import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from '@/shared/ui/Dialog/Dialog';
import { Text } from '@/shared/ui/Text/Text';
import { Space } from '@/shared/ui/Space/Space';
import { Button } from '@/shared/ui/Button/Button';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { useQuery } from '@/shared/hooks/useQuery/useQuery';
import { useHistory } from 'react-router';
import { setSuccessPaidModal } from '@/entity/ModalsEntity/modals.slice';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { useEffect, useState } from 'react';
const SuccessSubscribePayment = ({ open }) => {
    var _a;
    const tariffs = useTypedSelector((state) => state.paymentReducer.tariffs);
    const tariffId = (_a = useTypedSelector((state) => state.paymentReducer.userCurrentPayment)) === null || _a === void 0 ? void 0 : _a.tariffId;
    const [findTariff, setFindTariff] = useState('');
    const history = useHistory();
    const query = useQuery();
    const dispatch = useTypedDispatch();
    const handleClose = () => {
        query.delete('payment_id');
        history.push({ search: query.toString() });
        dispatch(setSuccessPaidModal(false));
    };
    useEffect(() => {
        if (tariffs.length !== 0) {
            const find = tariffs.find((el) => el.id === tariffId);
            if (find) {
                setFindTariff(find.name);
            }
        }
    }, [tariffs, tariffId]);
    return (_jsxs(Dialog, { open: open, closeIcon: true, onOpenChange: handleClose, children: [_jsx(Text, { css: { marginBottom: '24px' }, type: "preTitle", children: "\u041F\u043E\u0434\u043F\u0438\u0441\u043A\u0430 \u043E\u0444\u043E\u0440\u043C\u043B\u0435\u043D\u0430!" }), _jsxs(Text, { css: { marginBottom: '24px' }, type: "default", children: [_jsxs("strong", { children: ["\u041F\u043E\u0437\u0434\u0440\u0430\u0432\u043B\u044F\u0435\u043C, \u0442\u0435\u043F\u0435\u0440\u044C \u0432\u0430\u043C \u0434\u043E\u0441\u0442\u0443\u043F\u0435\u043D ", findTariff, ".", _jsx("br", {}), " \u0421\u043F\u0430\u0441\u0438\u0431\u043E, \u0447\u0442\u043E \u043F\u043E\u043B\u044C\u0437\u0443\u044E\u0442\u0435\u0441\u044C Harvester!"] }), " ", _jsx("br", {}), "\u041F\u043E\u0434\u0440\u043E\u0431\u043D\u043E\u0441\u0442\u0438 \u043E\u0431 \u043E\u043F\u043B\u0430\u0442\u0435 \u043D\u0430\u043F\u0440\u0430\u0432\u0438\u043B\u0438 \u043D\u0430 email."] }), _jsx(Space, { content: "flexEnd", children: _jsx(Button, { onClick: handleClose, type: "primary", children: "\u041D\u0430\u0447\u0430\u0442\u044C \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F" }) })] }));
};
export { SuccessSubscribePayment };
