export var FilterParamsEnum;
(function (FilterParamsEnum) {
    FilterParamsEnum["price"] = "price";
    FilterParamsEnum["revenue"] = "revenue";
    FilterParamsEnum["orders"] = "orders";
    FilterParamsEnum["createdAt"] = "createdAt";
    FilterParamsEnum["updatedAt"] = "updatedAt";
    FilterParamsEnum["marginality"] = "marginality";
    FilterParamsEnum["costPrice"] = "costPrice";
})(FilterParamsEnum || (FilterParamsEnum = {}));
export var MarketPlaceEnum;
(function (MarketPlaceEnum) {
    MarketPlaceEnum["Ozon"] = "MARKET_OZON";
    MarketPlaceEnum["Wildberries"] = "MARKET_WILD_BERRIES";
    MarketPlaceEnum["All"] = "ALL_MARKETPLACE";
})(MarketPlaceEnum || (MarketPlaceEnum = {}));
export var FBWTypeDelivery;
(function (FBWTypeDelivery) {
    FBWTypeDelivery["Monopallet"] = "FBW_TYPE_MONOPALLET";
    FBWTypeDelivery["Korob"] = "FBW_TYPE_KOROB";
})(FBWTypeDelivery || (FBWTypeDelivery = {}));
export var CalculateParamsEnum;
(function (CalculateParamsEnum) {
    CalculateParamsEnum["Available"] = "available";
    CalculateParamsEnum["notAvailable"] = "notAvailable";
    CalculateParamsEnum["All"] = "All";
})(CalculateParamsEnum || (CalculateParamsEnum = {}));
