export var StatusEnum;
(function (StatusEnum) {
    StatusEnum["MAILING_STATUS_DONE"] = "\u0417\u0430\u0432\u0435\u0440\u0448\u0435\u043D\u043E";
    StatusEnum["MAILING_STATUS_IN_PROGRESS"] = "\u0412 \u043F\u0440\u043E\u0446\u0435\u0441\u0441\u0435";
    StatusEnum["MAILING_STATUS_PLANNED"] = "\u0417\u0430\u043F\u043B\u0430\u043D\u0438\u0440\u043E\u0432\u0430\u043D\u043E";
    StatusEnum["MAILING_STATUS_CANCELED"] = "\u041E\u0442\u043C\u0435\u043D\u0435\u043D\u043E";
    StatusEnum["MAILING_STATUS_DRAFT"] = "\u0427\u0435\u0440\u043D\u043E\u0432\u0438\u043A";
    StatusEnum["MAILING_STATUS_ALL"] = "\u0412\u0441\u0435";
})(StatusEnum || (StatusEnum = {}));
export var StatusDisplayEnum;
(function (StatusDisplayEnum) {
    StatusDisplayEnum["MAILING_STATUS_DONE"] = "\u0414\u043E\u0441\u0442\u0430\u0432\u043B\u0435\u043D\u043E";
    StatusDisplayEnum["MAILING_STATUS_IN_PROGRESS"] = "\u0418\u0434\u0435\u0442 \u043E\u0442\u043F\u0440\u0430\u0432\u043A\u0430";
    StatusDisplayEnum["MAILING_STATUS_PLANNED"] = "\u0417\u0430\u043F\u043B\u0430\u043D\u0438\u0440\u043E\u0432\u0430\u043D\u0430";
    StatusDisplayEnum["MAILING_STATUS_CANCELED"] = "\u041E\u0442\u043C\u0435\u043D\u0435\u043D\u0430";
    StatusDisplayEnum["MAILING_STATUS_DRAFT"] = "\u0427\u0435\u0440\u043D\u043E\u0432\u0438\u043A";
    StatusDisplayEnum["MAILING_STATUS_ALL"] = "\u0412\u0441\u0435";
})(StatusDisplayEnum || (StatusDisplayEnum = {}));
export var MailingStatusForCounter;
(function (MailingStatusForCounter) {
    MailingStatusForCounter["MAILING_STATUS_DONE"] = "success";
    MailingStatusForCounter["MAILING_STATUS_IN_PROGRESS"] = "inProgress";
    MailingStatusForCounter["MAILING_STATUS_PLANNED"] = "planned";
    MailingStatusForCounter["MAILING_STATUS_CANCELED"] = "cancel";
    MailingStatusForCounter["MAILING_STATUS_DRAFT"] = "draft";
})(MailingStatusForCounter || (MailingStatusForCounter = {}));
export var StatusFilterEnum;
(function (StatusFilterEnum) {
    StatusFilterEnum["MAILING_STATUS_DONE"] = "MAILING_STATUS_DONE";
    StatusFilterEnum["MAILING_STATUS_IN_PROGRESS"] = "MAILING_STATUS_IN_PROGRESS";
    StatusFilterEnum["MAILING_STATUS_PLANNED"] = "MAILING_STATUS_PLANNED";
    StatusFilterEnum["MAILING_STATUS_CANCELED"] = "MAILING_STATUS_CANCELED";
    StatusFilterEnum["MAILING_STATUS_DRAFT"] = "MAILING_STATUS_DRAFT";
    StatusFilterEnum["MAILING_STATUS_ALL"] = "MAILING_STATUS_ALL";
})(StatusFilterEnum || (StatusFilterEnum = {}));
