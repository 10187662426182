import * as yup from 'yup';
const regExpPhone = /^(\+?[78])\d{10}$/;
export const personalDataSchemaWithName = yup.object({
    name: yup
        .string()
        .min(1, { text: 'required' })
        .required({ text: 'required' }),
    surName: yup
        .string()
        .min(1, { text: 'required' })
        .required({ text: 'required' }),
    phone: yup
        .string()
        .test({
        name: 'phone',
        message: { text: 'required' },
        test: ((value) => regExpPhone.test(value)),
    })
        .required({ text: 'required' }),
});
export const personalDataSchemaWithoutName = yup.object({
    phone: yup
        .string()
        .test({
        name: 'phone',
        message: { text: 'required' },
        test: ((value) => regExpPhone.test(value)),
    })
        .required({ text: 'required' }),
});
