var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
const DeliveryTypeButton = (_a) => {
    var { title, cssTitle, css, description, onClick, disabled = false } = _a, props = __rest(_a, ["title", "cssTitle", "css", "description", "onClick", "disabled"]);
    const handleClick = () => {
        if (disabled) {
            return;
        }
        if (onClick) {
            onClick();
        }
    };
    return (_jsxs(S.DeliveryTypeContainer, Object.assign({}, props, { "data-disable-attr": disabled, css: css, onClick: handleClick, children: [_jsx(S.DeliveryTypeText, { css: Object.assign({ margin: '22px 4px 6px 4px', textAlign: 'center' }, cssTitle), type: "logisticCard", children: title }), _jsx(S.DeliveryTypeText, { css: { marginBottom: '22px', fontWeight: 600 }, children: description })] })));
};
export { DeliveryTypeButton };
