import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from 'react-dom/client';
import '@/shared/assets/styles/index.less';
import { App } from './app/App';
import { Router, } from 'react-router-dom';
import store from '@/app/providers/redux/store';
import { Provider } from 'react-redux';
import { initAmplitude } from '@/shared/utils/amplitude/amplitude';
import { CheckPaymentProvider } from '@/app/providers/context/checkPaymentContext/checkPaymentContext';
import { history } from '@/shared/utils/history';
initAmplitude();
const container = document.getElementById('root');
const root = createRoot(container);
root.render(_jsx(Provider, { store: store, children: _jsx(Router, { history: history, children: _jsx(CheckPaymentProvider, { children: _jsx(App, {}) }) }) }));
