import { jsx as _jsx } from "react/jsx-runtime";
import * as S from './style';
const BackgroundGray = ({ children, chose, active, hover = false, onClick, error, css, }) => {
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };
    return (_jsx(S.BackgroundGray, { error: error, onClick: handleClick, hover: hover, chose: chose, active: active, css: css, children: children }));
};
export { BackgroundGray };
