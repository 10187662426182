import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FilterParamsComponent } from '@/widgets/ui/FilterParams/FilterParams';
import { Space } from '@/shared/ui/Space/Space';
import { Text } from '@/shared/ui/Text/Text';
import * as S from './style';
import React, { useState } from 'react';
import { MarketPlaceEnum } from '@/shared/hooks/useFilter/types';
import { Item } from './Item/Item';
import { MobileItem } from './MobileItem/MobileItem';
import { Button } from '@/shared/ui/Button/Button';
import BreadCrumbs from '@/shared/ui/BreadCrumbs/BreadCrumbs';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { handleGraphModal } from '@/entity/ModalsEntity/modals.slice';
import { useFilterProducts } from '@/shared/hooks/useFilter/useFilter';
import { removeProductsIds } from '@/entity/ListSavedProductsEntity/savedProducts.slice';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
const SaveItems = ({ isDesktop, decrementStep, handleOpenPaymentModal, products, handleClose }) => {
    var _a;
    const dispatch = useTypedDispatch();
    const [removeIds, setRemoveIds] = useState([]);
    const [filtersParams, setFilterParams] = useState({
        search: '',
        field: null,
        DESC: false,
        marketPlace: MarketPlaceEnum.All,
        calculate: null,
    });
    const filteredProducts = (_a = useFilterProducts(filtersParams, products)) !== null && _a !== void 0 ? _a : [];
    const handleOpenGraph = (id) => {
        dispatch(handleGraphModal({ id: id, open: true }));
    };
    const handleDeleteSelectIds = () => {
        sendAmplitudeData('delete_goods_modal_view_delete_btn_clicked');
        dispatch(removeProductsIds({ ids: removeIds }));
        if ((products.length - removeIds.length) <= 30) {
            handleClose();
        }
    };
    const handleChangeRemoveIds = (id) => {
        const findId = removeIds.find((el) => el === id);
        if (findId) {
            return setRemoveIds((prevState) => prevState.filter((el) => el !== id));
        }
        setRemoveIds((prevState) => [...prevState, id]);
    };
    return (_jsxs(Space, { direction: "vertical", css: { width: '100%', height: '100%' }, content: "between", children: [_jsxs(Space, { direction: "vertical", children: [_jsx(BreadCrumbs, { css: { padding: '0 0 12px 0' }, goBackFunc: decrementStep }), _jsxs(Text, { css: { marginBottom: '24px' }, type: "preTitle", children: ["\u0423\u0434\u0430\u043B\u0435\u043D\u0438\u0435 \u0442\u043E\u0432\u0430\u0440\u043E\u0432: ", removeIds.length] }), _jsx(FilterParamsComponent, { filterParams: filtersParams, setFilterParams: setFilterParams }), isDesktop &&
                        _jsxs(Space, { css: { padding: `${products.length > 3 ? '6px 32px 4px 16px' : '6px 16px 4px 16px'}`, marginTop: '14px' }, children: [_jsx(Space, { css: { width: '21px', marginRight: '12px' } }), _jsx(Space, { css: { width: '48px', marginRight: '24px' } }), _jsx(Space, { css: { width: '30%' }, children: _jsx(Text, { type: "textEllipsis", children: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435" }) }), _jsx(Space, { content: "center", css: { width: '10%' }, children: _jsx(Text, { type: "textEllipsis", children: "\u041F\u043B\u043E\u0449\u0430\u0434\u043A\u0430" }) }), _jsx(Space, { content: "center", css: { width: '10%' }, children: _jsx(Text, { type: "textEllipsis", children: "\u0426\u0435\u043D\u0430" }) }), _jsx(Space, { content: "center", css: { width: '10%' }, children: _jsx(Text, { type: "textEllipsis", children: "\u0417\u0430\u043A\u0430\u0437\u043E\u0432, 30\u0434" }) }), _jsx(Space, { content: "center", css: { width: '15%' }, children: _jsx(Text, { type: "textEllipsis", children: "\u0412\u044B\u0440\u0443\u0447\u043A\u0430, 30\u0434" }) }), _jsx(Space, { content: "center", css: { width: '25%' } })] }), _jsx(S.ItemsParametersContainer, { children: filteredProducts === null || filteredProducts === void 0 ? void 0 : filteredProducts.map((item) => (isDesktop ?
                            _jsx(Item, { handleOpenGraph: handleOpenGraph, handleChangeRemoveIds: handleChangeRemoveIds, removeIds: removeIds, item: item }) :
                            _jsx(MobileItem, { item: item }))) })] }), _jsxs(Space, { content: "flexEnd", children: [_jsx(Button, { onClick: handleDeleteSelectIds, disabled: removeIds.length === 0, css: { marginRight: '12px' }, type: "primary", children: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u0432\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0435 \u0442\u043E\u0432\u0430\u0440\u044B" }), _jsx(Button, { onClick: handleOpenPaymentModal, type: "primary", children: "\u041E\u0441\u0442\u0430\u0432\u0438\u0442\u044C \u0432\u0441\u0435 \u0442\u043E\u0432\u0430\u0440\u044B" })] })] }));
};
export { SaveItems };
