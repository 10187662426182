var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { interceptorConfig } from '@/shared/api/constants/interceptorConfig';
const instance = axios.create({
    baseURL: process.env.REACT_APP_URL_API_GOODS,
    timeout: 60000,
});
instance.interceptors.request.use((config) => {
    return interceptorConfig(config);
});
const api = {
    getProduct: (url, params) => __awaiter(void 0, void 0, void 0, function* () { return instance.get(url, params); }),
    patchProduct: (url, data) => __awaiter(void 0, void 0, void 0, function* () { return instance.patch(url, data); }),
    putProduct: (url, data, params) => __awaiter(void 0, void 0, void 0, function* () { return instance.put(url, data, params); }),
    postProduct: (url, data, params) => __awaiter(void 0, void 0, void 0, function* () { return instance.post(url, data, params); }),
    removeProduct: (url, params) => __awaiter(void 0, void 0, void 0, function* () { return instance.delete(url, params); }),
};
export const { getProduct, patchProduct, putProduct, postProduct, removeProduct, } = api;
