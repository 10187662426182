const durationValues = {
    '30': 'oneMonth',
    '90': 'threeMonth',
    '360': 'oneYear',
};
const getMonthFormDuration = (duration) => {
    switch (duration) {
        case '30':
            return 1;
        case '90':
            return 3;
        case '360':
            return 12;
        default: return 0;
    }
};
const getPercent = (first, second) => {
    return +Math.abs((first - second) / second * 100).toFixed(0);
};
export const prepareTariffAfterPromo = (tariff, sale, oldTariffs) => {
    const obj = {
        oneMonth: {
            lite: 0,
            pro: 0,
            liteSale: 0,
            proSale: 0,
        },
        threeMonth: {
            lite: 0,
            pro: 0,
            liteSale: 0,
            proSale: 0,
        },
        oneYear: {
            lite: 0,
            pro: 0,
            liteSale: 0,
            proSale: 0,
        },
    };
    const filteredNewTariffs = tariff.filter((item) => item.name.includes('Калькулятор'));
    const filterOldTariffs = oldTariffs === null || oldTariffs === void 0 ? void 0 : oldTariffs.filter((item) => item.name.includes('Калькулятор'));
    for (let i = 0; i < filteredNewTariffs.length; i++) {
        if (filteredNewTariffs[i].name.includes('Pro')) {
            if (sale) {
                obj[durationValues[filteredNewTariffs[i].duration]].proSale = getPercent(filteredNewTariffs[i].price, filterOldTariffs[i].price);
            }
            obj[durationValues[filteredNewTariffs[i].duration]].pro = (filteredNewTariffs[i].price / getMonthFormDuration(filteredNewTariffs[i].duration)).toFixed(0);
        }
        if (filteredNewTariffs[i].name.includes('Lite')) {
            if (sale) {
                obj[durationValues[filteredNewTariffs[i].duration]].liteSale = getPercent(filteredNewTariffs[i].price, filterOldTariffs[i].price);
            }
            obj[durationValues[filteredNewTariffs[i].duration]].lite = (filteredNewTariffs[i].price / getMonthFormDuration(filteredNewTariffs[i].duration)).toFixed(0);
        }
    }
    return obj;
};
