import { styled } from '@/app/styles/mainStyles';
import { Text } from '@/shared/ui/Text/style';
export const NavLinkText = styled(Text, {
    color: '$activeBackground',
});
export const NavLinkSupport = styled('a', {
    border: 'none',
    textDecoration: 'none !important',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '6px',
    '&:last-child': {
        marginBottom: '0',
    },
    '&:hover': {
        [`${NavLinkText}`]: {
            opacity: 0.7,
        },
    },
});
