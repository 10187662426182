import { CalculateParamsEnum, FilterParamsEnum, MarketPlaceEnum } from './types';
// eslint-disable-next-line @typescript-eslint/default-param-last
const searchProduct = (search = '', products) => products.filter((product) => {
    const { name } = product;
    if (search) {
        const regexp = new RegExp(search, 'gi');
        return (regexp.test(name));
    }
    return true;
});
const filterByMarketplace = (marketplace, products) => products === null || products === void 0 ? void 0 : products.filter((product) => {
    const { market } = product;
    if (marketplace === MarketPlaceEnum.All) {
        return true;
    }
    if (marketplace === MarketPlaceEnum.Ozon) {
        return market === 'MARKET_OZON';
    }
    if (marketplace === MarketPlaceEnum.Wildberries) {
        return market === 'MARKET_WILD_BERRIES';
    }
    return true;
});
const haveCalculateProduct = (params, products) => products === null || products === void 0 ? void 0 : products.filter((product) => {
    const { calculationExist } = product;
    if (params) {
        if (params === CalculateParamsEnum.Available) {
            return calculationExist;
        }
        if (params === CalculateParamsEnum.notAvailable) {
            return !calculationExist;
        }
    }
    return true;
});
export const useFilterProducts = (filters, products) => {
    const filteredSearch = searchProduct(filters === null || filters === void 0 ? void 0 : filters.search, products);
    const filterMarketplace = filterByMarketplace(filters === null || filters === void 0 ? void 0 : filters.marketPlace, filteredSearch);
    const filterCalculate = haveCalculateProduct(filters.calculate, filterMarketplace);
    if (filters.field === FilterParamsEnum.createdAt) {
        if (filters.DESC) {
            return filterCalculate === null || filterCalculate === void 0 ? void 0 : filterCalculate.sort((a, b) => {
                return new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf();
            });
        }
        else {
            return filterCalculate === null || filterCalculate === void 0 ? void 0 : filterCalculate.sort((a, b) => {
                return new Date(a.createdAt).valueOf() + new Date(b.createdAt).valueOf();
            });
        }
    }
    if (filters.field === FilterParamsEnum.updatedAt) {
        if (filters.DESC) {
            return filterCalculate === null || filterCalculate === void 0 ? void 0 : filterCalculate.sort((a, b) => {
                return new Date(a.updatedAt).valueOf() - new Date(b.updatedAt).valueOf();
            });
        }
        else {
            return filterCalculate === null || filterCalculate === void 0 ? void 0 : filterCalculate.sort((a, b) => {
                return new Date(a.updatedAt).valueOf() + new Date(b.updatedAt).valueOf();
            });
        }
    }
    if (filters.field === FilterParamsEnum.orders) {
        if (filters.DESC) {
            return filterCalculate === null || filterCalculate === void 0 ? void 0 : filterCalculate.sort((a, b) => {
                return a.orderCount30Days - b.orderCount30Days;
            });
        }
        else {
            return filterCalculate === null || filterCalculate === void 0 ? void 0 : filterCalculate.sort((a, b) => {
                return b.orderCount30Days - a.orderCount30Days;
            });
        }
    }
    if (filters.field === FilterParamsEnum.revenue) {
        if (filters.DESC) {
            return filterCalculate === null || filterCalculate === void 0 ? void 0 : filterCalculate.sort((a, b) => {
                return a.revenue30Days - b.revenue30Days;
            });
        }
        else {
            return filterCalculate === null || filterCalculate === void 0 ? void 0 : filterCalculate.sort((a, b) => {
                return b.revenue30Days - a.revenue30Days;
            });
        }
    }
    if (filters.field === FilterParamsEnum.price) {
        if (filters.DESC) {
            return filterCalculate === null || filterCalculate === void 0 ? void 0 : filterCalculate.sort((a, b) => {
                return +a.finalPrice - +b.finalPrice;
            });
        }
        else {
            return filterCalculate === null || filterCalculate === void 0 ? void 0 : filterCalculate.sort((a, b) => {
                return +b.finalPrice - +a.finalPrice;
            });
        }
    }
    return filterCalculate;
};
