import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from '@/shared/ui/Dialog/Dialog';
import { Text } from '@/shared/ui/Text/Text';
import { Space } from '@/shared/ui/Space/Space';
import { Button } from '@/shared/ui/Button/Button';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { setFirstLoginModal, setOnboardingModal } from '@/entity/ModalsEntity/modals.slice';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
import { startTrialSub } from '@/entity/PaymentEntity/payment.slice';
import { setCalculatorSideBarModule } from '@/entity/SidebarEntity/sidebar.slice';
import { useHistory } from 'react-router';
import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
const FirstLoginModal = ({ open }) => {
    const dispatch = useTypedDispatch();
    const history = useHistory();
    const isVerifyNumber = useTypedSelector((state) => { var _a; return (_a = state.profileReducer.profile) === null || _a === void 0 ? void 0 : _a.isVerifyPhoneNumber; });
    sendAmplitudeData('trial_start_modal_view_show');
    const handleStart = () => {
        sendAmplitudeData('trial_start_modal_view_btn_clicked');
        dispatch(startTrialSub({}));
        dispatch(setOnboardingModal(true));
        dispatch(setCalculatorSideBarModule({ value: true }));
        dispatch(setFirstLoginModal(false));
        history.push(RoutePath.home);
    };
    return (_jsxs(Dialog, { open: open, outside: true, onOpenChange: handleStart, closeIcon: true, children: [_jsx(Text, { type: "preTitle", css: { marginBottom: '24px' }, children: "\u0414\u043E\u0431\u0440\u043E \u043F\u043E\u0436\u0430\u043B\u043E\u0432\u0430\u0442\u044C" }), _jsxs(Text, { css: { marginBottom: '60px' }, type: "default", children: ["\u0420\u0430\u0434\u044B \u0432\u0438\u0434\u0435\u0442\u044C \u0432\u0430\u0441 \u0432 Harvester! ", _jsx("br", {}), _jsxs("strong", { children: ["\u041F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u043B\u0438 \u043F\u0440\u043E\u0431\u043D\u044B\u0439 \u043F\u0435\u0440\u0438\u043E\u0434 Harvester Pro \u043D\u0430 ", isVerifyNumber ? '14' : '7', " \u0434\u043D\u0435\u0439 \u0431\u0435\u0441\u043F\u043B\u0430\u0442\u043D\u043E!"] })] }), _jsx(Space, { content: "flexEnd", children: _jsx(Button, { onClick: handleStart, children: "\u041D\u0430\u0447\u0430\u0442\u044C" }) })] }));
};
export { FirstLoginModal };
