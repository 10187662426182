import { styled } from '@/app/styles/mainStyles';
export const RegisterHoverButton = styled('div', {
    display: 'flex',
    width: '100%',
    '&:hover': {
        cursor: 'pointer',
    },
    '@desktop': {
        justifyContent: 'flex-start',
        gap: '24px',
        alignItems: 'center',
    },
    '@mobile': {
        flexWrap: 'wrap',
        gap: '8px',
        justifyContent: 'center',
    },
});
export const CodeInput = styled('input', {
    border: '1px solid $border',
    outline: 'none',
    borderRadius: '6px',
    '@desktop': {
        fontSize: '26px',
        width: '25px',
        padding: '10px',
        textAlign: 'center',
        margin: '0 0 20px 15px',
    },
    '@mobile': {
        fontSize: '17px',
        width: '17px',
        padding: '10px',
        textAlign: 'center',
        margin: '0 0 20px 15px',
    },
});
export const FormContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    '@desktop': {
        width: '60%',
    },
    '@mobile': {
        width: '100%',
        padding: '0 16px',
    },
});
export const ImgContainer = styled('div', {
    display: 'flex',
    '@desktop': {
        width: '40%',
        height: '100%',
    },
    '@mobile': {
        display: 'none',
    },
});
