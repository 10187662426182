import { styled } from '@/app/styles/mainStyles';
export const PaymentModalComponent = styled('div', {
    display: 'flex',
    flexDirection: 'column',
});
export const NavDots = styled('div', {
    width: '12px',
    height: '12px',
    outline: 'none',
    borderRadius: '50%',
    border: '1px solid $border',
    background: 'transparent',
    marginRight: '12px',
    variants: {
        active: {
            true: {
                background: '$activeBackground',
            },
        },
    },
});
export const NavDotsContainer = styled('div', {
    display: 'flex',
    position: 'sticky',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: '6px',
});
