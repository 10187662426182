var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as S from './style';
import { useEffect, useState } from 'react';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { setPayMailingModal } from '@/entity/ModalsEntity/modals.slice';
import { ReactComponent as Info } from '@/shared/assets/icons/info.svg';
import { buyMailing, getMailingPrices } from '@/entity/PaymentEntity/payment.slice';
import { Icon, Tooltip, Text, Space, Slider, Dialog, Button, Input } from '@/shared/ui';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
import { useDebounce } from '@/shared/hooks/useDebounce/useDebounce';
import { usePromocodeActive } from '@/shared/hooks/usePromocodeActive/usePromocodeActive';
import { PromocodeValidateEnum } from '@/entity/PromocodesEntity/types';
import { preparePriceAfterPromo } from '@/features/utils/preparePriceAfterPromo';
import { useQuery } from '@/shared/hooks/useQuery/useQuery';
import { useHistory } from 'react-router';
import { fakeMailingIdAfterPayment } from '@/types/fakeMailingIdAfterPayment';
const PayMailing = ({ open, isDesktop }) => {
    const query = useQuery();
    const history = useHistory();
    const mailingNeedCount = query.get('mailingNeedMessage');
    const priceMailingId = query.get('priceMailingId');
    const dispatch = useTypedDispatch();
    const balance = useTypedSelector((state) => state.mailingsReducer.balance);
    const mailingPricesState = useTypedSelector((state) => state.paymentReducer.mailingPrices);
    const [currentPrice, setCurrentPrice] = useState({ price: 2, oldPrice: 0 });
    const [validateValue, setValidateValue] = useState(50);
    const [valueSlider, setValueSlider] = useState([50]);
    const [mailingPrices, setMailingPrices] = useState([]);
    const [nextState, setNextState] = useState({
        nextPrice: 1.7,
        moreMailing: 151,
        nextOldPrice: 0,
    });
    const [withBonusMailings, setWithBonusMailings] = useState({
        freeMailings: 0,
        freeMailingsFrom: 0,
    });
    const handleClose = () => {
        sendAmplitudeData('buy_messages_modal_view_closed_btn_clicked');
        dispatch(setPayMailingModal(false));
    };
    const [promoCode, setPromoCode] = useState('');
    const { handleFail, handleSuccess, setHavePromoCode, havePromocode, handleValidatePromocode, } = usePromocodeActive(promoCode, PromocodeValidateEnum.mailings);
    const debounceValue = useDebounce(valueSlider[0], 1500);
    const getCurrentValueForMailing = (value, updated) => {
        if (!!(updated === null || updated === void 0 ? void 0 : updated.length)) {
            updated === null || updated === void 0 ? void 0 : updated.forEach((el) => {
                if (Number(value) >= Number(el.min) && Number(value) <= Number(el.max)) {
                    setCurrentPrice({ price: el.price, oldPrice: el === null || el === void 0 ? void 0 : el.oldPrice });
                }
            });
        }
        else {
            mailingPrices.forEach((el) => {
                if (Number(value) >= Number(el.min) && Number(value) <= Number(el.max)) {
                    setCurrentPrice({ price: el.price, oldPrice: el === null || el === void 0 ? void 0 : el.oldPrice });
                }
            });
        }
    };
    const handleMoreMail = (value, updatedMailingPrices) => {
        var _a, _b, _c, _d, _e, _f, _g;
        let moreMailing = 0;
        let nextPrice = 0;
        let nextOldPrice = 0;
        for (let i = 0; i < updatedMailingPrices.length; i++) {
            if (Number(updatedMailingPrices[i].max) >= value) {
                if ((_a = updatedMailingPrices[i + 1]) === null || _a === void 0 ? void 0 : _a.price) {
                    nextPrice = (_b = updatedMailingPrices[i + 1]) === null || _b === void 0 ? void 0 : _b.price;
                    if ((_c = updatedMailingPrices[i + 1]) === null || _c === void 0 ? void 0 : _c.oldPrice) {
                        // @ts-ignore
                        nextOldPrice = (_d = updatedMailingPrices[i + 1]) === null || _d === void 0 ? void 0 : _d.oldPrice;
                    }
                    moreMailing = Number(updatedMailingPrices[i + 1].min) - value;
                    break;
                }
                else {
                    if (nextState.nextPrice >= ((_e = updatedMailingPrices[i]) === null || _e === void 0 ? void 0 : _e.price)) {
                        return setNextState({
                            moreMailing: 0,
                            nextPrice: (_f = updatedMailingPrices[i]) === null || _f === void 0 ? void 0 : _f.price,
                            nextOldPrice: 0,
                        });
                    }
                    nextPrice = (_g = updatedMailingPrices[i]) === null || _g === void 0 ? void 0 : _g.price;
                    moreMailing = Number(updatedMailingPrices[i].min) - value;
                    break;
                }
            }
        }
        setNextState({ moreMailing, nextPrice, nextOldPrice });
    };
    const handleActivatePromocode = () => __awaiter(void 0, void 0, void 0, function* () {
        const isValid = yield handleValidatePromocode();
        sendAmplitudeData('buy_messages_modal_view_apply_promocode_btn_clicked', {
            promocode: promoCode,
        });
        if (!isValid.payload.code) {
            const { payload } = yield dispatch(getMailingPrices({ promocode: promoCode }));
            if (payload === null || payload === void 0 ? void 0 : payload.freeMailings) {
                setWithBonusMailings({ freeMailings: payload.freeMailings, freeMailingsFrom: payload.freeMailingsFrom });
                return;
            }
            const updatedTariffs = preparePriceAfterPromo(payload === null || payload === void 0 ? void 0 : payload.mailingPrices, payload === null || payload === void 0 ? void 0 : payload.mailingPricesPromocode);
            setMailingPrices(updatedTariffs);
            setWithBonusMailings({ freeMailings: 0, freeMailingsFrom: 0 });
            handleMoreMail(valueSlider[0], updatedTariffs);
            getCurrentValueForMailing(valueSlider[0], updatedTariffs);
        }
        else {
            setMailingPrices(mailingPricesState);
            handleMoreMail(valueSlider[0], mailingPricesState);
            setWithBonusMailings({ freeMailings: 0, freeMailingsFrom: 0 });
            getCurrentValueForMailing(valueSlider[0], mailingPricesState);
            return;
        }
    });
    const handleBuy = () => {
        let domain = window.location.origin;
        if (priceMailingId) {
            domain = `${domain}/newMailing?mailingId=${priceMailingId}&mailingIdAfterPayment=${priceMailingId}&`;
        }
        else {
            domain = `${domain}/homeMailings?mailingIdAfterPayment=${fakeMailingIdAfterPayment}&`;
        }
        sendAmplitudeData('buy_messages_modal_view_buy_btn_clicked', {
            count: valueSlider[0],
        });
        if (handleSuccess) {
            dispatch(buyMailing({ numberMailings: valueSlider[0], domain, promocode: promoCode }));
        }
        else {
            dispatch(buyMailing({ numberMailings: valueSlider[0], domain }));
        }
    };
    const handleChangeSlider = (number) => {
        getCurrentValueForMailing(number[0]);
        setValueSlider(number);
        setValidateValue(number[0]);
        handleMoreMail(number[0], mailingPrices);
    };
    const getUiForTooltip = () => {
        if (mailingPrices) {
            return (_jsx(Space, { content: "flexStart", direction: "vertical", children: mailingPrices.map((el) => {
                    return (_jsxs(Space, { children: [_jsxs(Text, { css: { fontSize: '13px', textAlign: 'start' }, type: "default", children: [`${el.min} - ${el.max} cообщений -`, "\u00A0"] }), (el === null || el === void 0 ? void 0 : el.oldPrice) &&
                                _jsx(Text, { css: {
                                        marginRight: '6px',
                                        fontSize: '13px',
                                        textAlign: 'start',
                                        textDecoration: 'line-through',
                                    }, type: "default", children: _jsx("strong", { children: `${el.oldPrice}` }) }), _jsxs(Text, { css: {
                                    fontSize: '13px',
                                    textAlign: 'start',
                                    color: (el === null || el === void 0 ? void 0 : el.oldPrice) ? '$acceptButton' : '',
                                }, type: "default", children: [_jsx("strong", { children: el.price }), " \u20BD/\u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435"] })] }));
                }) }));
        }
    };
    const handleChangeInput = (event) => {
        getCurrentValueForMailing(event.target.value);
        setValidateValue(event.target.value);
        if (Number(event.target.value) >= 50000) {
            setValueSlider([50000]);
            handleMoreMail(50000, mailingPrices);
            return;
        }
        if (mailingNeedCount) {
            if (+mailingNeedCount >= Number(event.target.value)) {
                setValueSlider([+mailingNeedCount]);
                handleMoreMail(+mailingNeedCount, mailingPrices);
                return;
            }
        }
        else {
            if (Number(event.target.value) <= 50) {
                setValueSlider([50]);
                handleMoreMail(50, mailingPrices);
                return;
            }
        }
        if (Number(event.target.value)) {
            setValueSlider([Number(event.target.value)]);
            handleMoreMail(Number(event.target.value), mailingPrices);
        }
    };
    const handleHavePromocode = (value) => {
        sendAmplitudeData('buy_messages_modal_view_i_have_promocode_btn_clicked');
        setHavePromoCode(value);
    };
    useEffect(() => {
        if (mailingPricesState.length !== 0) {
            setMailingPrices(mailingPricesState);
        }
    }, [mailingPricesState]);
    useEffect(() => {
        if (mailingPricesState.length === 0) {
            dispatch(getMailingPrices({}));
        }
    }, []);
    useEffect(() => {
        sendAmplitudeData('buy_messages_modal_view_count_changed', {
            count: valueSlider[0],
        });
    }, [debounceValue]);
    useEffect(() => {
        if (mailingNeedCount && mailingPricesState.length !== 0) {
            if (+mailingNeedCount >= 50) {
                setValueSlider([+mailingNeedCount]);
                setValidateValue(+mailingNeedCount);
                handleMoreMail(+mailingNeedCount, mailingPrices);
                getCurrentValueForMailing(+mailingNeedCount);
            }
        }
    }, [mailingPrices]);
    useEffect(() => {
        return () => {
            query.delete('mailingNeedMessage');
            query.delete('priceMailingId');
            history.push({ search: query.toString() });
        };
    }, []);
    return (_jsx(Dialog, { open: open, closeIcon: true, onOpenChange: handleClose, css: {
            maxWidth: '610px',
            width: '610px',
            top: isDesktop ? '49%' : '50%',
        }, children: _jsxs(Space, { direction: "vertical", children: [_jsx(Text, { css: { marginBottom: '24px' }, type: "preTitle", children: "\u041F\u0430\u043A\u0435\u0442\u044B \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0439" }), _jsxs(Text, { css: { marginBottom: '6px' }, type: "default", children: ["\u0421\u0435\u0439\u0447\u0430\u0441 \u0412\u0430\u043C \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u043E ", _jsx("strong", { children: balance }), " \u0421\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0439"] }), _jsxs(Space, { css: { marginBottom: '42px' }, align: "center", children: [_jsx(Text, { type: "default", children: "\u0425\u043E\u0442\u0438\u0442\u0435 \u0432\u0437\u044F\u0442\u044C \u0435\u0449\u0435" }), _jsx(S.MailingInput, { onChange: handleChangeInput, value: validateValue }), _jsx(Text, { type: "default", children: "\u0421\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0439?" })] }), _jsx(Slider, { css: { marginBottom: '4px' }, min: mailingNeedCount ? +mailingNeedCount >= 50 ? +mailingNeedCount : 50 : 50, value: valueSlider, withProomocode: !!withBonusMailings.freeMailings, promocodeValue: withBonusMailings.freeMailingsFrom, defaultValue: valueSlider, max: 50000, onValueChange: handleChangeSlider }), _jsxs(Space, { css: { marginBottom: '12px' }, content: "between", children: [_jsx(Text, { type: "labelText", children: mailingNeedCount ? mailingNeedCount : 50 }), _jsx(Text, { type: "labelText", children: "50\u043A" })] }), _jsxs(S.RateBlock, { children: [_jsxs(S.RateMessage, { children: [_jsx(Text, { css: {
                                        color: '$textBlackColor',
                                        marginBottom: '4px',
                                        fontWeight: 300,
                                        lineHeight: '12px',
                                    }, type: "labelText", children: "\u0421\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C \u043E\u0434\u043D\u043E\u0433\u043E \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F" }), _jsxs(Space, { children: [!!(currentPrice === null || currentPrice === void 0 ? void 0 : currentPrice.oldPrice) &&
                                            _jsx(Text, { css: {
                                                    marginRight: '6px',
                                                    fontSize: '13px',
                                                    color: '$labelColor', fontWeight: 700,
                                                    textDecoration: 'line-through',
                                                }, type: "default", children: _jsx("strong", { children: currentPrice.oldPrice }) }), _jsxs(Text, { ellipse: true, type: "default", children: [_jsx("strong", { children: currentPrice.price }), " \u20BD/\u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435"] }), _jsx(Tooltip, { delayDuration: 0, content: getUiForTooltip(), children: _jsx("div", { style: { marginLeft: '4px' }, children: _jsx(Icon, { css: { margin: 0, width: '18px' }, IconItem: Info }) }) })] })] }), _jsxs(S.RateMessage, { children: [_jsx(Text, { css: { color: '$textBlackColor', marginBottom: '4px', fontWeight: 300, lineHeight: '12px' }, type: "labelText", children: "\u041F\u043E\u043F\u043E\u043B\u043D\u0435\u043D\u0438\u0435" }), _jsxs(Text, { type: "default", children: [_jsx("strong", { children: valueSlider[0] }), " \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0439"] }), (!!withBonusMailings.freeMailings && (withBonusMailings.freeMailingsFrom <= valueSlider[0])) &&
                                    _jsxs(Text, { css: { color: '$acceptButton' }, type: "default", children: [_jsxs("strong", { children: ["+", withBonusMailings.freeMailings] }), " \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0439"] })] }), _jsxs(S.RateMessage, { children: [_jsxs(Text, { css: { color: '$textBlackColor', marginBottom: '4px', fontWeight: 300, lineHeight: '12px' }, type: "labelText", children: ["\u0412\u043E\u0437\u044C\u043C\u0438\u0442\u0435 \u0435\u0449\u0435 ", _jsx("strong", { style: { color: '#3BB44A' }, children: nextState.moreMailing }), " \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0439, \u0447\u0442\u043E\u0431\u044B \u0441\u043D\u0438\u0437\u0438\u0442\u044C \u0441\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C \u0434\u043E"] }), _jsxs(Space, { children: [!!(nextState === null || nextState === void 0 ? void 0 : nextState.nextOldPrice) &&
                                            _jsx(Text, { css: {
                                                    color: '$acceptButton',
                                                    textDecoration: 'line-through',
                                                    marginRight: '6px',
                                                    fontSize: '13px',
                                                }, type: "default", children: _jsx("strong", { children: nextState === null || nextState === void 0 ? void 0 : nextState.nextOldPrice }) }), _jsxs(Text, { css: { color: '$acceptButton' }, type: "default", ellipse: true, children: [_jsx("strong", { children: nextState.nextPrice }), " \u20BD/\u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435"] })] })] }), _jsxs(S.RateMessage, { children: [_jsx(Text, { css: { color: '$activeBackground', marginBottom: '4px' }, type: "labelText", children: "\u0421\u0443\u043C\u043C\u0430 \u043F\u043E\u043F\u043E\u043B\u043D\u0435\u043D\u0438\u044F" }), _jsxs(Space, { css: { gap: '6px' }, children: [!!currentPrice.oldPrice &&
                                            _jsxs(Text, { css: {
                                                    textDecoration: 'line-through',
                                                    textDecorationColor: '$error',
                                                    color: '#B3B8E7',
                                                }, type: "default", children: [_jsx("strong", { children: (valueSlider[0] * currentPrice.oldPrice).toFixed(0) }), " \u20BD"] }), _jsxs(Text, { css: { color: '$activeBackground' }, type: "default", children: [_jsx("strong", { children: (valueSlider[0] * currentPrice.price).toFixed(0) }), " \u20BD"] })] })] })] }), havePromocode &&
                    _jsxs(Space, { css: { marginBottom: '24px' }, direction: "vertical", size: "default", children: [_jsxs(Space, { align: "center", size: "default", children: [_jsx(Text, { children: "\u041F\u0440\u043E\u043C\u043E\u043A\u043E\u0434" }), _jsx(Input, { value: promoCode, onChange: (event) => setPromoCode(event.target.value), css: { height: '34px' } })] }), (handleSuccess && !isDesktop) &&
                                _jsx(Text, { css: { color: '$acceptButton' }, children: "\u041F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u043F\u0440\u0438\u043C\u0435\u043D\u0435\u043D" }), (handleFail && !isDesktop) &&
                                _jsx(Text, { css: { color: '$error' }, children: "\u041F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u043D\u0435\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043B\u0435\u043D" })] }), _jsxs(Space, { content: "between", align: "center", children: [!havePromocode ?
                            _jsx(Text, { onClick: () => handleHavePromocode(true), type: 'select', children: "\u0423 \u043C\u0435\u043D\u044F \u0435\u0441\u0442\u044C \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434" })
                            :
                                _jsxs(Space, { align: "center", size: "small", children: [_jsx(Button, { onClick: handleActivatePromocode, type: "default", children: "\u041F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C \u043A\u043E\u0434" }), (handleSuccess && isDesktop) &&
                                            _jsx(Text, { css: { color: '$acceptButton' }, children: "\u041F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u043F\u0440\u0438\u043C\u0435\u043D\u0435\u043D" }), (handleFail && isDesktop) &&
                                            _jsx(Text, { css: { color: '$error' }, children: "\u041F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u043D\u0435\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043B\u0435\u043D" })] }), _jsxs(Space, { align: "center", children: [_jsx(Button, { onClick: handleBuy, css: { marginRight: '12px' }, type: "primary", children: "\u041F\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u044C" }), _jsx(Text, { onClick: () => {
                                        handleClose();
                                        sendAmplitudeData('buy_messages_modal_view_cancel_btn_clicked');
                                    }, type: "select", children: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C" })] })] })] }) }));
};
export { PayMailing };
