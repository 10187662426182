import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import { Button } from '@/shared/ui/Button/Button';
import { Space } from '@/shared/ui/Space/Space';
const TooltipForOnboarding = ({ continuous, step, closeProps, primaryProps, tooltipProps, skipProps, isLastStep, }) => {
    return (_jsxs(S.TooltipContainer, Object.assign({}, tooltipProps, { children: [step.title && _jsx(S.TooltipTitle, { type: "preTitle", children: step.title }), _jsx(S.TooltipContent, { type: "default", css: step.customStyle, children: step.content }), !step.hideFooter &&
                _jsxs(S.TooltipFooter, { children: [step.showSkipButton && _jsx(S.TooltipTitle, Object.assign({ css: { margin: '0' }, type: "select" }, skipProps, { children: "\u041F\u0440\u043E\u043F\u0443\u0441\u0442\u0438\u0442\u044C" })), continuous && (_jsx(Space, { css: { width: !step.showSkipButton ? '100%' : 'fit-content' }, content: "flexEnd", children: _jsx(Button, Object.assign({ type: "primary" }, primaryProps, { children: isLastStep ? 'Понятно' : 'Далее' })) })), !continuous &&
                            _jsx(Space, { css: { width: !step.showSkipButton ? '100%' : 'fit-content' }, content: "flexEnd", children: _jsx(Button, Object.assign({ type: "primary" }, closeProps, { children: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E" })) })] })] })));
};
export { TooltipForOnboarding };
