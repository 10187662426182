import { createSlice } from '@reduxjs/toolkit';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
const initialState = {
    addSaveProduct: false,
    onboardingModal: false,
    source: '',
    changePassword: false,
    graphModal: {
        id: '',
        open: false,
    },
    completeOnboarding: false,
    paymentModal: false,
    successPaidModal: false,
    errorPaidModal: false,
    firstLoginModal: false,
    getBonusMailingModal: false,
    removePaymentModal: false,
    liteSubLimit: false,
    limitSavedProducts: false,
    paymentHistoryModal: false,
    addNewShop: false,
    saveProductLimit: false,
    payMailingModal: false,
    redemptionPercentageModal: false,
    checkSize: false,
    exitCreateCalculate: false,
    updateUserInfo: false,
    endTrialModal: false,
    whyMailingsError: false,
    updateSubModal: false,
};
const modalsSlice = createSlice({
    name: 'modalState',
    initialState,
    reducers: {
        openAddSaveProduct: (state, action) => {
            state.addSaveProduct = true;
            state.source = action.payload;
        },
        onCloseAddSaveProduct: (state) => {
            state.addSaveProduct = false;
        },
        handleGraphModal: (state, { payload }) => {
            state.graphModal = { id: payload.id, open: payload.open };
        },
        setPaymentModal: (state, { payload }) => {
            if (payload) {
                sendAmplitudeData('choose_tariff_modal_view_shown');
            }
            state.paymentModal = payload;
        },
        setOnboardingModal: (state, { payload }) => {
            state.onboardingModal = payload;
        },
        setSaveProductLimit: (state, { payload }) => {
            state.saveProductLimit = payload;
        },
        setCompleteOnboarding: (state, { payload }) => {
            state.completeOnboarding = payload;
        },
        setExitCreateCalculate: (state, { payload }) => {
            state.exitCreateCalculate = payload;
        },
        setUpdateSubModal: (state, { payload }) => {
            state.updateSubModal = payload;
        },
        setChangePassword: (state, { payload }) => {
            state.changePassword = payload;
        },
        setRedemptionPercentageModal: (state, { payload }) => {
            state.redemptionPercentageModal = payload;
        },
        setCheckSize: (state, { payload }) => {
            state.checkSize = payload;
        },
        setSuccessPaidModal: (state, { payload }) => {
            state.successPaidModal = payload;
        },
        setErrorPaidModal: (state, { payload }) => {
            state.errorPaidModal = payload;
        },
        setUpdateUserInfo: (state, { payload }) => {
            if (payload) {
                sendAmplitudeData('profile_questrionnare_modal_view_shown');
            }
            state.updateUserInfo = payload;
        },
        setFirstLoginModal: (state, { payload }) => {
            state.firstLoginModal = payload;
        },
        setRemovePaymentModal: (state, { payload }) => {
            state.removePaymentModal = payload;
        },
        setLimitSavedProducts: (state, { payload }) => {
            if (payload) {
                sendAmplitudeData('downgrate_to_lite_modal_view_shown');
            }
            state.limitSavedProducts = payload;
        },
        setLiteSubLimit: (state, { payload }) => {
            if (payload) {
                sendAmplitudeData('downgrate_to_free_modal_view_shown');
            }
            state.liteSubLimit = payload;
        },
        setPaymentHistory: (state, { payload }) => {
            state.paymentHistoryModal = payload;
        },
        setAddNewShop: (state, { payload }) => {
            state.addNewShop = payload;
        },
        setPayMailingModal: (state, { payload }) => {
            state.payMailingModal = payload;
        },
        setGetBonusMailingModal: (state, { payload }) => {
            state.getBonusMailingModal = payload;
        },
        setEndTrialModal: (state, { payload }) => {
            if (payload) {
                sendAmplitudeData('trial_finished_modal_view_shown');
            }
            state.endTrialModal = payload;
        },
        setWhyMailingsError: (state, { payload }) => {
            state.whyMailingsError = payload;
        },
    },
});
export default modalsSlice.reducer;
export const { openAddSaveProduct, setCompleteOnboarding, setChangePassword, handleGraphModal, onCloseAddSaveProduct, setOnboardingModal, setPaymentModal, setSuccessPaidModal, setErrorPaidModal, setFirstLoginModal, setWhyMailingsError, setRemovePaymentModal, setLimitSavedProducts, setLiteSubLimit, setPaymentHistory, setAddNewShop, setPayMailingModal, setSaveProductLimit, setGetBonusMailingModal, setUpdateUserInfo, setEndTrialModal, setExitCreateCalculate, setRedemptionPercentageModal, setCheckSize, setUpdateSubModal, } = modalsSlice.actions;
