import { styled } from '@/app/styles/mainStyles';
export const IconCollapse = styled('svg', {
    width: '12px',
    height: '12px',
    margin: '0 0 0 1px',
    viewBox: '0 0 10 12',
    fill: 'none',
    stroke: '$textBlackColor',
    xmlns: 'http://www.w3.org/2000/svg',
});
export const IconItem = styled('div', {
    width: '18px',
    height: '18px',
    margin: '0 0 0 1px',
    '&:hover': {
        cursor: 'pointer',
        [`${IconCollapse}`]: {
            stroke: '$active',
        },
    },
    '&:active': {
        [`${IconCollapse}`]: {
            stroke: '$activeBackground',
        },
    },
    '& > svg': {
        transition: '0.2s ease-in-out',
    },
    '&[data-state-open="false"]': {
        '& > svg': {
            transform: 'rotate(180deg)',
        },
    },
    variants: {
        sideBarHead: {
            true: {},
        },
    },
});
