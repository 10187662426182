import { styled } from '@/app/styles/mainStyles';
import { Input } from '@/shared/ui/Input/style';
export const MailingInput = styled(Input, {
    width: '82px',
    margin: '0 10px',
    color: '$scrollColor',
    fontSize: '17px',
    fontWeight: 'bold',
    fontFamily: '$inter',
});
export const AbsoluteNewYearIcon = styled('div', {
    position: 'absolute',
    width: '293px',
    right: 0,
    top: 0,
});
export const RateBlock = styled('div', {
    borderRadius: '8px',
    background: '$white',
    padding: '20px 16px',
    display: 'flex',
    marginBottom: '24px',
    gap: '12px',
    flexWrap: 'wrap',
});
export const RateMessage = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    width: '46%',
});
