import { styled } from '@/app/styles/mainStyles';
import { Text } from '@/shared/ui/Text/style';
export const ItemText = styled(Text, {});
export const ItemContainer = styled('div', {
    margin: '5px 0',
    padding: '16px',
    background: '$white',
    borderRadius: '8px',
    display: 'flex',
});
