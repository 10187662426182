import { jsx as _jsx } from "react/jsx-runtime";
import * as S from './style';
export const Space = (props) => {
    const { children, size, align, id, content, direction, textAlign, css, onClick } = props;
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };
    return (_jsx(S.Space, { id: id, onClick: handleClick, textAlign: textAlign, content: content, size: size, align: align, direction: direction, css: css, children: children }));
};
