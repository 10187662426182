import { styled } from '@/app/styles/mainStyles';
import { Input } from '@/shared/ui/Input/style';
import { Text } from '@/shared/ui/Text/style';
export const PasswordInput = styled(Input, {});
export const Label = styled('div', {
    display: 'flex',
});
export const ButtonPassword = styled('button', {
    outline: 'none',
    border: 'none',
    background: 'none',
    position: 'absolute',
    padding: '0',
    right: '0%',
    top: '29px',
    width: '24px',
    height: '24px',
    '&:hover': {
        cursor: 'pointer',
    },
});
export const TextLabel = styled(Text, {
    variants: {
        fontType: {
            nunito: {
                fontFamily: '$nunito',
                fontWeight: '700',
                fontSize: '16px',
                marginBottom: '8px',
                lineHeight: '21px',
            },
            inter: {
                fontFamily: '$inter',
                fontWeight: '700',
                fontSize: '17px',
                lineHeight: '21px',
                marginBottom: '8px',
            },
        },
    },
});
