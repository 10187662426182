var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from '@/shared/ui/Dialog/Dialog';
import React, { useState } from 'react';
import * as S from './style';
import { Text } from '@/shared/ui/Text/Text';
import { Icon } from '@/shared/ui/Icon/Icon';
import { ReactComponent as XCircle } from '@/shared/assets/icons/x-circle.svg';
import { Input } from '@/shared/ui/Input/Input';
import { Button } from '@/shared/ui/Button/Button';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { addNewProductToSave, } from '@/entity/ListSavedProductsEntity/savedProducts.slice';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { onCloseAddSaveProduct, setPaymentModal } from '@/entity/ModalsEntity/modals.slice';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
import { useQuery } from '@/shared/hooks/useQuery/useQuery';
import { withRouter } from 'react-router';
import { routes } from '@/shared/utils/routes';
import { getLimits } from '@/entity/ProductEntity/product.slice';
import { Space } from '@/shared/ui';
import { TariffsNameEnum } from '@/entity/PaymentEntity/types';
const AddSaveProduct = ({ history, setIsOpened, isDesktop }) => {
    const query = useQuery();
    const [step, setStep] = useState(1);
    const stateModal = useTypedSelector((state) => state.modalReducer);
    const subName = useTypedSelector((state) => { var _a; return (_a = state.paymentReducer.userSubscription) === null || _a === void 0 ? void 0 : _a.tariffName; });
    const [valueInput, setValueInput] = useState('');
    const dispatch = useTypedDispatch();
    function onChangeHandler(event) {
        const { value } = event.target;
        setValueInput(value);
    }
    function onCloseModal() {
        sendAmplitudeData('analyze_good_modal_view_closed_btn_clicked');
        dispatch(onCloseAddSaveProduct());
    }
    const handleOpenUpdateTariff = () => {
        dispatch(setPaymentModal(true));
    };
    function saveProduct() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (stateModal.source === 'sideBar') {
                if (!isDesktop) {
                    setIsOpened(() => {
                        const newState = false;
                        localStorage.setItem('sidebarOpened', `${newState}`);
                        return newState;
                    });
                }
                const limits = yield dispatch(getLimits());
                if (+limits.payload.userAnalyzedAvailableLimits.numberOfAnalyzed <= 0) {
                    if (subName === TariffsNameEnum.Lite) {
                        return setStep(3);
                    }
                    else {
                        return setStep(4);
                    }
                }
                const item = yield dispatch(addNewProductToSave({ url: valueInput, save: false }));
                if (!((_a = item === null || item === void 0 ? void 0 : item.payload) === null || _a === void 0 ? void 0 : _a.item)) {
                    return setStep(2);
                }
                query.delete('afterSaveId');
                query.set('urlProduct', valueInput);
                sendAmplitudeData('analyze_good_btn_clicked', {
                    inputStrValue: valueInput,
                });
                history.push({
                    pathname: routes.productWithoutSave,
                    search: query.toString(),
                });
            }
            else {
                dispatch(addNewProductToSave({ url: valueInput, save: true }));
            }
            dispatch(onCloseAddSaveProduct());
        });
    }
    const addOnKeyDown = (event) => __awaiter(void 0, void 0, void 0, function* () {
        if (event.key === 'Enter') {
            yield saveProduct();
        }
    });
    const getUiForError = () => {
        if (step === 1) {
            return (_jsxs(_Fragment, { children: [_jsx(Text, { css: { marginBottom: '24px' }, type: "title", children: "\u0410\u043D\u0430\u043B\u0438\u0437\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0442\u043E\u0432\u0430\u0440" }), _jsx(Text, { css: { marginBottom: '12px' }, type: "default", children: "\u0412\u0441\u0442\u0430\u0432\u044C\u0442\u0435 \u043F\u0440\u044F\u043C\u0443\u044E \u0441\u0441\u044B\u043B\u043A\u0443 \u043D\u0430 \u0442\u043E\u0432\u0430\u0440 \u043D\u0430 Ozon \u0438\u043B\u0438 Wildberries" }), _jsx(Icon, { onClick: onCloseModal, IconItem: XCircle, dialogClose: true }), _jsx(Input, { value: valueInput, onChange: onChangeHandler, css: { marginBottom: '24px' }, typeInput: "default", placeholder: "https://..." }), _jsx("div", { children: _jsx(Button, { onClick: saveProduct, type: "primary", children: stateModal.source === 'sideBar' ? 'Анализировать' : 'Добавить' }) })] }));
        }
        if (step === 2) {
            return (_jsxs(_Fragment, { children: [_jsx(Text, { css: { marginBottom: '24px' }, type: "title", children: "\u0410\u043D\u0430\u043B\u0438\u0437\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0442\u043E\u0432\u0430\u0440" }), _jsx(Text, { css: { marginBottom: '12px' }, type: "default", children: "\u041C\u044B \u043D\u0435 \u0441\u043C\u043E\u0433\u043B\u0438 \u043D\u0430\u0439\u0442\u0438 \u0442\u043E\u0432\u0430\u0440 \u043F\u043E \u0443\u043A\u0430\u0437\u0430\u043D\u043D\u043E\u0439 \u0441\u0441\u044B\u043B\u043A\u0435." }), _jsx(Text, { css: { marginBottom: '24px' }, type: "default", children: "\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u0443\u0431\u0435\u0434\u0438\u0442\u0435\u0441\u044C, \u0447\u0442\u043E \u0441\u0441\u044B\u043B\u043A\u0430 \u0432\u0435\u0434\u0435\u0442 \u043D\u0430 \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0443 \u0442\u043E\u0432\u0430\u0440\u0430 \u0438 \u043F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0441\u043D\u043E\u0432\u0430." }), _jsx(Text, { onClick: () => setStep(1), type: "select", children: "\u041D\u0430\u0437\u0430\u0434" })] }));
        }
        if (step === 3) {
            return (_jsxs(_Fragment, { children: [_jsx(Text, { css: { marginBottom: '24px' }, type: "title", children: "\u041B\u0438\u043C\u0438\u0442 \u0438\u0441\u0447\u0435\u0440\u043F\u0430\u043D" }), _jsx(Text, { css: { marginBottom: '56px' }, type: "default", children: "\u0412\u044B \u0438\u0441\u0447\u0435\u0440\u043F\u0430\u043B\u0438 \u043B\u0438\u043C\u0438\u0442 \u0430\u043D\u0430\u043B\u0438\u0437\u043E\u0432 \u0442\u043E\u0432\u0430\u0440\u043E\u0432 \u0432 \u044D\u0442\u043E\u043C \u043C\u0435\u0441\u044F\u0446\u0435" }), _jsxs(Space, { content: "flexEnd", size: "small", children: [_jsx(Text, { onClick: () => setStep(1), type: "select", children: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E" }), _jsx(Button, { onClick: handleOpenUpdateTariff, type: "primary", children: "\u041F\u043E\u0432\u044B\u0441\u0438\u0442\u044C \u043B\u0438\u043C\u0438\u0442\u044B" })] })] }));
        }
        if (step === 4) {
            return (_jsxs(_Fragment, { children: [_jsx(Text, { css: { marginBottom: '24px' }, type: "title", children: "\u041B\u0438\u043C\u0438\u0442 \u0438\u0441\u0447\u0435\u0440\u043F\u0430\u043D" }), _jsx(Text, { css: { marginBottom: '56px' }, type: "default", children: "\u0412\u044B \u0438\u0441\u0447\u0435\u0440\u043F\u0430\u043B\u0438 \u043B\u0438\u043C\u0438\u0442 \u0430\u043D\u0430\u043B\u0438\u0437\u043E\u0432 \u0442\u043E\u0432\u0430\u0440\u043E\u0432 \u0432 \u044D\u0442\u043E\u043C \u043C\u0435\u0441\u044F\u0446\u0435" }), _jsx(Space, { content: "flexEnd", size: "small", children: _jsx(Button, { type: "primary", onClick: () => setStep(1), children: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E" }) })] }));
        }
    };
    return (_jsx(Dialog, { css: { width: '608px' }, open: stateModal.addSaveProduct, children: _jsx(S.AddSaveProductContainer, { onKeyDown: addOnKeyDown, children: getUiForError() }) }));
};
export default withRouter(AddSaveProduct);
