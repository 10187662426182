import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CardPlanContainer, CardPlanPriceContainer, CardPlanTextWithIcon, CardPlanTitle, CardPrice, DescriptionText, PeriodText, } from '@/features/ui/Dialogs/PaymentModal/PaymentPlans/style';
import { Text } from '@/shared/ui/Text/Text';
import { Button } from '@/shared/ui/Button/Button';
import { Icon } from '@/shared/ui/Icon/Icon';
import { ReactComponent as Infinte } from '@/shared/assets/limitIcons/Infinite.svg';
import { ReactComponent as Agree } from '@/shared/assets/limitIcons/Agree.svg';
import { ReactComponent as Dissagre } from '@/shared/assets/limitIcons/Dissagre.svg';
const Free = ({ handleClickSub, tariffName, getIsFeatureTariff, withButton = true, getIsCurrentTariff }) => {
    const isTariff = getIsCurrentTariff('free');
    const isNextTariff = getIsFeatureTariff('free');
    return (_jsxs(CardPlanContainer, { children: [_jsx(CardPlanTitle, { css: { marginBottom: '12px' }, type: "pageTitle", children: "Free" }), _jsx(CardPlanPriceContainer, { children: _jsx(CardPrice, { children: _jsx(PeriodText, { css: { fontWeight: '700' }, children: "\u0411\u0435\u0441\u043F\u043B\u0430\u0442\u043D\u043E" }) }) }), _jsx(Text, { css: { marginBottom: '16px', textAlign: 'center' }, children: "\u0415\u0441\u043B\u0438 \u043D\u0443\u0436\u043D\u043E \u0442\u043E\u043B\u044C\u043A\u043E \u0441\u0447\u0438\u0442\u0430\u0442\u044C" }), withButton ? _jsx(Button, { onClick: () => handleClickSub('free'), withoutBorder: true, disabled: isTariff || !!isNextTariff, css: { marginBottom: '29px', width: '100%' }, type: "default", children: !!isNextTariff ? isNextTariff : isTariff ? 'Текущий' : 'Выбрать' })
                :
                    _jsx("div", { style: { height: '40px' } }), _jsxs(CardPlanTextWithIcon, { children: [_jsx(Icon, { css: { width: '33px', height: '12px' }, IconItem: Infinte }), _jsx(DescriptionText, { children: "\u0420\u0430\u0441\u0447\u0435\u0442 \u044D\u043A\u043E\u043D\u043E\u043C\u0438\u043A\u0438" })] }), _jsxs(CardPlanTextWithIcon, { children: [_jsx(Icon, { css: { width: '33px', height: '17px' }, IconItem: Agree }), _jsx(DescriptionText, { children: "\u041F\u043E\u0434\u0440\u043E\u0431\u0440\u044B\u0439 \u043E\u0442\u0447\u0435\u0442 \u043F\u043E \u0440\u0430\u0441\u0445\u043E\u0434\u0430\u043C" })] }), _jsxs(CardPlanTextWithIcon, { children: [_jsx(Icon, { css: { width: '33px', height: '17px' }, IconItem: Agree }), _jsx(DescriptionText, { children: "\u0420\u0430\u0441\u0447\u0435\u0442 \u044D\u043A\u043E\u043D\u043E\u043C\u0438\u043A\u0438 \u043F\u0430\u0440\u0442\u0438\u0438" })] }), _jsxs(CardPlanTextWithIcon, { children: [_jsx(Icon, { css: { width: '33px', height: '22px' }, IconItem: Dissagre }), _jsx(DescriptionText, { children: "\u0420\u0430\u0441\u0447\u0435\u0442 \u043F\u043E \u0434\u0430\u043D\u043D\u044B\u043C \u043A\u043E\u043D\u043A\u0443\u0440\u0435\u043D\u0442\u0430" })] }), _jsxs(CardPlanTextWithIcon, { css: { marginBottom: '0px' }, children: [_jsx(Icon, { css: { width: '33px', height: '22px' }, IconItem: Dissagre }), _jsx(DescriptionText, { children: "\u0410\u043D\u0430\u043B\u0438\u0437 \u043A\u043E\u043D\u043A\u0443\u0440\u0435\u043D\u0442\u043E\u0432" })] })] }));
};
export { Free };
