import { jsx as _jsx } from "react/jsx-runtime";
const YAxisTick = (payload) => {
    var _a;
    const { textAnchor, x, y, color } = payload;
    return (_jsx("g", { children: _jsx("text", { x: x, y: y, textAnchor: textAnchor, strokeWidth: 0, stroke: color, fill: color, style: {
                fontWeight: '400',
                fontSize: '13px',
                lineHeight: '12px',
                fontFamily: 'Nunito',
            }, children: new Intl.NumberFormat('en', {
                notation: 'compact',
            }).format((_a = payload === null || payload === void 0 ? void 0 : payload.payload) === null || _a === void 0 ? void 0 : _a.value) }) }));
};
export { YAxisTick };
