import { styled } from '@/app/styles/mainStyles';
export const PaymentsHistoryDescription = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',
});
export const PaymentsHistoryWrapper = styled('div', {
    maxHeight: '460px',
    overflowY: 'auto',
});
export const PaymentsHistoryContainer = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
});
