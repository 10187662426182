import { styled } from '@/app/styles/mainStyles';
export const BackgroundGray = styled('div', {
    background: '#F7F7F7',
    borderRadius: '8px',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    boxSizing: 'border-box',
    variants: {
        chose: {
            true: {
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        hover: {
            true: {
                '&:hover': {
                    cursor: 'pointer',
                    boxShadow: '0px 4px 10px 0 rgba(0, 0, 0, 0.2)',
                },
            },
        },
        active: {
            true: {
                backgroundColor: '$active !important',
                color: 'white',
            },
        },
        error: {
            true: {
                backgroundColor: '$revenueFaultBg !important',
            },
        },
    },
});
