export var OzonMailingTypeEnum;
(function (OzonMailingTypeEnum) {
    OzonMailingTypeEnum["FBO"] = "OZON_ORDERS_DELIVERY_TYPE_FBO";
    OzonMailingTypeEnum["FBS"] = "OZON_ORDERS_DELIVERY_TYPE_FBS";
})(OzonMailingTypeEnum || (OzonMailingTypeEnum = {}));
export const OzonMailingDisplayType = {
    'OZON_ORDERS_DELIVERY_TYPE_FBO': 'FBO',
    'OZON_ORDERS_DELIVERY_TYPE_FBS': 'FBS',
};
export const OzonOrderDisblayName = {
    'OZON_ORDERS_ORDER_BY_DESC': 'От новых к старым',
    'OZON_ORDERS_ORDER_BY_ASC': 'От старых к новым',
};
export const ozonOrderOrderBy = [
    {
        name: 'От новых к старым',
        value: 'OZON_ORDERS_ORDER_BY_DESC',
    },
    {
        name: 'От старых к новым',
        value: 'OZON_ORDERS_ORDER_BY_ASC',
    },
];
export var OzonPresetRange;
(function (OzonPresetRange) {
    OzonPresetRange["OneMonth"] = "oneMonth";
    OzonPresetRange["ThreeMonth"] = "ThreeMonth";
    OzonPresetRange["SixMonth"] = "SixMonth";
})(OzonPresetRange || (OzonPresetRange = {}));
