import { styled } from '@/app/styles/mainStyles';
export const Wrapper = styled('div', {
    '@desktop': {
        width: 'auto',
    },
    '@mobile': {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    },
});
export const Error = styled('div', {
    color: '$red',
    fontSize: '.75rem',
    marginTop: '.25rem',
});
