var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PaymentStatus } from './types';
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
import { getReqPayment, postReqPayment, putReqPayment, removeReqPayment } from '@/shared/api/payment';
import { setErrorPaidModal, setSuccessPaidModal } from '@/entity/ModalsEntity/modals.slice';
import Cookies from 'universal-cookie';
import { updateUserProduct } from '@/entity/ProfileEntity/profile.slice';
import { displayError } from '@/shared/utils/displayError/displayError';
const cookies = new Cookies();
const initialState = {
    tariffs: [],
    diffTariffs: [],
    userCurrentPayment: null,
    userSubscriptionName: '',
    userTariffData: null,
    nextUserTariffData: null,
    userSubscription: null,
    paymentList: {
        total: 0,
        payments: [],
    },
    mailingPrices: [],
    userSubStatus: BASE_FETCH_STATUS,
    paymentStatus: BASE_FETCH_STATUS,
};
const getPaymentTariffs = createAsyncThunk('paymentSlice/getPaymentTariffs', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getReqPayment(`tariffs?limit=10&offset=0${payload.promocode ? `&promocode=${payload.promocode}` : ''}`);
        if (payload.promocode) {
            return Object.assign({ promocode: true }, resp.data);
        }
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getDiffTariffs = createAsyncThunk('paymentSlice/getDiffTariffs', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getReqPayment(`tariff/diff?limit=10&offset=0${payload.promocode ? `&promocode=${payload.promocode}` : ''}`);
        if (payload.promocode) {
            return Object.assign({ promocode: true }, resp.data);
        }
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getMailingPrices = createAsyncThunk('paymentSlice/getMailingPrices', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getReqPayment(`mailing_prices/list?offset=0&limit=10&${payload.promocode ? `promocode=${payload.promocode}` : ''}`);
        if (payload.promocode) {
            return Object.assign({ promocode: true }, resp.data);
        }
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getUserSubscription = createAsyncThunk('paymentSlice/getUserSubscription', (payment, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        const respTariffs = yield thunkAPI.dispatch(getPaymentTariffs({}));
        const resp = yield getReqPayment('subscription');
        if (resp.data.currentSub === null) {
            return null;
        }
        if (respTariffs.payload.tariffs.length !== 0) {
            const findMyTariffs = respTariffs.payload.tariffs.find((el) => el.id === resp.data.currentSub.tariffId);
            return Object.assign({ tariffName: findMyTariffs === null || findMyTariffs === void 0 ? void 0 : findMyTariffs.name, tariffData: findMyTariffs, nextTariff: respTariffs.payload.tariffs.find((el) => el.id === resp.data.futureSub.tariffId) }, resp.data);
        }
        else {
            return resp.data;
        }
    }
    catch (error) {
        const err = error;
        if (((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
            cookies.remove('token', { path: '/' });
            return;
        }
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const startTrialSub = createAsyncThunk('paymentSlice/startTrialSub', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield postReqPayment('trial', payload);
        yield thunkAPI.dispatch(updateUserProduct({ product: 'USER_ACTIVE_PRODUCT_CALCULATOR' }));
        const tariffEnd = yield thunkAPI.dispatch(getUserSubscription());
        if (tariffEnd.payload.currentSub.finishedAt) {
            localStorage.setItem('endTrial', tariffEnd.payload.currentSub.finishedAt);
        }
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getUserPayment = createAsyncThunk('paymentSlice/getUserPayment', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getReqPayment(`payment?id=${payload.id}`);
        if (resp.data.payment.status === PaymentStatus.success) {
            thunkAPI.dispatch(setSuccessPaidModal(true));
        }
        if (resp.data.payment.status === PaymentStatus.canceled) {
            thunkAPI.dispatch(setErrorPaidModal(true));
        }
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getListPayment = createAsyncThunk('paymentSlice/getListPayment', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getReqPayment(`payments?limit=${payload.limit}&offset=${payload.offset}`);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const buySubscribe = createAsyncThunk('paymentSlice/buySubscribe', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        const resp = yield postReqPayment('buying_tariff', payload);
        window.location.replace((_a = resp.data) === null || _a === void 0 ? void 0 : _a.confirmationUrl);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const buyMailing = createAsyncThunk('paymentSlice/buyMailing', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        const resp = yield postReqPayment('buying_mailings', payload);
        window.location.replace((_a = resp.data) === null || _a === void 0 ? void 0 : _a.confirmationUrl);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const changeSubscription = createAsyncThunk('paymentSlice/changeSubscription', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield putReqPayment('subscription', payload);
        yield thunkAPI.dispatch(getUserSubscription());
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const removePaymentMethod = createAsyncThunk('paymentSlice/removePaymentMethod', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield removeReqPayment('payment_method/remove');
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const paymentSlice = createSlice({
    name: 'paymentSlice',
    initialState,
    reducers: {
        clearPaymentState: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPaymentTariffs.pending, (state) => {
            state.paymentStatus = START_FETCH_STATUS;
        });
        builder.addCase(getPaymentTariffs.fulfilled, (state, { payload }) => {
            if (!payload.promocode) {
                state.tariffs = payload.tariffs;
                state.paymentStatus.isLoading = false;
            }
        });
        builder.addCase(getPaymentTariffs.rejected, (state, { payload }) => {
            state.paymentStatus.isLoading = false;
            state.paymentStatus.error = payload;
        });
        builder.addCase(getDiffTariffs.pending, (state) => {
            state.paymentStatus = START_FETCH_STATUS;
        });
        builder.addCase(getDiffTariffs.fulfilled, (state, { payload }) => {
            state.diffTariffs = payload.tariffs;
            state.paymentStatus.isLoading = false;
        });
        builder.addCase(getDiffTariffs.rejected, (state, { payload }) => {
            state.paymentStatus.isLoading = false;
            state.paymentStatus.error = payload;
        });
        builder.addCase(getUserPayment.pending, (state) => {
            state.paymentStatus = START_FETCH_STATUS;
        });
        builder.addCase(getUserPayment.fulfilled, (state, { payload }) => {
            state.userCurrentPayment = payload.payment;
            state.paymentStatus.isLoading = false;
        });
        builder.addCase(getUserPayment.rejected, (state, { payload }) => {
            state.paymentStatus.isLoading = false;
            state.paymentStatus.error = payload;
        });
        builder.addCase(getUserSubscription.pending, (state) => {
            state.userSubStatus = START_FETCH_STATUS;
        });
        builder.addCase(getUserSubscription.fulfilled, (state, { payload }) => {
            if (payload === null || payload === void 0 ? void 0 : payload.tariffName) {
                state.userSubscriptionName = payload.tariffName;
            }
            if (payload === null || payload === void 0 ? void 0 : payload.tariffData) {
                state.userTariffData = payload.tariffData;
            }
            if (payload === null || payload === void 0 ? void 0 : payload.nextTariff) {
                state.nextUserTariffData = payload.nextTariff;
            }
            state.userSubscription = payload;
            state.userSubStatus.isLoading = false;
        });
        builder.addCase(getUserSubscription.rejected, (state, { payload }) => {
            state.userSubStatus.isLoading = false;
            state.userSubStatus.error = payload;
        });
        builder.addCase(getListPayment.pending, (state) => {
            state.paymentStatus = START_FETCH_STATUS;
        });
        builder.addCase(getListPayment.fulfilled, (state, { payload }) => {
            let lastSuccessPayment = null;
            for (let i = 0; i < (payload === null || payload === void 0 ? void 0 : payload.payments.length); i++) {
                if ((payload === null || payload === void 0 ? void 0 : payload.payments[i].status) === PaymentStatus.success) {
                    lastSuccessPayment = payload === null || payload === void 0 ? void 0 : payload.payments[i];
                    break;
                }
            }
            state.paymentList = payload;
            state.userCurrentPayment = lastSuccessPayment;
            state.paymentStatus.isLoading = false;
        });
        builder.addCase(getListPayment.rejected, (state, { payload }) => {
            state.paymentStatus.isLoading = false;
            state.paymentStatus.error = payload;
        });
        builder.addCase(startTrialSub.pending, (state) => {
            state.paymentStatus = START_FETCH_STATUS;
        });
        builder.addCase(startTrialSub.fulfilled, (state) => {
            state.paymentStatus.isLoading = false;
        });
        builder.addCase(startTrialSub.rejected, (state, { payload }) => {
            state.paymentStatus.isLoading = false;
            state.paymentStatus.error = payload;
        });
        builder.addCase(getMailingPrices.pending, (state) => {
            state.paymentStatus = START_FETCH_STATUS;
        });
        builder.addCase(getMailingPrices.fulfilled, (state, { payload }) => {
            state.paymentStatus.isLoading = false;
            if (!payload.promocode) {
                state.mailingPrices = payload === null || payload === void 0 ? void 0 : payload.mailingPrices;
            }
        });
        builder.addCase(getMailingPrices.rejected, (state, { payload }) => {
            state.paymentStatus.isLoading = false;
            state.paymentStatus.error = payload;
        });
        builder.addCase(buyMailing.pending, (state) => {
            state.paymentStatus = START_FETCH_STATUS;
        });
        builder.addCase(buyMailing.fulfilled, (state) => {
            state.paymentStatus.isLoading = false;
        });
        builder.addCase(buyMailing.rejected, (state, { payload }) => {
            state.paymentStatus.isLoading = false;
            state.paymentStatus.error = payload;
        });
        builder.addCase(changeSubscription.pending, (state) => {
            state.paymentStatus = START_FETCH_STATUS;
        });
        builder.addCase(changeSubscription.fulfilled, (state) => {
            state.paymentStatus.isLoading = false;
        });
        builder.addCase(changeSubscription.rejected, (state, { payload }) => {
            state.paymentStatus.isLoading = false;
            state.paymentStatus.error = payload;
        });
        builder.addCase(removePaymentMethod.pending, (state) => {
            state.paymentStatus = START_FETCH_STATUS;
        });
        builder.addCase(removePaymentMethod.fulfilled, (state) => {
            state.paymentStatus.isLoading = false;
            state.userCurrentPayment = null;
        });
        builder.addCase(removePaymentMethod.rejected, (state, { payload }) => {
            state.paymentStatus.isLoading = false;
            state.paymentStatus.error = payload;
        });
    },
});
export default paymentSlice.reducer;
export const { clearPaymentState } = paymentSlice.actions;
export { getPaymentTariffs, getUserPayment, buySubscribe, getUserSubscription, getListPayment, startTrialSub, getMailingPrices, buyMailing, removePaymentMethod, changeSubscription, getDiffTariffs, };
