import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import AddSaveProduct from '@/features/ui/Dialogs/AddSaveProduct/AddSaveProduct';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { GraphModal } from '@/features/ui/Dialogs/GraphModal/GraphModal';
import React, { useEffect } from 'react';
import { ChangePassword } from '@/features/ui/Dialogs/AuthFlow/ChangePassword/ChangePassword';
import { CompleteOnboardingModal, } from '@/features/ui/Dialogs/CompleteOnboardingModal/CompleteOnboardingModal';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { setCheckSize, setCompleteOnboarding, setEndTrialModal, setLimitSavedProducts, setLiteSubLimit, setRedemptionPercentageModal, setUpdateSubModal, } from '@/entity/ModalsEntity/modals.slice';
import { PaymentModal } from '@/features/ui/Dialogs/PaymentModal/PaymentModal';
import { SuccessSubscribePayment, } from '@/features/ui/Dialogs/SubscribeFlow/SuccessSubscribePayment/SuccessSubscribePayment';
import { ErrorSubscribePayment, } from '@/features/ui/Dialogs/SubscribeFlow/ErrorSubscribePayment/ErrorSubscribePayment';
import { FirstLoginModal } from '@/features/ui/Dialogs/FirstLogin/FirstLogin';
import { RemovePaymentModal } from '@/features/ui/Dialogs/RemovePaymentModal/RemovePaymentModal';
import { LimitSavedProducts } from '@/features/ui/Dialogs/LimitFlow/LimitSavedProducts/LimitSavedProducts';
import { LiteSubLimit } from '@/features/ui/Dialogs/LiteSubLimit/LiteSubLimit';
import { PaymentsHistory } from '@/features/ui/Dialogs/PaymentsHistory/PaymentsHistory';
import AddNewShopModal from '@/features/ui/Dialogs/AddNewShopModal/AddNewShopModal';
import { TariffsNameEnum } from '@/entity/PaymentEntity/types';
import { PayMailing } from '@/features/ui/Dialogs/PayMailing/PayMailing';
import { GetBonusMailing } from '@/features/ui/Dialogs/GetBonusMailing/GetBonusMailing';
import { LimitSaveProduct } from '@/features/ui/Dialogs/LimitSaveProduct/LimitSaveProduct';
import { UpdateUserInfo } from '@/widgets/ui/AuthFlow/ui/UpdateUserInfo/UpdateUserInfo';
import { checkUserToUpdateInfo } from '@/shared/utils/сheckUserToUpdateInfo';
import { setNotification } from '@/entity/ProfileEntity/profile.slice';
import { EndTrialModal } from '@/features/ui/Dialogs/EndTrialModal/EndTrialModal';
import { compareTwoDates } from '@/shared/utils/compareTwoDates';
import { WhyMailingsError } from '@/features/ui/Dialogs/WhyMailingsError/WhyMailingsError';
import { useOnboarding } from '@/shared/hooks/useOnboarding/useOnboarding';
import { RedemptionPercentageModal } from '@/features/ui/Dialogs/RedemptionPercentageModal/RedemptionPercentageModal';
import { CheckSizeModal } from '@/features/ui/Dialogs/CheckSizeModal/CheckSizeModal';
import { UpdateSubModal } from '@/features/ui/Dialogs/UpdateSubModal/UpdateSubModal';
import { useQuery } from '@/shared/hooks/useQuery/useQuery';
const Modals = ({ isDesktop, setIsOpened }) => {
    const dispatch = useTypedDispatch();
    const query = useQuery();
    const prevSubId = query.get('prevSubId');
    const featureSubId = query.get('featureSubId');
    const { whyMailingsError, endTrialModal, getBonusMailingModal, redemptionPercentageModal, checkSize, successPaidModal, errorPaidModal, paymentModal, addSaveProduct, graphModal, changePassword, addNewShop, completeOnboarding, firstLoginModal, removePaymentModal, limitSavedProducts, liteSubLimit, paymentHistoryModal, payMailingModal, saveProductLimit, updateUserInfo, updateSubModal, } = useTypedSelector((state) => state.modalReducer);
    const userInfo = useTypedSelector((state) => state.profileReducer.profile);
    const currentSubscriptionName = useTypedSelector((state) => state.paymentReducer.userSubscriptionName);
    const products = useTypedSelector((state) => state.savedProducts.products);
    useOnboarding();
    useEffect(() => {
        if (userInfo) {
            if (!checkUserToUpdateInfo(userInfo)) {
                dispatch(setNotification(true));
            }
            if (compareTwoDates()) {
                dispatch(setEndTrialModal(true));
            }
        }
    }, [userInfo]);
    useEffect(() => {
        if (currentSubscriptionName === TariffsNameEnum.Free && products.length !== 0) {
            dispatch(setLiteSubLimit(true));
        }
        if (currentSubscriptionName === TariffsNameEnum.Lite && products.length > 30) {
            dispatch(setLimitSavedProducts(true));
        }
    }, [currentSubscriptionName, products]);
    useEffect(() => {
        if (featureSubId && prevSubId) {
            dispatch(setUpdateSubModal(true));
        }
    }, []);
    return (_jsxs(_Fragment, { children: [whyMailingsError && _jsx(WhyMailingsError, { open: whyMailingsError }), saveProductLimit && _jsx(LimitSaveProduct, { open: saveProductLimit }), updateUserInfo && _jsx(UpdateUserInfo, { open: updateUserInfo }), getBonusMailingModal && _jsx(GetBonusMailing, { open: getBonusMailingModal }), payMailingModal && _jsx(PayMailing, { open: payMailingModal, isDesktop: isDesktop }), liteSubLimit && _jsx(LiteSubLimit, { open: liteSubLimit }), firstLoginModal && _jsx(FirstLoginModal, { open: firstLoginModal }), limitSavedProducts && _jsx(LimitSavedProducts, { isDesktop: isDesktop, open: limitSavedProducts }), removePaymentModal && _jsx(RemovePaymentModal, { open: removePaymentModal }), addSaveProduct && _jsx(AddSaveProduct, { setIsOpened: setIsOpened, isDesktop: isDesktop }), graphModal && _jsx(GraphModal, { open: graphModal.open, id: graphModal.id }), changePassword && _jsx(ChangePassword, {}), updateSubModal && _jsx(UpdateSubModal, {}), redemptionPercentageModal &&
                _jsx(RedemptionPercentageModal, { open: redemptionPercentageModal, isDesktop: isDesktop, handleClose: () => dispatch(setRedemptionPercentageModal(false)) }), checkSize &&
                _jsx(CheckSizeModal, { open: checkSize, handleClose: () => dispatch(setCheckSize(false)), isDesktop: isDesktop }), paymentModal && _jsx(PaymentModal, { open: paymentModal, isDesktop: isDesktop }), successPaidModal && _jsx(SuccessSubscribePayment, { open: successPaidModal }), errorPaidModal && _jsx(ErrorSubscribePayment, { open: errorPaidModal }), completeOnboarding &&
                _jsx(CompleteOnboardingModal, { open: completeOnboarding, onClose: () => dispatch(setCompleteOnboarding(false)) }), paymentHistoryModal && _jsx(PaymentsHistory, { open: paymentHistoryModal }), addNewShop && _jsx(AddNewShopModal, { shop: null, open: addNewShop }), endTrialModal && _jsx(EndTrialModal, { open: endTrialModal })] }));
};
export { Modals };
