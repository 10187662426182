export var ExperienceNameEnum;
(function (ExperienceNameEnum) {
    ExperienceNameEnum["EXPERIENCE_UNKNOWN"] = "EXPERIENCE_UNKNOWN";
    ExperienceNameEnum["EXPERIENCE_NOT_YET_WORK"] = "EXPERIENCE_NOT_YET_WORK";
    ExperienceNameEnum["EXPERIENCE_LESS_THEN_6_MONTH"] = "EXPERIENCE_LESS_THEN_6_MONTH";
    ExperienceNameEnum["EXPERIENCE_LESS_THEN_1_YEAR"] = "EXPERIENCE_LESS_THEN_1_YEAR";
    ExperienceNameEnum["EXPERIENCE_BETWEEN_1_AND_3_YEARS"] = "EXPERIENCE_BETWEEN_1_AND_3_YEARS";
    ExperienceNameEnum["EXPERIENCE_BETWEEN_3_AND_5_YEARS"] = "EXPERIENCE_BETWEEN_3_AND_5_YEARS";
    ExperienceNameEnum["EXPERIENCE_MORE_THEN_5_YEARS"] = "EXPERIENCE_MORE_THEN_5_YEARS";
})(ExperienceNameEnum || (ExperienceNameEnum = {}));
export var TotalMarketsEnum;
(function (TotalMarketsEnum) {
    TotalMarketsEnum["TOTAL_MARKETS_NOT_YET"] = "TOTAL_MARKETS_NOT_YET";
    TotalMarketsEnum["TOTAL_MARKETS_ONE"] = "TOTAL_MARKETS_ONE";
    TotalMarketsEnum["TOTAL_MARKETS_TWO_OR_THREE"] = "TOTAL_MARKETS_TWO_OR_THREE";
    TotalMarketsEnum["TOTAL_MARKETS_BETWEEN_FOUR_AND_TEN"] = "TOTAL_MARKETS_BETWEEN_FOUR_AND_TEN";
    TotalMarketsEnum["TOTAL_MARKETS_MORE_THEN_TEN"] = "TOTAL_MARKETS_MORE_THEN_TEN";
    TotalMarketsEnum["TOTAL_MARKETS_UNKNOWN"] = "TOTAL_MARKETS_UNKNOWN";
})(TotalMarketsEnum || (TotalMarketsEnum = {}));
export var SkuListEnum;
(function (SkuListEnum) {
    SkuListEnum["SKU_UNKNOWN"] = "SKU_UNKNOWN";
    SkuListEnum["SKU_SEVERAL"] = "SKU_SEVERAL";
    SkuListEnum["SKU_LESS_THEN_50"] = "SKU_LESS_THEN_50";
    SkuListEnum["SKU_BETWEEN_50_AND_100"] = "SKU_BETWEEN_50_AND_100";
    SkuListEnum["SKU_BETWEEN_100_AND_300"] = "SKU_BETWEEN_100_AND_300";
    SkuListEnum["SKU_BETWEEN_300_AND_500"] = "SKU_BETWEEN_300_AND_500";
    SkuListEnum["SKU_BETWEEN_500_AND_1000"] = "SKU_BETWEEN_500_AND_1000";
    SkuListEnum["SKU_BETWEEN_1000_AND_3000"] = "SKU_BETWEEN_1000_AND_3000";
    SkuListEnum["SKU_MORE_THEN_3000"] = "SKU_MORE_THEN_3000";
})(SkuListEnum || (SkuListEnum = {}));
export var OwnRoleEnum;
(function (OwnRoleEnum) {
    OwnRoleEnum["OWN_ROLE_UNKNOWN"] = "OWN_ROLE_UNKNOWN";
    OwnRoleEnum["OWN_ROLE_I_WANT_TO_SELL_ON_MARKETPLACES"] = "OWN_ROLE_I_WANT_TO_SELL_ON_MARKETPLACES";
    OwnRoleEnum["OWN_ROLE_I_SELL_IT_MYSELF"] = "OWN_ROLE_I_SELL_IT_MYSELF";
    OwnRoleEnum["OWN_ROLE_I_WORK_IN_A_COMPANY"] = "OWN_ROLE_I_WORK_IN_A_COMPANY";
    OwnRoleEnum["OWN_ROLE_FOUNDED_MY_COMPANY"] = "OWN_ROLE_FOUNDED_MY_COMPANY";
})(OwnRoleEnum || (OwnRoleEnum = {}));
export var MarketplaceListEnum;
(function (MarketplaceListEnum) {
    MarketplaceListEnum["Ozon"] = "MARKET_OZON";
    MarketplaceListEnum["Wildberries"] = "MARKET_WILD_BERRIES";
    MarketplaceListEnum["YandexMarket"] = "MARKET_YANDEX";
    MarketplaceListEnum["SberMegaMarket"] = "MARKET_SBER_MEGA";
})(MarketplaceListEnum || (MarketplaceListEnum = {}));
