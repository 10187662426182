export function localeTime(duration) {
    switch (duration) {
        case '360':
            return '12 месяцев';
        case '30':
            return '1 месяц';
        case '90':
            return '3 месяца';
    }
}
