import * as Progress from '@radix-ui/react-progress';
import { styled } from '@/app/styles/mainStyles';
export const ProgressRoot = styled(Progress.Root, {
    position: 'relative',
    overflow: 'hidden',
    background: 'transparent',
    borderRadius: '4px',
    border: '1px solid $border',
    width: '100%',
    height: 25,
    transform: 'translateZ(0)',
});
export const ProgressIndicator = styled(Progress.Indicator, {
    backgroundColor: '$activeBackground',
    width: '100%',
    height: '100%',
    transition: 'transform 660ms cubic-bezier(0.65, 0, 0.35, 1)',
});
export const ProgressLeftFakeRoot = styled(Progress.Root, {
    position: 'relative',
    overflow: 'hidden',
    background: 'transparent',
    borderRadius: '4px 0 0 4px',
    borderLeft: '1px solid $border',
    borderTop: '1px solid $border',
    borderRight: 'none',
    borderBottom: '1px solid $border',
    width: '20%',
    height: 25,
    transform: 'translateZ(0)',
});
export const ProgressRightFakeRoot = styled(Progress.Root, {
    position: 'relative',
    overflow: 'hidden',
    background: 'transparent',
    borderRadius: '0 4px 4px 0',
    border: '1px solid $border',
    borderRight: '1px solid $border',
    borderTop: '1px solid $border',
    borderBottom: '1px solid $border',
    borderLeft: 'none',
    width: '100%',
    height: 25,
    transform: 'translateZ(0)',
});
export const ProgressFakeIndicator = styled(Progress.Indicator, {
    backgroundColor: '$activeBackground',
    width: '100%',
    height: '100%',
    transition: 'transform 660ms cubic-bezier(0.65, 0, 0.35, 1)',
});
