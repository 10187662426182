var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getProduct, postProduct, removeProduct } from '@/shared/api/calculator';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
import Cookies from 'universal-cookie';
import { displayError } from '@/shared/utils/displayError/displayError';
const cookies = new Cookies();
const initialState = {
    products: [],
    productsStatus: BASE_FETCH_STATUS,
    fetchProduct: BASE_FETCH_STATUS,
};
const getSavedProducts = createAsyncThunk('savedProducts/getSavedProducts', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const start = performance.now();
    try {
        const resp = yield getProduct(`/list?limit=${payload.limit}&offset=${payload.offset}`);
        const end = performance.now();
        sendAmplitudeData('SLA_goods_list', {
            duration: Number(end - start),
            apiSuccess: true,
            countItems: resp.data.total,
        });
        return resp.data.goods;
    }
    catch (error) {
        const end = performance.now();
        sendAmplitudeData('SLA_goods_list', {
            duration: Number(end - start),
            apiSuccess: false,
            countItems: 0,
        });
        const err = error;
        if (err.response.status === 401) {
            cookies.remove('token', { path: '/' });
            return;
        }
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getGoodById = createAsyncThunk('savedProducts/getGoodById', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getProduct(`/good?id=${payload.id}`);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const removeProductsIds = createAsyncThunk('savedProducts/removeProductsIds', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield postProduct('/goods', payload);
        return payload;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const removeAllUserProducts = createAsyncThunk('savedProducts/removeAllUserProducts', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield removeProduct('/user_goods');
        return true;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const removeProductById = createAsyncThunk('savedProducts/removeProductById', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield removeProduct(`/good?id=${payload.id}`);
        return payload.id;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const addNewProductToSave = createAsyncThunk('savedProducts/addNewProductToSave', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const start = performance.now();
    try {
        const resp = yield postProduct('/good', payload);
        if (!payload.save) {
            const end = performance.now();
            sendAmplitudeData('SLA_analyze_good', {
                duration: Number(end - start),
                apiSuccess: true,
                inputStrValue: payload.url,
            });
        }
        return { item: resp.data.good, save: payload.save };
    }
    catch (error) {
        const err = error;
        if (!payload.save) {
            const end = performance.now();
            sendAmplitudeData('SLA_analyze_good', {
                duration: Number(end - start),
                apiSuccess: false,
                inputStrValue: payload.url,
            });
        }
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const savedProductSlice = createSlice({
    name: 'productSlice',
    initialState,
    reducers: {
        clearSavedProducts: (state) => {
            state.products = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSavedProducts.pending, (state) => {
            state.productsStatus = START_FETCH_STATUS;
        });
        builder.addCase(getSavedProducts.rejected, (state, { payload }) => {
            state.productsStatus.isLoading = false;
            state.productsStatus.error = payload;
        });
        builder.addCase(getSavedProducts.fulfilled, (state, { payload }) => {
            state.products = payload;
            state.productsStatus.isLoading = false;
        });
        builder.addCase(addNewProductToSave.pending, (state) => {
            state.fetchProduct = START_FETCH_STATUS;
        });
        builder.addCase(addNewProductToSave.rejected, (state, { payload }) => {
            state.fetchProduct.isLoading = false;
            state.fetchProduct.error = payload;
        });
        builder.addCase(addNewProductToSave.fulfilled, (state, { payload }) => {
            if (payload.save) {
                state.products = [payload.item, ...state.products];
            }
            state.fetchProduct.isLoading = false;
        });
        builder.addCase(getGoodById.pending, (state) => {
            state.fetchProduct = START_FETCH_STATUS;
        });
        builder.addCase(getGoodById.rejected, (state, { payload }) => {
            state.fetchProduct.isLoading = false;
            state.fetchProduct.error = payload;
        });
        builder.addCase(getGoodById.fulfilled, (state, { payload }) => {
            state.fetchProduct.isLoading = false;
            state.fetchProduct.error = payload;
        });
        builder.addCase(removeProductsIds.pending, (state) => {
            state.fetchProduct = START_FETCH_STATUS;
        });
        builder.addCase(removeProductsIds.rejected, (state, { payload }) => {
            state.fetchProduct.isLoading = false;
            state.fetchProduct.error = payload;
        });
        builder.addCase(removeProductsIds.fulfilled, (state, { payload }) => {
            state.fetchProduct.isLoading = false;
            state.products = state.products.filter((el) => !payload.goods.includes(el.id));
        });
        builder.addCase(removeProductById.pending, (state) => {
            state.productsStatus = START_FETCH_STATUS;
        });
        builder.addCase(removeProductById.rejected, (state, { payload }) => {
            state.productsStatus.isLoading = false;
            state.productsStatus.error = payload;
        });
        builder.addCase(removeProductById.fulfilled, (state, { payload }) => {
            state.products = state.products.filter((el) => el.id !== payload);
            state.productsStatus.isLoading = false;
        });
        builder.addCase(removeAllUserProducts.pending, (state) => {
            state.productsStatus = START_FETCH_STATUS;
        });
        builder.addCase(removeAllUserProducts.rejected, (state, { payload }) => {
            state.productsStatus.isLoading = false;
            state.productsStatus.error = payload;
        });
        builder.addCase(removeAllUserProducts.fulfilled, (state) => {
            state.products = [];
            state.productsStatus.isLoading = false;
        });
    },
});
export default savedProductSlice.reducer;
export const { clearSavedProducts } = savedProductSlice.actions;
export { getSavedProducts, getGoodById, removeProductById, addNewProductToSave, removeProductsIds, removeAllUserProducts };
