import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from '../style';
import { Text, BreadCrumbs, Space, Button } from '@/shared/ui';
import { NewPriceBlock } from '../NewPriceBlock/NewPriceBlock';
import { Lite } from '@/features/ui/Dialogs/PaymentModal/PaymentPlans/Lite/Lite';
import { Pro } from '@/features/ui/Dialogs/PaymentModal/PaymentPlans/Pro/Pro';
import { NewTariffText } from '@/features/ui/Dialogs/PaymentModal/UpgradeSub/NewTariffText/NewTariffText';
const ToSub = ({ isDesktop, handleBack, subType, activeValuesForPlans, getIsCurrentTariff, activePeriod, handleChangeTariff, getIsFeatureTariff, }) => {
    const getItem = () => {
        switch (subType) {
            case 'lite': {
                return _jsx(Lite, { withButton: false, getIsFeatureTariff: getIsFeatureTariff, getIsCurrentTariff: getIsCurrentTariff, price: activeValuesForPlans.lite, sale: activeValuesForPlans.liteSale, tariffName: "Lite", handleClickSub: () => {
                    } });
            }
            case 'pro': {
                return _jsx(Pro, { getIsFeatureTariff: getIsFeatureTariff, withButton: false, getIsCurrentTariff: getIsCurrentTariff, price: activeValuesForPlans.pro, sale: activeValuesForPlans.proSale, handleClickSub: () => {
                    }, tariffName: "Pro" });
            }
        }
    };
    return (_jsxs(S.UpgradeSubWrapper, { children: [isDesktop && getItem(), _jsxs(S.UpgradeDescriptionContainer, { children: [_jsxs(Space, { direction: "vertical", children: [_jsx(BreadCrumbs, { css: { padding: '0 0 24px 0' }, goBackFunc: handleBack }), _jsxs(Space, { css: { marginBottom: '24px' }, direction: 'vertical', size: "default", children: [_jsx(Text, { css: { marginBottom: '6px' }, type: "preTitle", children: "\u041F\u043E\u0432\u044B\u0441\u0438\u0442\u044C \u0442\u0430\u0440\u0438\u0444 \u0434\u043E Pro" }), _jsx(NewTariffText, { subType: subType, activePeriod: activePeriod })] }), _jsx(NewPriceBlock, { subType: subType, activePeriod: activePeriod })] }), _jsx(Space, { content: "flexEnd", children: _jsx(Button, { onClick: () => handleChangeTariff('sub'), type: "payment", children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438" }) })] })] }));
};
export { ToSub };
