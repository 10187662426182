import { keyframes } from '@stitches/react';
import * as HoverCardPrimitive from '@radix-ui/react-hover-card';
import { styled } from '@/app/styles/mainStyles';
export { Trigger, Root, Portal } from '@radix-ui/react-hover-card';
const slideUpAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});
const slideRightAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(-2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});
const slideDownAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});
const slideLeftAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});
const StyledContent = styled(HoverCardPrimitive.Content, {
    display: 'flex',
    color: 'black',
    maxWidth: '530px',
    height: '560px',
    borderRadius: '6px',
    justifyContent: 'center',
    zIndex: '99999',
    alignItems: 'center',
    padding: '12px 16px',
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: '$montserrat',
    backgroundColor: 'white',
    boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    '@media (prefers-reduced-motion: no-preference)': {
        animationDuration: '400ms',
        animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
        animationFillMode: 'forwards',
        willChange: 'transform, opacity',
        '&[data-state="delayed-open"]': {
            '&[data-side="top"]': { animationName: slideDownAndFade },
            '&[data-side="right"]': { animationName: slideLeftAndFade },
            '&[data-side="bottom"]': { animationName: slideUpAndFade },
            '&[data-side="left"]': { animationName: slideRightAndFade },
        },
    },
    '@tablet': {
        whiteSpace: 'pre-wrap',
    },
});
const StyledArrow = styled(HoverCardPrimitive.Arrow, {
    fill: 'white',
});
// Exports
export const Content = StyledContent;
export const Arrow = StyledArrow;
