import { styled } from '@/app/styles/mainStyles';
export const AnimatedButton = styled('div', {
// base styles
// animationDirection: 'alternate',
// animationDuration: '500ms',
// animationName: `${scaleUp}`,
// animationIterationCount: 1,
// animationTimingFunction: 'linear',
// '&:disabled': {
//   transition: 'margin-left 1s',
// },
});
