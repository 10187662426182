import { styled } from '@/app/styles/mainStyles';
export const RestorePasswordContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
});
export const RestoreHoverButton = styled('div', {
    '&:hover': {
        cursor: 'pointer',
    },
});
export const NavLinkSupport = styled('a', {
    textDecoration: 'none',
    '&:hover': {
        cursor: 'pointer',
    },
});
export const CodeInput = styled('input', {
    border: '1px solid $border',
    outline: 'none',
    borderRadius: '6px',
    fontSize: '26px',
    width: '25px',
    padding: '10px 10px 10px 18px',
    margin: '0 0 0 15px',
});
