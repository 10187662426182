export const ErrorCalculator = {
    1: 'Потеря соединения. Проверьте интернет и перезагрузите страницу',
    3: 'Неправильно заполнено одно из полей',
    4: 'Что-то пошло не так. Попробуйте перезагрузить страницу',
    5: 'not found',
    6: 'Магазин с таким (названием/доступами) уже существует',
    7: 'Ошибка доступа. Попробуйте перезагрузить страницу',
    13: 'Внутренняя ошибка. Обратитесь в Поддержку',
    16: 'Время сессии истекло. Попробуйте обновить страницу',
};
