import { styled } from '@/app/styles/mainStyles';
import { SideBarSectionButtonRow } from '@/widgets/ui/SideBar/style';
import { BreadCrumbsContainer } from '@/shared/ui/BreadCrumbs/style';
export const Icon = styled('svg', {
    display: 'inline-block',
    marginRight: '5px',
    width: '21px',
    height: '18px',
    variants: {
        sideBarHover: {
            sideBar: {
                fill: '#3C435A',
                [`${SideBarSectionButtonRow}:hover &`]: {
                    fill: '#545FC9 !important',
                },
                // [`${SideBarBodySectionButton}:hover &`]: {
                //   fill: '#545FC9 !important',
                // },
                [`${BreadCrumbsContainer}:hover &`]: {
                    fill: '$active !important',
                },
            },
        },
        rotate: {
            false: {
                transform: 'rotate(-180deg)',
            },
        },
        hoverSetting: {
            true: {
                '&:hover': {
                    opacity: '.7',
                    cursor: 'pointer',
                },
            },
        },
        fill: {
            sideBar: {
                fill: '#000000',
            },
        },
        active: {
            true: {
                fill: '#2A3389',
            },
        },
        dialogClose: {
            true: {
                fill: '#3C435A',
                width: '24px',
                height: '24px',
                position: 'absolute',
                top: '18px',
                right: '18px',
                '&:hover': {
                    opacity: '.7',
                    cursor: 'pointer',
                },
            },
        },
    },
});
