import { Root, Indicator, } from '@radix-ui/react-checkbox';
import { styled } from '@/app/styles/mainStyles';
export const StyledRoot = styled(Root, {
    all: 'unset',
    width: 16,
    height: 16,
    borderRadius: '2px',
    border: '1px solid #545FC9',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F7F7F7',
    '&:hover': {
        cursor: 'pointer',
    },
    variants: {
        type: {
            active: {
                background: '#545FC9',
            },
        },
        disabled: {
            true: {
                opacity: '.7',
                border: '1px solid #B3B8E7',
                borderRadius: '2px',
                cursor: 'not-allowed',
            },
        },
        error: {
            true: {
                border: '1px solid red',
            },
        },
    },
});
export const StyledIndicator = styled(Indicator, {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    variants: {
        disabled: {
            true: {
                opacity: '.7',
                cursor: 'not-allowed',
            },
        },
    },
});
