var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import * as S from './style';
export const NumberInput = forwardRef((_a, ref) => {
    var { value, onChange, css, max, min, typeInput, disabled, placeholder, error } = _a, props = __rest(_a, ["value", "onChange", "css", "max", "min", "typeInput", "disabled", "placeholder", "error"]);
    const handleChange = (event) => {
        if (onChange) {
            onChange(event);
        }
    };
    return (_jsx(S.Input, Object.assign({ ref: ref, css: css, value: value, type: "number", error: error && true, onChange: handleChange, disabled: disabled, placeholder: placeholder, min: min, max: max, typeInput: typeInput }, props)));
});
