import { Content, Root, Trigger, Arrow, Portal } from '@radix-ui/react-popover';
import { styled } from '@/app/styles/mainStyles';
import { keyframes } from '@stitches/react';
const slideUpAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});
const slideRightAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(-2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});
const slideDownAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});
const slideLeftAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});
const PopoverContent = styled(Content, {
    borderRadius: 4,
    fontFamily: '$nunito',
    padding: 16,
    maxWidth: '220px',
    zIndex: 9999,
    border: 'none',
    outline: 'none',
    backgroundColor: 'white',
    boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
        '&[data-side="top"]': { animationName: slideDownAndFade },
        '&[data-side="right"]': { animationName: slideLeftAndFade },
        '&[data-side="bottom"]': { animationName: slideUpAndFade },
        '&[data-side="left"]': { animationName: slideRightAndFade },
    },
    '&:focus': {
        boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    },
    variants: {
        type: {
            dark: {
                backgroundColor: '$scrollColor',
                color: 'white !important',
                boxShadow: 'none',
            },
        },
        width: {
            triggerWidth: {
                display: 'flex',
                width: 'var(--radix-popover-trigger-width) !important',
                maxWidth: 'var(--radix-popover-trigger-width)',
                maxHeight: 'calc(var(--radix-popover-content-available-height) - 12px)',
                height: 'fit-content',
            },
        },
    },
});
const PopoverArrow = styled(Arrow, {
    fill: 'white',
    variants: {
        type: {
            dark: {
                fill: '$scrollColor',
            },
        },
    },
});
export { Root, Trigger, PopoverContent, PopoverArrow, Portal };
