import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Icon, Space, Text } from '@/shared/ui';
import { ReactComponent as Help } from '@/shared/assets/icons/HelpBlue.svg';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { setWhyMailingsError } from '@/entity/ModalsEntity/modals.slice';
const ShopAddedWithoutMailings = ({ shopName, handleClose }) => {
    const dispatch = useTypedDispatch();
    const handleClick = () => {
        dispatch(setWhyMailingsError(true));
    };
    return (_jsxs(Space, { direction: "vertical", children: [_jsx(Text, { css: { marginBottom: '24px' }, type: "preTitle", children: "\u0420\u0430\u0441\u0441\u044B\u043B\u043A\u0430 \u043D\u0435\u0434\u043E\u0441\u0442\u0443\u043F\u043D\u0430 :(" }), _jsxs(Text, { type: "default", css: { marginBottom: '24px' }, children: ["\u041A \u0441\u043E\u0436\u0430\u043B\u0435\u043D\u0438\u044E, Harvester \u0420\u0430\u0441\u0441\u044B\u043B\u043A\u0430 \u043D\u0435\u0434\u043E\u0441\u0442\u0443\u043F\u043D\u0430 \u0434\u043B\u044F \u043C\u0430\u0433\u0430\u0437\u0438\u043D\u0430 ", _jsx("strong", { children: shopName }), "."] }), _jsxs(Text, { type: "default", css: { marginBottom: '24px' }, children: ["\u041C\u044B \u0431\u0443\u0434\u0435\u043C \u043F\u0440\u043E\u0432\u0435\u0440\u044F\u0442\u044C \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u043E\u0441\u0442\u044C \u0440\u0430\u0441\u0441\u044B\u043B\u043E\u043A \u0434\u043B\u044F \u043C\u0430\u0433\u0430\u0437\u0438\u043D\u0430 ", _jsx("strong", { children: shopName }), " \u0438 \u0441\u043E\u043E\u0431\u0449\u0438\u043C, \u043A\u043E\u0433\u0434\u0430 \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0438 \u0441\u0442\u0430\u043D\u0443\u0442 \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u044B \u0434\u043B\u044F \u0432\u0430\u0448\u0435\u0433\u043E \u043C\u0430\u0433\u0430\u0437\u0438\u043D\u0430."] }), _jsxs(Space, { size: "default", children: [_jsx(Button, { onClick: handleClose, type: "primary", children: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E :(" }), _jsxs(Space, { onClick: handleClick, align: "center", size: "small", children: [_jsx(Icon, { IconItem: Help }), _jsx(Text, { type: "select", children: "\u041F\u043E\u0447\u0435\u043C\u0443 \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0430 \u043D\u0435\u0434\u043E\u0441\u0442\u0443\u043F\u043D\u0430?" })] })] })] }));
};
export { ShopAddedWithoutMailings };
