var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as S from './style';
import { Dialog } from '@/shared/ui/Dialog/Dialog';
import { ActivePeriodEnum, SubActionState, UserActionEnum } from './types';
import { Text } from '@/shared/ui/Text/Text';
import { PaymentPlans } from './PaymentPlans/PaymentPlans';
import { useEffect, useState } from 'react';
import { ButtonsAction } from './ButtonsAction/ButtonsAction';
import { DowngradeSub } from './DowngradeSub/DowngradeSub';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { buySubscribe, changeSubscription, getDiffTariffs, getPaymentTariffs, } from '@/entity/PaymentEntity/payment.slice';
import { setPaymentModal, setUpdateSubModal } from '@/entity/ModalsEntity/modals.slice';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
import { Button, Input, Space } from '@/shared/ui';
import { PromocodeValidateEnum } from '@/entity/PromocodesEntity/types';
import { prepareTariffAfterPromo } from '@/features/utils/prepareTariffAfterPromo';
import { usePromocodeActive } from '@/shared/hooks/usePromocodeActive/usePromocodeActive';
import { UpgradeSub } from '@/features/ui/Dialogs/PaymentModal/UpgradeSub/UpgradeSub';
import { useParamsForSubFlow } from '@/shared/hooks/useParamsForSubFlow/useParamsForSubFlow';
import { formatTime } from '@/shared/utils/formatTime';
const PaymentModal = ({ open, isDesktop }) => {
    const dispatch = useTypedDispatch();
    const [step, setStep] = useState(1);
    const [subType, setSubType] = useState(null);
    const [actionUser, setActionUser] = useState(null);
    const [upgradeState, setUpgradeState] = useState(null);
    const [withUpgrade, setWithUpgrade] = useState(false);
    const [activePeriod, setActivePeriod] = useState(ActivePeriodEnum.OneMonth);
    const [valuesTariffs, setValuesTariffs] = useState({
        oneMonth: {
            lite: 0,
            pro: 0,
            liteSale: 0,
            proSale: 0,
        },
        threeMonth: {
            lite: 0,
            pro: 0,
            liteSale: 0,
            proSale: 0,
        },
        oneYear: {
            lite: 0,
            pro: 0,
            liteSale: 0,
            proSale: 0,
        },
    });
    const [activeValuesForPlans, setActiveValuesForPlans] = useState(valuesTariffs.oneMonth);
    const [promocodeValue, setPromocodeValue] = useState('');
    const tariffs = useTypedSelector((state) => state.paymentReducer.tariffs);
    const tariffName = useTypedSelector((state) => state.paymentReducer.userSubscriptionName);
    const currentSubscription = useTypedSelector((state) => state.paymentReducer.userSubscription);
    const featureSubscription = useTypedSelector((state) => { var _a; return (_a = state.paymentReducer.userSubscription) === null || _a === void 0 ? void 0 : _a.futureSub; });
    const handleGetQuery = useParamsForSubFlow();
    const { handleFail, handleSuccess, setHavePromoCode, havePromocode, handleValidatePromocode, } = usePromocodeActive(promocodeValue, PromocodeValidateEnum.goods);
    const handleCheckForBuyOrChange = () => {
        if (tariffName === 'Free') {
            return 'buy';
        }
        else {
            return 'change';
        }
    };
    const handleCloseModal = () => {
        dispatch(setPaymentModal(false));
    };
    const handleBuySub = (sub) => __awaiter(void 0, void 0, void 0, function* () {
        const domainForBuy = `${window.location.origin}/home?pay=true&`;
        if (sub === 'lite') {
            const findLiteTariffs = tariffs.find((el) => el.name === 'Калькулятор Lite' && el.duration === activePeriod);
            const domain = handleGetQuery(findLiteTariffs.id, UserActionEnum.downgrade);
            if (handleCheckForBuyOrChange() === 'buy') {
                if (handleSuccess) {
                    dispatch(buySubscribe({ tariffId: findLiteTariffs === null || findLiteTariffs === void 0 ? void 0 : findLiteTariffs.id, domain: domainForBuy, promocode: promocodeValue }));
                }
                else {
                    dispatch(buySubscribe({ tariffId: findLiteTariffs === null || findLiteTariffs === void 0 ? void 0 : findLiteTariffs.id, domain: domainForBuy }));
                }
            }
            else {
                const changeData = yield dispatch(changeSubscription({ tariffId: findLiteTariffs.id, domain }));
                if (changeData.payload.confirmationUrl) {
                    window.location.replace(changeData.payload.confirmationUrl);
                }
                else {
                    handleCloseModal();
                    dispatch(setUpdateSubModal(true));
                }
            }
        }
        if (sub === 'pro') {
            const findProTariffs = tariffs.find((el) => el.name === 'Калькулятор Pro' && el.duration === activePeriod);
            const domain = handleGetQuery(findProTariffs.id, UserActionEnum.downgrade);
            if (handleCheckForBuyOrChange() === 'buy') {
                if (handleSuccess) {
                    dispatch(buySubscribe({ tariffId: findProTariffs === null || findProTariffs === void 0 ? void 0 : findProTariffs.id, domain: domainForBuy, promocode: promocodeValue }));
                }
                else {
                    dispatch(buySubscribe({ tariffId: findProTariffs === null || findProTariffs === void 0 ? void 0 : findProTariffs.id, domain: domainForBuy }));
                }
            }
            else {
                const changeData = yield dispatch(changeSubscription({ tariffId: findProTariffs.id, domain }));
                if (changeData.payload.confirmationUrl) {
                    window.location.replace(changeData.payload.confirmationUrl);
                }
                else {
                    handleCloseModal();
                    dispatch(setUpdateSubModal(true));
                }
            }
        }
        if (sub === 'free' && handleCheckForBuyOrChange() === 'change') {
            const findFreeTariffs = tariffs.find((el) => el.name === 'Free');
            dispatch(changeSubscription({ tariffId: findFreeTariffs.id }));
        }
    });
    const handleHavePromocode = (value) => {
        sendAmplitudeData('choose_tariff_modal_view_i_have_promocode_btn_clicked');
        setHavePromoCode(value);
    };
    const checkForPro = (sub) => {
        const findMyTariff = tariffs.find((el) => el.id === (currentSubscription === null || currentSubscription === void 0 ? void 0 : currentSubscription.currentSub.tariffId));
        if (+activePeriod > +findMyTariff.duration && findMyTariff.name === 'Калькулятор Pro') {
            setActionUser(UserActionEnum.upgrade);
            setUpgradeState(SubActionState.time);
            setStep(2);
            setSubType(sub);
            return;
        }
        if (findMyTariff.name === 'Калькулятор Lite') {
            setActionUser(UserActionEnum.upgrade);
            setUpgradeState(SubActionState.tariff);
            setStep(2);
            setSubType(sub);
            return;
        }
        if (findMyTariff.name === 'Калькулятор Pro' && +findMyTariff.duration > +activePeriod) {
            handleBuySub(sub);
        }
    };
    const checkForLite = (sub) => {
        const findMyTariff = tariffs.find((el) => el.id === (currentSubscription === null || currentSubscription === void 0 ? void 0 : currentSubscription.currentSub.tariffId));
        if (findMyTariff.name === 'Free') {
            handleBuySub(sub);
            return;
        }
        if (+activePeriod > +findMyTariff.duration && findMyTariff.name === 'Калькулятор Pro') {
            setActionUser(UserActionEnum.downgrade);
            setWithUpgrade(true);
            setStep(2);
            setSubType(sub);
            return;
        }
        setWithUpgrade(false);
        if (+activePeriod > +findMyTariff.duration) {
            setActionUser(UserActionEnum.upgrade);
            setUpgradeState(SubActionState.time);
            setStep(2);
            setSubType(sub);
            return;
        }
        if (+activePeriod === +findMyTariff.duration && findMyTariff.name === 'Калькулятор Pro') {
            setActionUser(UserActionEnum.downgrade);
            setStep(2);
            setSubType(sub);
            return;
        }
        if (+activePeriod < +findMyTariff.duration && findMyTariff.name === 'Калькулятор Pro') {
            setActionUser(UserActionEnum.downgrade);
            setStep(2);
            setSubType(sub);
            return;
        }
        if (+activePeriod < +findMyTariff.duration) {
            handleBuySub(sub);
        }
    };
    const handleClickSub = (sub) => {
        sendAmplitudeData('choose_tariff_modal_view_tariff_item_clicked', {
            tariffPlan: sub,
        });
        if (tariffName === 'Free') {
            handleBuySub(sub);
            return;
        }
        if (sub === 'pro') {
            checkForPro(sub);
            return;
            // handleBuySub(sub);
        }
        if (sub === 'lite') {
            checkForLite(sub);
            return;
        }
        if (sub === 'free') {
            setActionUser(UserActionEnum.downgrade);
            setStep(2);
            setSubType(sub);
        }
    };
    const handleBack = () => {
        sendAmplitudeData('tariff_info_modal_view_closed');
        setStep(1);
        setSubType(null);
        setActionUser(null);
    };
    const setPeriod = (activePeriodValue) => {
        sendAmplitudeData('choose_tariff_modal_view_period_item_clicked', {
            tariffPeriod: activePeriodValue,
        });
        if (activePeriodValue === ActivePeriodEnum.OneMonth) {
            setActiveValuesForPlans(valuesTariffs.oneMonth);
        }
        if (activePeriodValue === ActivePeriodEnum.ThreeMonth) {
            setActiveValuesForPlans(valuesTariffs.threeMonth);
        }
        if (activePeriodValue === ActivePeriodEnum.OneYear) {
            setActiveValuesForPlans(valuesTariffs.oneYear);
        }
        setActivePeriod(activePeriodValue);
    };
    const handleChangePromocodeValue = (event) => {
        setPromocodeValue(event.target.value);
    };
    const handleActivatePromocode = () => __awaiter(void 0, void 0, void 0, function* () {
        const data = yield handleValidatePromocode();
        sendAmplitudeData('choose_tariff_modal_view_apply_promocode_btn_clicked', {
            promocode: promocodeValue,
        });
        if (!data.payload.code) {
            const newTariffs = yield dispatch(getPaymentTariffs({ promocode: promocodeValue.trim() }));
            if (!newTariffs.payload.code) {
                const updatedTariffs = prepareTariffAfterPromo(newTariffs.payload.tariffs, true, tariffs);
                setValuesTariffs(updatedTariffs);
            }
        }
        else {
            setValuesTariffs(prepareTariffAfterPromo(tariffs, false));
        }
    });
    const getHeight = () => {
        if (step === 2) {
            if (!isDesktop) {
                return '93vh';
            }
            return 'fit-content';
        }
    };
    const getIsCurrentTariff = (type) => {
        if (!currentSubscription) {
            return false;
        }
        if (currentSubscription === null || currentSubscription === void 0 ? void 0 : currentSubscription.currentSub.tariffId) {
            const findMyTariff = tariffs.find((el) => el.id === (currentSubscription === null || currentSubscription === void 0 ? void 0 : currentSubscription.currentSub.tariffId));
            if ((findMyTariff === null || findMyTariff === void 0 ? void 0 : findMyTariff.name) === 'Free') {
                return findMyTariff.name.toLowerCase().includes(type);
            }
            if ((findMyTariff === null || findMyTariff === void 0 ? void 0 : findMyTariff.duration) && type) {
                return findMyTariff.duration === activePeriod && findMyTariff.name.toLowerCase().includes(type);
            }
        }
        return false;
    };
    const getIsFeatureTariff = (type) => {
        if (!(featureSubscription === null || featureSubscription === void 0 ? void 0 : featureSubscription.tariffId)) {
            return '';
        }
        if (featureSubscription.tariffId) {
            const findMyTariff = tariffs.find((el) => el.id === (featureSubscription === null || featureSubscription === void 0 ? void 0 : featureSubscription.tariffId));
            if ((findMyTariff === null || findMyTariff === void 0 ? void 0 : findMyTariff.name) === 'Free' && findMyTariff.name.toLowerCase().includes(type)) {
                return `Тариф действует с ${formatTime(currentSubscription === null || currentSubscription === void 0 ? void 0 : currentSubscription.currentSub.finishedAt)}`;
            }
            if ((findMyTariff === null || findMyTariff === void 0 ? void 0 : findMyTariff.duration) && type && findMyTariff.duration === activePeriod && findMyTariff.name.toLowerCase().includes(type)) {
                return `Тариф действует с ${formatTime(currentSubscription === null || currentSubscription === void 0 ? void 0 : currentSubscription.currentSub.finishedAt)}`;
            }
        }
        return '';
    };
    const handleSetActiveValuesForPlans = (newTariffs) => {
        if (activePeriod === ActivePeriodEnum.OneMonth) {
            setActiveValuesForPlans(newTariffs.oneMonth);
        }
        if (activePeriod === ActivePeriodEnum.ThreeMonth) {
            setActiveValuesForPlans(newTariffs.threeMonth);
        }
        if (activePeriod === ActivePeriodEnum.OneYear) {
            setActiveValuesForPlans(newTariffs.oneYear);
        }
    };
    useEffect(() => {
        handleSetActiveValuesForPlans(valuesTariffs);
    }, [valuesTariffs]);
    useEffect(() => {
        dispatch(getDiffTariffs({ promocode: handleSuccess ? promocodeValue : '' }));
    }, [handleSuccess]);
    useEffect(() => {
        if (tariffs.length !== 0) {
            const newTariffs = prepareTariffAfterPromo(tariffs, false);
            setValuesTariffs(newTariffs);
            handleSetActiveValuesForPlans(newTariffs);
        }
    }, [tariffs]);
    return (_jsxs(Dialog, { css: {
            maxWidth: '100% !important',
            top: isDesktop ? '49%' : '53%',
            maxHeight: isDesktop ? '91vh' : '93vh',
            padding: isDesktop ? '16px' : '0',
            width: isDesktop ? '' : '100vw !important',
            height: getHeight(),
            overflowX: isDesktop ? '' : 'hidden',
        }, open: open, outside: true, onOpenChange: handleCloseModal, closeIcon: true, children: [_jsxs(S.PaymentModalComponent, { children: [_jsx(Text, { css: { marginBottom: isDesktop ? '' : '22px', padding: isDesktop ? '' : '16px 0 0 16px' }, type: "preTitle", children: "\u0422\u0430\u0440\u0438\u0444\u044B" }), (step === 1 && !isDesktop) &&
                        _jsx(_Fragment, { children: !havePromocode ?
                                _jsx(Space, { css: { marginBottom: '12px' }, content: "center", children: _jsx(Text, { onClick: () => handleHavePromocode(true), type: "select", children: "\u0423 \u043C\u0435\u043D\u044F \u0435\u0441\u0442\u044C \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434" }) })
                                :
                                    _jsx(Space, { css: { marginBottom: '12px' }, align: "center", size: "default", direction: "vertical", children: _jsxs(Space, { size: "small", direction: "vertical", css: { width: 'fit-content' }, children: [_jsxs(Space, { align: "center", size: "default", content: "flexEnd", children: [_jsx(Input, { cssContainer: { width: '200px' }, typeInput: "default", css: { height: '34px' }, value: promocodeValue, onChange: handleChangePromocodeValue }), _jsx(Button, { type: "default", onClick: handleActivatePromocode, children: "\u041F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C \u043A\u043E\u0434" })] }), _jsxs(Space, { align: "center", children: [handleSuccess &&
                                                            _jsx(Text, { css: { color: '$acceptButton' }, children: "\u041F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u043F\u0440\u0438\u043C\u0435\u043D\u0435\u043D" }), handleFail &&
                                                            _jsx(Text, { css: { color: '$error' }, children: "\u041F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u043D\u0435\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043B\u0435\u043D" })] })] }) }) }), step === 1 &&
                        _jsxs(_Fragment, { children: [_jsx(ButtonsAction, { activePeriod: activePeriod, setActivePeriod: setPeriod }), _jsx(PaymentPlans, { getIsCurrentTariff: getIsCurrentTariff, getIsFeatureTariff: getIsFeatureTariff, isDesktop: isDesktop, tariffName: tariffName, handleClickSub: handleClickSub, activeValuesForPlans: activeValuesForPlans })] }), step === 2 &&
                        _jsx(_Fragment, { children: actionUser === UserActionEnum.downgrade ?
                                _jsx(DowngradeSub, { withUpgrade: withUpgrade, handleCloseModal: handleCloseModal, getIsCurrentTariff: getIsCurrentTariff, getIsFeatureTariff: getIsFeatureTariff, isDesktop: isDesktop, handleBuySub: handleBuySub, activePeriod: activePeriod, activeValuesForPlans: activeValuesForPlans, handleBack: handleBack, subType: subType })
                                :
                                    _jsx(UpgradeSub, { getIsCurrentTariff: getIsCurrentTariff, getIsFeatureTariff: getIsFeatureTariff, subType: subType, activePeriod: activePeriod, upgradeState: upgradeState, activeValuesForPlans: activeValuesForPlans, isDesktop: isDesktop, handleBack: handleBack }) })] }), (step === 1 && isDesktop) &&
                _jsx(_Fragment, { children: !havePromocode ?
                        _jsx(Space, { css: { margin: '0 20px 12px 20px' }, content: "flexStart", children: _jsx(Text, { onClick: () => handleHavePromocode(true), type: "select", children: "\u0423 \u043C\u0435\u043D\u044F \u0435\u0441\u0442\u044C \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434" }) })
                        :
                            _jsx(Space, { css: { margin: '0 20px 12px 20px' }, align: "flexEnd", size: "default", direction: "vertical", children: _jsxs(Space, { size: "small", direction: "vertical", css: { width: 'fit-content' }, children: [_jsxs(Space, { align: "center", size: "default", content: "flexEnd", children: [_jsx(Text, { children: "\u041F\u0440\u043E\u043C\u043E\u043A\u043E\u0434" }), _jsx(Input, { cssContainer: { width: '200px' }, typeInput: "default", css: { height: '34px' }, value: promocodeValue, onChange: handleChangePromocodeValue }), _jsx(Button, { type: "default", onClick: handleActivatePromocode, children: "\u041F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C \u043A\u043E\u0434" })] }), _jsxs(Space, { align: "center", children: [handleSuccess &&
                                                    _jsx(Text, { css: { color: '$acceptButton' }, children: "\u041F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u043F\u0440\u0438\u043C\u0435\u043D\u0435\u043D" }), handleFail &&
                                                    _jsx(Text, { css: { color: '$error' }, children: "\u041F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u043D\u0435\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043B\u0435\u043D" })] })] }) }) })] }));
};
export { PaymentModal };
