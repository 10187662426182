var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useState } from 'react';
import { ReactComponent as ArrowTop } from '@/shared/assets/icons/ArrowTop.svg';
import { ReactComponent as ArrowBottom } from '@/shared/assets/icons/ArrowBottom.svg';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { ReactComponent as ArrowSelect } from '@/shared/assets/icons/arrowSelect.svg';
import * as S from './style';
import { Icon } from '@/shared/ui';
import './style.css';
const Select = forwardRef((_a, forwardedRef) => {
    var { children, defaultValue, onChange, placeholder, value, cssContent, type, css, testId, withScroll = false, disabled, error, logistic } = _a, props = __rest(_a, ["children", "defaultValue", "onChange", "placeholder", "value", "cssContent", "type", "css", "testId", "withScroll", "disabled", "error", "logistic"]);
    const [open, setOpen] = useState(false);
    const cssScroll = {
        height: cssContent === null || cssContent === void 0 ? void 0 : cssContent.height,
        maxHeight: cssContent === null || cssContent === void 0 ? void 0 : cssContent.maxHeight,
    };
    const handleChange = (valueState) => {
        if (disabled) {
            return null;
        }
        setTimeout(() => setOpen(valueState));
    };
    return (_jsxs(S.Select, Object.assign({ open: open, onOpenChange: handleChange, value: value }, props, { children: [_jsxs(S.StyledTrigger, { "data-testid": testId, css: css, ref: forwardedRef, error: error && true, type: type, logistic: logistic, disabled: disabled, children: [_jsx(S.StyledValue, { asChild: true, defaultValue: defaultValue, placeholder: placeholder || defaultValue, children: _jsx(S.StyledSpaceValue, { children: !value ? defaultValue : value }) }), _jsx(S.PrimitiveIcon, { asChild: true, children: _jsx("div", { style: { width: '8px', display: 'flex', justifyContent: 'flex-end' }, children: _jsx(Icon, { css: { width: '8px', height: '4px', margin: '0' }, IconItem: ArrowSelect }) }) })] }), _jsx(S.StyledPortal, { children: _jsxs(S.StyledContent, { onMouseDown: (e) => e.stopPropagation(), side: "bottom", sideOffset: 3, avoidCollisions: true, css: cssContent, position: "popper", children: [!withScroll &&
                            _jsx(S.StyledUpButton, { children: _jsx(ArrowTop, {}) }), !withScroll ?
                            _jsx(S.StyledViewport, { children: children })
                            :
                                _jsxs(ScrollArea.Root, { style: cssScroll, className: "ScrollAreaRoot", type: "auto", children: [_jsx(S.StyledViewport, { style: { overflowY: undefined }, asChild: true, children: _jsx(ScrollArea.Viewport, { className: "ScrollAreaViewport", children: children }) }), _jsx(ScrollArea.Scrollbar, { className: "ScrollAreaScrollbar", orientation: "vertical", children: _jsx(ScrollArea.Thumb, { className: "ScrollAreaThumb" }) })] }), !withScroll &&
                            _jsx(S.StyledDownButton, { children: _jsx(ArrowBottom, {}) })] }) })] })));
});
const SelectItem = forwardRef((_a, forwardedRef) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (_jsx(S.StyledItem, Object.assign({}, props, { ref: forwardedRef, children: _jsx(S.StyledItemText, { children: children }) })));
});
const SelectNamespace = Object.assign(Select, { Item: SelectItem });
export { SelectNamespace as Select };
