import { styled } from '@/app/styles/mainStyles';
import { Text } from '@/shared/ui/Text/style';
export const BackgroundHintText = styled(Text, {
    variants: {
        hintError: {
            true: {
                fontSize: '16px',
                fontWeight: '400',
                color: '$error',
                fontFamily: '$nunito',
            },
        },
    },
});
