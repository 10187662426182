export var ActivePeriodEnum;
(function (ActivePeriodEnum) {
    ActivePeriodEnum["OneMonth"] = "30";
    ActivePeriodEnum["ThreeMonth"] = "90";
    ActivePeriodEnum["OneYear"] = "360";
})(ActivePeriodEnum || (ActivePeriodEnum = {}));
export var UserActionEnum;
(function (UserActionEnum) {
    UserActionEnum["downgrade"] = "downgrade";
    UserActionEnum["upgrade"] = "upgrade";
})(UserActionEnum || (UserActionEnum = {}));
export var SubActionState;
(function (SubActionState) {
    SubActionState["time"] = "time";
    SubActionState["tariff"] = "tariff";
})(SubActionState || (SubActionState = {}));
