import * as yup from 'yup';
export const registerSchema = yup.object({
    email: yup
        .string()
        .email({ text: 'required' })
        .required({ text: 'required' }),
    // fullName: yup
    //   .string()
    //   .min(2, { text: 'required' })
    //   .trim()
    //   .required({ text: 'required' }),
    password: yup
        .string()
        .min(8, { text: 'Пароль не может быть меньше 8 символов' })
        .trim()
        .required({ text: 'required' }),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password')], { text: 'Пароли не совпадают' })
        .required({ text: 'required' }),
});
