import { jsx as _jsx } from "react/jsx-runtime";
import { LazyCalculateListPage, LazyCalculatePage, LazyHomeMailingPage, LazyHomePage, LazyListSavedProducts, LazyMailingPage, LazyMailingsListPage, LazyMainPage, LazyMyShopsPage, LazyNewCalculatePage, LazyNewMailingPage, LazyProductPage, LazyProfilePage, LazyPromocodeSettingsPage, LoginModule, NotFoundPage, SignUp, } from '@/pages';
import { UserKindEnum } from '@/app/interfaces/Mailings/types';
export var AppRoutes;
(function (AppRoutes) {
    AppRoutes["PROFILE"] = "profile";
    AppRoutes["HOME"] = "home";
    AppRoutes["LOGIN"] = "login";
    AppRoutes["SIGNUP"] = "signUp";
    AppRoutes["MAIN"] = "main";
    AppRoutes["MYMAILINGS"] = "myMailings";
    AppRoutes["NEWMAILING"] = "newMailing";
    AppRoutes["MYSHOPS"] = "myShops";
    AppRoutes["HOMEMAILINGS"] = "homeMailings";
    AppRoutes["VIEWMAILING"] = "viewMailing";
    AppRoutes["SAVEDPRODUCTS"] = "savedProducts";
    AppRoutes["CALCULATELIST"] = "calculateList";
    AppRoutes["VIEWCALCULATE"] = "viewCalculate";
    AppRoutes["VIEWPRODUCT"] = "viewProduct";
    AppRoutes["PRODUCTWITHOUTSAVE"] = "productWithOutSave";
    AppRoutes["CALCULATENEWITEM"] = "calculateNewItem";
    AppRoutes["NOTFOUND"] = "notFound";
    AppRoutes["PROMOCODES"] = "promoCodes";
})(AppRoutes || (AppRoutes = {}));
export const RoutePath = {
    [AppRoutes.MAIN]: '/main',
    [AppRoutes.PROFILE]: '/profile',
    [AppRoutes.HOME]: '/home',
    [AppRoutes.LOGIN]: '/login',
    [AppRoutes.SIGNUP]: '/signUp',
    [AppRoutes.MYMAILINGS]: '/myMailings',
    [AppRoutes.NEWMAILING]: '/newMailing',
    [AppRoutes.MYSHOPS]: '/myShops',
    [AppRoutes.HOMEMAILINGS]: '/homeMailings',
    [AppRoutes.SAVEDPRODUCTS]: '/products',
    [AppRoutes.CALCULATELIST]: '/calculations',
    [AppRoutes.VIEWPRODUCT]: '/products/:id',
    [AppRoutes.VIEWMAILING]: '/mailing/:id',
    [AppRoutes.VIEWCALCULATE]: '/calculations/:id',
    [AppRoutes.PRODUCTWITHOUTSAVE]: '/products/0000-0000-0000-0000-0000',
    [AppRoutes.CALCULATENEWITEM]: '/calculateNewItem',
    [AppRoutes.PROMOCODES]: '/promoCodes',
    [AppRoutes.NOTFOUND]: '*',
};
export const routeConfig = {
    [AppRoutes.HOME]: {
        id: 1,
        path: RoutePath.home,
        element: _jsx(LazyHomePage, {}),
        isProtect: true,
    },
    [AppRoutes.PROFILE]: {
        id: 2,
        path: RoutePath.profile,
        element: _jsx(LazyProfilePage, {}),
        isProtect: true,
    },
    [AppRoutes.MAIN]: {
        id: 3,
        path: RoutePath.main,
        isProtect: true,
        element: _jsx(LazyMainPage, {}),
    },
    [AppRoutes.LOGIN]: {
        id: 4,
        path: RoutePath.login,
        element: _jsx(LoginModule, {}),
    },
    [AppRoutes.SIGNUP]: {
        id: 5,
        path: RoutePath.signUp,
        element: _jsx(SignUp, {}),
    },
    [AppRoutes.MYMAILINGS]: {
        id: 6,
        path: RoutePath.myMailings,
        element: _jsx(LazyMailingsListPage, {}),
        isProtect: true,
        permissions: {
            type: 'Product',
            value: 'USER_ACTIVE_PRODUCT_MAILING',
        },
    },
    [AppRoutes.NEWMAILING]: {
        id: 7,
        path: RoutePath.newMailing,
        element: _jsx(LazyNewMailingPage, {}),
        isProtect: true,
        permissions: {
            type: 'Product',
            value: 'USER_ACTIVE_PRODUCT_MAILING',
        },
    },
    [AppRoutes.MYSHOPS]: {
        id: 8,
        path: RoutePath.myShops,
        isProtect: true,
        element: _jsx(LazyMyShopsPage, {}),
        permissions: {
            type: 'Product',
            value: 'USER_ACTIVE_PRODUCT_MAILING',
        },
    },
    [AppRoutes.HOMEMAILINGS]: {
        id: 8,
        path: RoutePath.homeMailings,
        isProtect: true,
        element: _jsx(LazyHomeMailingPage, {}),
    },
    [AppRoutes.SAVEDPRODUCTS]: {
        id: 9,
        path: RoutePath.savedProducts,
        isProtect: true,
        element: _jsx(LazyListSavedProducts, {}),
    },
    [AppRoutes.CALCULATELIST]: {
        id: 10,
        path: RoutePath.calculateList,
        isProtect: true,
        element: _jsx(LazyCalculateListPage, {}),
    },
    [AppRoutes.VIEWPRODUCT]: {
        id: 11,
        path: RoutePath.viewProduct,
        isProtect: true,
        element: _jsx(LazyProductPage, {}),
    },
    [AppRoutes.VIEWMAILING]: {
        id: 12,
        path: RoutePath.viewMailing,
        isProtect: true,
        element: _jsx(LazyMailingPage, {}),
        permissions: {
            type: 'Product',
            value: 'USER_ACTIVE_PRODUCT_MAILING',
        },
    },
    [AppRoutes.VIEWCALCULATE]: {
        id: 13,
        path: RoutePath.viewCalculate,
        isProtect: true,
        element: _jsx(LazyCalculatePage, {}),
    },
    [AppRoutes.PRODUCTWITHOUTSAVE]: {
        id: 14,
        path: RoutePath.productWithOutSave,
        isProtect: true,
        element: _jsx(LazyProductPage, {}),
    },
    [AppRoutes.CALCULATENEWITEM]: {
        id: 15,
        path: RoutePath.calculateNewItem,
        isProtect: true,
        element: _jsx(LazyNewCalculatePage, {}),
    },
    [AppRoutes.PROMOCODES]: {
        id: 16,
        path: RoutePath.promoCodes,
        isProtect: true,
        element: _jsx(LazyPromocodeSettingsPage, {}),
        permissions: {
            type: 'Role',
            value: UserKindEnum.USER_KIND_ADMIN,
        },
    },
    [AppRoutes.NOTFOUND]: {
        id: 17,
        path: RoutePath.notFound,
        isProtect: false,
        element: _jsx(NotFoundPage, {}),
    },
};
export const routeGenerator = {
    mailing: (id) => `${RoutePath.viewMailing.replace(':id', id)}`,
    calculate: (id) => `${RoutePath.viewCalculate.replace(':id', id)}`,
    product: (id) => `${RoutePath.viewProduct.replace(':id', id)}`,
};
