import * as Switch from '@radix-ui/react-switch';
import { styled } from '@/app/styles/mainStyles';
export const SwitchRoot = styled(Switch.Root, {
    all: 'unset',
    width: 32,
    height: 20,
    backgroundColor: '$border',
    borderRadius: '9999px',
    position: 'relative',
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    '&[data-state="checked"]': {
        backgroundColor: '$activeBackground',
    },
    variants: {
        color: {
            green: {
                backgroundColor: '$border',
                '&[data-state="checked"]': {
                    backgroundColor: '$activeSwitchGreen',
                },
            },
        },
    },
});
export const SwitchThumb = styled(Switch.Thumb, {
    display: 'block',
    width: 16,
    height: 16,
    backgroundColor: 'white',
    borderRadius: '9999px',
    transition: 'transform 100ms',
    transform: 'translateX(2px)',
    willChange: 'transform',
    '&[data-state="checked"]': {
        transform: 'translateX(14px)',
    },
});
