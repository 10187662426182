import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import { ReactComponent as ArrowLeft } from '@/shared/assets/icons/arrowLeft.svg';
import { Icon } from '@/shared/ui';
import { useHistory } from 'react-router';
const BreadCrumbs = ({ goBackFunc, css }) => {
    const history = useHistory();
    const onGoBack = () => {
        if (goBackFunc) {
            goBackFunc();
        }
        else {
            history.goBack();
        }
    };
    return (_jsx(S.BreadCrumbsWrapper, { css: css, children: _jsxs(S.BreadCrumbsContainer, { onClick: onGoBack, children: [_jsx(Icon, { fill: "sideBar", sideBarHover: "sideBar", IconItem: ArrowLeft, css: { height: '10px', width: '26px' } }), _jsx(S.BreadCrumbsTextBack, { children: "\u0412\u0435\u0440\u043D\u0443\u0442\u044C\u0441\u044F \u043D\u0430\u0437\u0430\u0434" })] }) }));
};
export default BreadCrumbs;
