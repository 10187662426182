import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router';
import { SideBarContainer } from '@/app/styles/mainStyles';
import { Navigation } from '@/widgets/ui/Navigation/Navigation';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import ReactJoyride, { EVENTS } from 'react-joyride';
import { setOnboardingTarget } from '@/entity/OnboardingEntity/onboarding.slice';
import { TooltipForOnboarding } from '@/features/ui/TooltipForOnboarding/TooltipForOnboarding';
import { sidebarStepsDesktop } from '@/entity/OnboardingEntity/Steps/SidebarStep/SidebarDesktop';
const cookies = new Cookies();
const SideBar = ({ isTransition, isOpened, setIsOpened, userKind, isDesktop, }) => {
    const dispatch = useTypedDispatch();
    const target = useTypedSelector((state) => state.onboardingReducer.onboardingTarget);
    const run = useTypedSelector((state) => state.onboardingReducer.onboarding);
    let runOnboarding = target === 'sidebar' && run;
    const handleJoyrideCallback = (data) => {
        const { type } = data;
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            setTimeout(() => {
                dispatch(setOnboardingTarget('main'));
            }, 500);
        }
    };
    if (!cookies.get('token')) {
        return null;
    }
    if (userKind === 'USER_KIND_UNVERIFIED') {
        return null;
    }
    return (_jsx(_Fragment, { children: _jsxs(SideBarContainer, { id: "sideBarContainer", isClosed: !isOpened, isTransition: isTransition, children: [_jsx(Navigation, { isDesktop: isDesktop, setIsOpened: setIsOpened, isOpened: isOpened }), _jsx(ReactJoyride, { spotlightPadding: 0, continuous: true, tooltipComponent: TooltipForOnboarding, callback: handleJoyrideCallback, run: runOnboarding, steps: sidebarStepsDesktop.steps })] }) }));
};
export default withRouter(SideBar);
