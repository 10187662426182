import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as S from './style';
import { useEffect, useState } from 'react';
import { Space } from '@/shared/ui';
const ProgressBar = ({ progressValue, withFake = false }) => {
    const [progress, setProgress] = useState(+progressValue);
    const [fakeProgress, setFakeProgress] = useState(0);
    useEffect(() => {
        if (progressValue) {
            setProgress(+progressValue);
        }
    }, [progressValue]);
    useEffect(() => {
        const timer = setTimeout(() => setFakeProgress(100), 1000);
        return () => clearTimeout(timer);
    }, []);
    return (_jsx(_Fragment, { children: withFake ?
            _jsxs(Space, { css: { width: '100%' }, children: [_jsx(S.ProgressLeftFakeRoot, { children: _jsx(S.ProgressFakeIndicator, { style: { transform: `translateX(-${100 - fakeProgress}%)` } }) }), _jsx(S.ProgressRightFakeRoot, { children: _jsx(S.ProgressFakeIndicator, { style: { transform: `translateX(-${100 - progress}%)` } }) })] })
            :
                _jsx(S.ProgressRoot, { value: progress, children: _jsx(S.ProgressIndicator, { style: { transform: `translateX(-${100 - progress}%)` } }) }) }));
};
export { ProgressBar };
