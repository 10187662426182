function instanceOfWildberries(object) {
    return 'wildBerries' in object;
}
export const formatStatsForGraph = (product) => {
    var _a, _b;
    const arr = [];
    if (product === null || product === void 0 ? void 0 : product.length) {
        if (instanceOfWildberries(product[0])) {
            if (product === null || product === void 0 ? void 0 : product.length) {
                for (let i = 0; i < (product === null || product === void 0 ? void 0 : product.length); i++) {
                    arr.push((_a = product[i]) === null || _a === void 0 ? void 0 : _a.wildBerries);
                }
            }
        }
        else {
            if (product === null || product === void 0 ? void 0 : product.length) {
                for (let i = 0; i < (product === null || product === void 0 ? void 0 : product.length); i++) {
                    arr.push((_b = product[i]) === null || _b === void 0 ? void 0 : _b.ozon);
                }
            }
        }
    }
    return arr;
};
