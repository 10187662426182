var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from '@/shared/ui/Dialog/Dialog';
import { Text } from '@/shared/ui/Text/Text';
import { Space } from '@/shared/ui/Space/Space';
import { Button } from '@/shared/ui/Button/Button';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { setLiteSubLimit, setPaymentModal } from '@/entity/ModalsEntity/modals.slice';
import { removeAllUserProducts } from '@/entity/ListSavedProductsEntity/savedProducts.slice';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
const LiteSubLimit = ({ open }) => {
    const dispatch = useTypedDispatch();
    const handleRemoveAllProducts = () => __awaiter(void 0, void 0, void 0, function* () {
        sendAmplitudeData('downgrate_to_free_modal_view_delete_btn_clicked');
        const resp = yield dispatch(removeAllUserProducts());
        if (resp.payload) {
            dispatch(setLiteSubLimit(false));
        }
    });
    const handleOpenPaymentModal = () => {
        sendAmplitudeData('downgrate_to_free_modal_view_keep_btn_clicked');
        dispatch(setPaymentModal(true));
    };
    return (_jsxs(Dialog, { open: open, children: [_jsx(Text, { css: { marginBottom: '24px' }, type: "preTitle", children: "\u0423\u0434\u0430\u043B\u0435\u043D\u0438\u0435 \u0442\u043E\u0432\u0430\u0440\u043E\u0432" }), _jsx(Text, { css: { marginBottom: '46px' }, type: "default", children: "\u0421 \u0441\u0435\u0433\u043E\u0434\u043D\u044F\u0448\u043D\u0435\u0433\u043E \u0434\u043D\u044F \u0432\u044B \u043F\u0435\u0440\u0435\u0448\u043B\u0438 \u043D\u0430 \u0442\u0430\u0440\u0438\u0444 Free. \u0410\u043D\u0430\u043B\u0438\u0437 \u0438 \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u0438\u0435 \u0442\u043E\u0432\u0430\u0440\u043E\u0432 \u043D\u0435\u0434\u043E\u0441\u0442\u0443\u043F\u043D\u044B \u0432 \u0442\u0430\u0440\u0438\u0444\u0435 Free. \u0423\u0434\u0430\u043B\u0438\u0442\u044C \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u043D\u044B\u0435 \u0442\u043E\u0432\u0430\u0440\u044B?" }), _jsxs(Space, { content: "flexEnd", children: [_jsx(Button, { onClick: handleRemoveAllProducts, css: { borderRadius: '28px', marginRight: '12px' }, type: "delete", children: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u0442\u043E\u0432\u0430\u0440\u044B" }), _jsx(Button, { onClick: handleOpenPaymentModal, type: "primary", children: "\u041E\u0441\u0442\u0430\u0432\u0438\u0442\u044C \u0442\u043E\u0432\u0430\u0440\u044B" })] })] }));
};
export { LiteSubLimit };
