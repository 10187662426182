import { styled } from '@/app/styles/mainStyles';
import { Text } from '@/shared/ui/Text/style';
export const NewPriceContainer = styled('div', {
    display: 'flex',
    padding: '18px 23px',
    borderRadius: '8px',
    border: '1px solid $border',
    justifyContent: 'space-between',
    background: '$gray',
    '@mobile': {
        flexDirection: 'column',
    },
});
export const DescWithPriceContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
});
export const DescPrice = styled(Text, {
    fontSize: '32px',
});
