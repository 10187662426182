export var UserKindEnum;
(function (UserKindEnum) {
    UserKindEnum["USER_KIND_UNVERIFIED"] = "USER_KIND_UNVERIFIED";
    UserKindEnum["USER_KIND_NONE"] = "USER_KIND_NONE";
    UserKindEnum["USER_KIND_DEFAULT"] = "USER_KIND_DEFAULT";
    UserKindEnum["USER_KIND_SUPPORT"] = "USER_KIND_SUPPORT";
    UserKindEnum["USER_KIND_ADMIN"] = "USER_KIND_ADMIN";
})(UserKindEnum || (UserKindEnum = {}));
export var MailingStatusEnum;
(function (MailingStatusEnum) {
    MailingStatusEnum["Done"] = "MAILING_STATUS_DONE";
    MailingStatusEnum["InProgress"] = "MAILING_STATUS_IN_PROGRESS";
    MailingStatusEnum["Planned"] = "MAILING_STATUS_PLANNED";
    MailingStatusEnum["Draft"] = "MAILING_STATUS_DRAFT";
    MailingStatusEnum["Cancel"] = "MAILING_STATUS_CANCELED";
})(MailingStatusEnum || (MailingStatusEnum = {}));
export var ProductTypeEnum;
(function (ProductTypeEnum) {
    ProductTypeEnum["USER_ACTIVE_PRODUCT_CALCULATOR"] = "USER_ACTIVE_PRODUCT_CALCULATOR";
    ProductTypeEnum["USER_ACTIVE_PRODUCT_MAILING"] = "USER_ACTIVE_PRODUCT_MAILING";
})(ProductTypeEnum || (ProductTypeEnum = {}));
