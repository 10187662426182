var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Cookies from 'universal-cookie';
import { patchReqUser } from '@/shared/api/user';
import { getMailing } from '@/shared/api/mailing';
const cookies = new Cookies();
export function changeUserPassword(data) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        const token = cookies.get('token');
        try {
            const resp = yield patchReqUser('/password', data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return resp;
        }
        catch (e) {
            // @ts-ignore
            return (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data;
        }
    });
}
export function getMailingRequest(id) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield getMailing(`request?id=${id}`);
            return response.data;
        }
        catch (e) {
            console.log(e);
        }
    });
}
