import { jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from '@/shared/ui';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { formatTime } from '@/shared/utils/formatTime';
import { useDiffTariff } from '@/shared/hooks/useDiffTariff/useDiffTariff';
const NewTariffText = ({ subType, activePeriod }) => {
    const currentTariff = useTypedSelector((state) => { var _a; return (_a = state.paymentReducer.userSubscription) === null || _a === void 0 ? void 0 : _a.currentSub; });
    const { getNextSubPrice, getDiffPrice } = useDiffTariff(subType, activePeriod);
    return (_jsxs(Text, { type: "default", children: ["\u0412\u0430\u0448 \u0442\u0435\u043A\u0443\u0449\u0438\u0439 \u0442\u0430\u0440\u0438\u0444 \u0434\u0435\u0439\u0441\u0442\u0432\u0443\u0435\u0442 \u0434\u043E ", formatTime(currentTariff === null || currentTariff === void 0 ? void 0 : currentTariff.finishedAt), ". \u0414\u043B\u044F \u043F\u0435\u0440\u0435\u0445\u043E\u0434\u0430 \u043D\u0430 \u043D\u043E\u0432\u044B\u0439 \u0442\u0430\u0440\u0438\u0444 \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u0434\u043E\u043F\u043B\u0430\u0442\u0438\u0442\u044C ", getDiffPrice(), "\u20BD. \u041D\u043E\u0432\u044B\u0439 \u0442\u0430\u0440\u0438\u0444 \u0432\u0441\u0442\u0443\u043F\u0438\u0442 \u0432 \u0441\u0438\u043B\u0443 \u0441\u0440\u0430\u0437\u0443 \u043F\u043E\u0441\u043B\u0435 \u043E\u043F\u043B\u0430\u0442\u044B. \u0421\u043B\u0435\u0434\u0443\u044E\u0449\u0438\u0435 \u0441\u043F\u0438\u0441\u0430\u043D\u0438\u0435 \u0431\u0443\u0434\u0435\u0442 \u0441\u043E\u0433\u043B\u0430\u0441\u043D\u043E \u0441\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u0438 \u0442\u0430\u0440\u0438\u0444\u0430: ", getNextSubPrice(), "\u20BD."] }));
};
export { NewTariffText };
