import { styled } from '@/app/styles/mainStyles';
export const BackgroundCard = styled('div', {
    background: '#F7F7F7',
    borderRadius: '8px',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    variants: {
        chose: {
            true: {
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        active: {
            true: {
                backgroundColor: '$active',
                color: 'white',
            },
        },
        disable: {
            true: {
                cursor: 'not-allowed',
                backgroundColor: '$disableBackground',
                color: '$disableColor',
            },
        },
    },
});
