import { styled } from '@/app/styles/mainStyles';
export const LoginContainer = styled('div', {
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
    justifyContent: 'center',
});
export const Img = styled('img', {
    height: '36px',
});
export const ImgContainer = styled('div', {
    display: 'flex',
    '@desktop': {
        width: '40%',
        height: '100%',
    },
    '@mobile': {
        display: 'none',
    },
});
export const FormContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    '@desktop': {
        width: '60%',
    },
    '@mobile': {
        width: '100%',
    },
});
export const ResetPassword = styled('div', {
    '&:hover': {
        cursor: 'pointer',
    },
});
