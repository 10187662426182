var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { IMaskMixin } from 'react-imask';
import { Input } from '@/shared/ui';
const PhoneMaskedInput = IMaskMixin((_a) => {
    var { inputRef } = _a, props = __rest(_a, ["inputRef"]);
    return (
    // @ts-ignore
    _jsx(Input, Object.assign({}, props, { ref: inputRef })));
});
export { PhoneMaskedInput };
