import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from '@/shared/ui/Dialog/Dialog';
import * as S from './style';
import { Text } from '@/shared/ui/Text/Text';
import { Button } from '@/shared/ui/Button/Button';
import { Space } from '@/shared/ui/Space/Space';
const CompleteOnboardingModal = ({ open, onClose }) => {
    return (_jsx(Dialog, { open: open, onOpenChange: onClose, closeIcon: true, children: _jsxs(S.CompleteOnboardingContainer, { children: [_jsx(Text, { css: { marginBottom: '24px' }, type: "title", children: "\u0420\u0430\u0434\u044B \u043F\u043E\u0437\u043D\u0430\u043A\u043E\u043C\u0438\u0442\u044C\u0441\u044F" }), _jsx(Text, { css: { marginBottom: '24px' }, type: "default", children: "\u0412\u044B \u043F\u0440\u043E\u0448\u043B\u0438 \u043A\u043E\u0440\u043E\u0442\u043A\u043E\u0435 \u043E\u0431\u0443\u0447\u0435\u043D\u0438\u0435 \u043F\u043E \u043E\u0441\u043D\u043E\u0432\u043D\u044B\u043C \u0440\u0430\u0437\u0434\u0435\u043B\u0430\u043C \u043A\u0430\u043B\u044C\u043A\u0443\u043B\u044F\u0442\u043E\u0440\u0430 Harvester! \u0422\u0435\u043F\u0435\u0440\u044C \u0432\u044B \u0433\u043E\u0442\u043E\u0432\u044B \u0441\u043E\u0437\u0434\u0430\u0432\u0430\u0442\u044C \u0440\u0430\u0441\u0447\u0435\u0442\u044B \u0438 \u0438\u0437\u0443\u0447\u0430\u0442\u044C \u043A\u043E\u043D\u043A\u0443\u0440\u0435\u043D\u0442\u043E\u0432. \u0415\u0441\u043B\u0438 \u0432\u043E\u0437\u043D\u0438\u043A\u043D\u0443\u0442 \u0432\u043E\u043F\u0440\u043E\u0441\u044B, \u043C\u044B \u0432\u0441\u0435\u0433\u0434\u0430 \u0433\u043E\u0442\u043E\u0432\u044B \u043F\u043E\u043C\u043E\u0447\u044C!" }), _jsx(Space, { content: "center", children: _jsx(Button, { onClick: onClose, type: "primary", children: "\u041D\u0430\u0447\u0430\u0442\u044C \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F" }) })] }) }));
};
export { CompleteOnboardingModal };
