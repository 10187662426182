var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
import { getProduct, postProduct } from '@/shared/api/calculator';
import { MarketplaceEnum, } from '@/widgets/ui/NewCalculate/ui/CustomItem/types';
import { displayError } from '@/shared/utils/displayError/displayError';
const initialState = {
    wbFbwList: [],
    wbCommisionCategory: [],
    wbCommisionList: [],
    ozonCategoryList: [],
    ozonFbsList: [],
    allCalculatedLogistic: {},
    previewCalculate: null,
    logisticStatus: BASE_FETCH_STATUS,
    selectCalculated: {},
    selectOzonLogistic: {},
    selectWbLogistic: {},
};
const getWbFbwList = createAsyncThunk('LogisticSlice/getWbFbwList', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getProduct('wb/fbw?limit=50&offset=0');
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getOzonFbsList = createAsyncThunk('LogisticSlice/getOzonFbsList', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getProduct('ozon/fbs?limit=10&offset=0');
        return resp.data.ozonFbsStorageCommission;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getOzonCommissionList = createAsyncThunk('LogisticSlice/getOzonCommissionList', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getProduct('ozon/commission?limit=500&offset=0');
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getWbComissionList = createAsyncThunk('LogisticSlice/getWbComissionList', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getProduct(`wb/commission?limit=500&offset=0&category=${payload}`);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const previewCalculate = createAsyncThunk('LogisticSlice/previewCalculate', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    let updatePayload = Object.assign(Object.assign({}, payload.item), { save: false });
    try {
        const resp = yield postProduct('/calculating', updatePayload);
        return { calculating: resp.data.calculating, calculateId: payload.calculateId };
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const recalculateItem = createAsyncThunk('LogisticSlice/recalculateItem', (payload) => __awaiter(void 0, void 0, void 0, function* () {
    let updatePayload = Object.assign(Object.assign({}, payload.item), { save: false });
    try {
        const resp = yield postProduct('/calculating', updatePayload);
        const updatedValue = payload.item.items[0];
        return Object.assign(Object.assign({}, updatedValue), { deliveryPrice: resp.data.calculating.items[0].mpExpenses, accept: true });
    }
    catch (error) {
        const updatedValue = payload.item.items[0];
        return Object.assign(Object.assign({}, updatedValue), { accept: false });
    }
}));
const getWbComissionCategory = createAsyncThunk('LogisticSlice/getWbComissionCategory', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getProduct('wb/commission/category?limit=500&offset=0');
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const logisticSlice = createSlice({
    name: 'LogisticSlice',
    initialState,
    reducers: {
        addNewCalculatedLogistic: (state, { payload }) => {
            state.allCalculatedLogistic = payload.item;
            if (payload.marketplace === MarketplaceEnum.Ozon) {
                state.selectOzonLogistic = payload.item.items[0];
            }
            else {
                state.selectWbLogistic = payload.item.items[0];
            }
            state.selectCalculated = payload;
        },
        setSelectOzonLogistic: (state, { payload }) => {
            state.selectOzonLogistic = payload;
        },
        changeAllCalculatedLogistic: (state, { payload }) => {
            state.allCalculatedLogistic.items = payload;
            state.selectOzonLogistic = {};
            state.previewCalculate = null;
        },
        resetPreviewCalculate: (state) => {
            state.previewCalculate = null;
        },
        setSelectWbLogistic: (state, { payload }) => {
            state.selectWbLogistic = payload;
        },
        resetSelectCalculated: (state) => {
            state.selectCalculated = {};
        },
        updateFieldsCalculated: (state, { payload }) => {
            var _a, _b;
            state.allCalculatedLogistic.items = (_b = (_a = state.allCalculatedLogistic) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.map((el) => {
                if (el.calculateId === payload.calculateId) {
                    el.delivery = Object.assign(Object.assign({}, el.delivery), { fbwStoragePeriod: +payload.storagePeriod > 1 ? +payload.storagePeriod : el.delivery.fbwStoragePeriod, countShipments: +payload.numberShipments > 1 ? +payload.numberShipments : el.delivery.countShipments, deliveryPrice: +payload.deliveryPrice > 1 ? +payload.deliveryPrice : el.delivery.deliveryPrice });
                    if (el.marketplace === MarketplaceEnum.Ozon) {
                        state.selectOzonLogistic = el;
                    }
                    else {
                        state.selectWbLogistic = el;
                    }
                    return el;
                }
                return el;
            });
        },
        clearCalculate: (state) => {
            state.selectCalculated = {};
            state.allCalculatedLogistic = [];
            state.previewCalculate = null;
            state.selectWbLogistic = {};
            state.selectOzonLogistic = {};
        },
        addDeliveryForCalculate: (state, { payload }) => {
            state.allCalculatedLogistic.items.push(payload.item);
            if (payload.marketplace === MarketplaceEnum.Ozon) {
                state.selectOzonLogistic = payload.item;
            }
            else {
                state.selectWbLogistic = payload.item;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getWbFbwList.pending, (state) => {
            state.logisticStatus = START_FETCH_STATUS;
        });
        builder.addCase(getWbFbwList.rejected, (state, { payload }) => {
            state.logisticStatus.isLoading = false;
            state.logisticStatus.error = payload;
        });
        builder.addCase(getWbFbwList.fulfilled, (state, { payload }) => {
            state.wbFbwList = payload.wbFbw.sort((a, b) => a.storage.localeCompare(b.storage));
            state.logisticStatus.isLoading = false;
        });
        builder.addCase(getWbComissionCategory.pending, (state) => {
            state.logisticStatus = START_FETCH_STATUS;
        });
        builder.addCase(getWbComissionCategory.rejected, (state, { payload }) => {
            state.logisticStatus.isLoading = false;
            state.logisticStatus.error = payload;
        });
        builder.addCase(getWbComissionCategory.fulfilled, (state, { payload }) => {
            state.wbCommisionCategory = payload.categories.sort((a, b) => a.name.localeCompare(b.name));
            state.logisticStatus.isLoading = false;
        });
        builder.addCase(getWbComissionList.pending, (state) => {
            state.logisticStatus = START_FETCH_STATUS;
        });
        builder.addCase(getWbComissionList.rejected, (state, { payload }) => {
            state.logisticStatus.isLoading = false;
            state.logisticStatus.error = payload;
        });
        builder.addCase(getWbComissionList.fulfilled, (state, { payload }) => {
            state.wbCommisionList = payload.wbCommissions.sort((a, b) => a.subCategory.localeCompare(b.subCategory));
            state.logisticStatus.isLoading = false;
        });
        builder.addCase(getOzonCommissionList.pending, (state) => {
            state.logisticStatus = START_FETCH_STATUS;
        });
        builder.addCase(getOzonCommissionList.rejected, (state, { payload }) => {
            state.logisticStatus.isLoading = false;
            state.logisticStatus.error = payload;
        });
        builder.addCase(getOzonCommissionList.fulfilled, (state, { payload }) => {
            state.ozonCategoryList = payload.ozonCommissions.sort((a, b) => a.productCategory.localeCompare(b.productCategory));
            state.logisticStatus.isLoading = false;
        });
        builder.addCase(getOzonFbsList.pending, (state) => {
            state.logisticStatus = START_FETCH_STATUS;
        });
        builder.addCase(getOzonFbsList.rejected, (state, { payload }) => {
            state.logisticStatus.isLoading = false;
            state.logisticStatus.error = payload;
        });
        builder.addCase(getOzonFbsList.fulfilled, (state, { payload }) => {
            state.ozonFbsList = payload;
            state.logisticStatus.isLoading = false;
        });
        builder.addCase(previewCalculate.pending, (state) => {
            state.logisticStatus = START_FETCH_STATUS;
        });
        builder.addCase(previewCalculate.rejected, (state, { payload }) => {
            state.logisticStatus.isLoading = false;
            state.logisticStatus.error = payload;
        });
        builder.addCase(previewCalculate.fulfilled, (state, { payload }) => {
            state.allCalculatedLogistic.items.map((el) => {
                if (el.calculateId === state.selectOzonLogistic.calculateId || el.calculateId === state.selectWbLogistic.calculateId) {
                    el.deliveryPrice = payload.calculating.items[0].mpExpenses;
                }
                return el;
            });
            state.previewCalculate = payload.calculating;
            state.logisticStatus.isLoading = false;
        });
    },
});
export default logisticSlice.reducer;
export const { addNewCalculatedLogistic, resetPreviewCalculate, resetSelectCalculated, updateFieldsCalculated, setSelectOzonLogistic, setSelectWbLogistic, addDeliveryForCalculate, clearCalculate, changeAllCalculatedLogistic, } = logisticSlice.actions;
export { getWbComissionCategory, previewCalculate, getWbFbwList, getWbComissionList, getOzonCommissionList, getOzonFbsList, recalculateItem, };
