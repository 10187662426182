import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import toast from 'react-hot-toast';
import { ReactComponent as Circle } from '@/shared/assets/icons/x-circle.svg';
import Success from '@/shared/assets/icons/statusDone.svg';
import * as S from './style';
const ToastSuccessContent = (props) => {
    return (_jsxs(S.ToastContainerWrapper, { children: [_jsx(S.ToastMessage, { children: props.message }), _jsx(S.ToastIcon, { children: _jsx(Circle, { onClick: () => toast.dismiss(props.toast.id) }) })] }));
};
const ToastSuccess = (message) => {
    toast((t) => (_jsx(ToastSuccessContent, { toast: t, message: message })), {
        icon: _jsx("img", { src: Success, style: { width: '24px', height: '24px' }, alt: "Success" }),
    });
};
export { ToastSuccess };
