import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from '@/shared/ui/Dialog/Dialog';
import React from 'react';
import { ReactComponent as XCircle } from '@/shared/assets/icons/x-circle.svg';
import { TooltipGraph } from '@/widgets/ui/TooltipGraph/TooltipGraph';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { handleGraphModal } from '@/entity/ModalsEntity/modals.slice';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
import { Icon, Text } from '@/shared/ui';
const GraphModal = ({ open, id }) => {
    const dispatch = useTypedDispatch();
    const onCloseModal = () => {
        dispatch(handleGraphModal({ id: '', open: false }));
        sendAmplitudeData('graph_good_modal_view_closed_btn_clicked');
    };
    return (_jsxs(Dialog, { open: open, css: { minHeight: '60vh', minWidth: '70vw', top: '50%' }, children: [_jsx(Text, { css: { marginBottom: '12px' }, type: "title", children: "\u0421\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043A\u0430" }), _jsx(Icon, { onClick: onCloseModal, IconItem: XCircle, dialogClose: true }), _jsx(TooltipGraph, { id: id })] }));
};
export { GraphModal };
