import { styled } from '@/app/styles/mainStyles';
import { Text } from '@/shared/ui/Text/style';
export const TooltipContainer = styled('div', {
    display: 'flex',
    padding: '20px 16px',
    flexDirection: 'column',
    borderRadius: '8px',
    backgroundColor: '$white',
    '@mobile': {
        maxWidth: '360px',
    },
});
export const TooltipFooter = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});
export const TooltipTitle = styled(Text, {
    marginBottom: '20px',
});
export const TooltipContent = styled(Text, {
    marginBottom: '20px',
    maxWidth: '400px',
    fontSize: '15px',
});
