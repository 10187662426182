import { configureStore } from '@reduxjs/toolkit';
import mailingSlice from '@/entity/MailingListEntity/mailing.slice';
import profileSlice from '../../../entity/ProfileEntity/profile.slice';
import shopsSlice from '@/entity/MailingShopsEntity/shops.slice';
import savedProductSlice from '@/entity/ListSavedProductsEntity/savedProducts.slice';
import productSlice from '@/entity/ProductEntity/product.slice';
import modalsSlice from '../../../entity/ModalsEntity/modals.slice';
import calculatorSlice from '@/entity/CalculatorEntity/calculator.slice';
import logisticSlice from '@/entity/LogisticEntity/logistic.slice';
import onboardingSlice from '@/entity/OnboardingEntity/onboarding.slice';
import paymentSlice from '@/entity/PaymentEntity/payment.slice';
import sidebarSlice from '@/entity/SidebarEntity/sidebar.slice';
import promocodesSlice from '@/entity/PromocodesEntity/promocodes.slice';
import newMailingSlice from '@/entity/NewMailingEntity/newMailing.slice';
const store = configureStore({
    reducer: {
        modalReducer: modalsSlice,
        mailingsReducer: mailingSlice,
        newMailightsReducer: newMailingSlice,
        profileReducer: profileSlice,
        shopsReducer: shopsSlice,
        savedProducts: savedProductSlice,
        productReducer: productSlice,
        calculatorReducer: calculatorSlice,
        logisticReducer: logisticSlice,
        onboardingReducer: onboardingSlice,
        paymentReducer: paymentSlice,
        sidebarReducer: sidebarSlice,
        promocodesReducer: promocodesSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
    devTools: process.env.NODE_ENV !== 'production',
});
export default store;
