import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from '@/shared/ui/Text/Text';
import { Space } from '@/shared/ui/Space/Space';
import { Button } from '@/shared/ui/Button/Button';
import { Dialog } from '@/shared/ui/Dialog/Dialog';
import { useHistory } from 'react-router';
import { useQuery } from '@/shared/hooks/useQuery/useQuery';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { setErrorPaidModal } from '@/entity/ModalsEntity/modals.slice';
const ErrorSubscribePayment = ({ open }) => {
    const history = useHistory();
    const query = useQuery();
    const dispatch = useTypedDispatch();
    const handleClose = () => {
        query.delete('payment_id');
        history.push({ search: query.toString() });
        dispatch(setErrorPaidModal(false));
    };
    return (_jsxs(Dialog, { open: open, closeIcon: true, onOpenChange: handleClose, children: [_jsx(Text, { css: { marginBottom: '24px' }, type: "preTitle", children: "\u0427\u0442\u043E-\u0442\u043E \u043F\u043E\u0448\u043B\u043E \u043D\u0435 \u0442\u0430\u043A" }), _jsxs(Text, { css: { marginBottom: '24px' }, type: "default", children: ["\u0412\u043E\u0437\u043D\u0438\u043A\u043B\u0430 \u043E\u0448\u0438\u0431\u043A\u0430 \u043F\u0440\u0438 \u043E\u043F\u043B\u0430\u0442\u0435 \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0438.", _jsx("br", {}), "\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u043F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0441\u043D\u043E\u0432\u0430"] }), _jsx(Space, { content: "flexEnd", children: _jsx(Button, { onClick: handleClose, type: "primary", children: "\u041F\u043E\u043F\u0440\u043E\u0431\u043E\u0432\u0430\u0442\u044C \u0441\u043D\u043E\u0432\u0430" }) })] }));
};
export { ErrorSubscribePayment };
