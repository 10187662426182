import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Dialog, Space, Text } from '@/shared/ui';
import { PersonalData } from '@/widgets/ui/AuthFlow/ui/PersonalData/PersonalData';
import { SalesExperience } from '@/widgets/ui/AuthFlow/ui/SalesExperience/SalesExperience';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { setUpdateUserInfo } from '@/entity/ModalsEntity/modals.slice';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
import { iconDictionary } from '@/shared/iconDictionary/icon-dictionray';
import { EnterCode } from '@/widgets/ui/AuthFlow/ui/EnterCode/EnterCode';
const UpdateUserInfo = ({ open }) => {
    const dispatch = useTypedDispatch();
    const [step, setStep] = useState(0);
    const [userFields, setUserFields] = useState(null);
    const incrementStep = () => {
        sendAmplitudeData('profile_questrionnare_modal_view_continue_btn_clicked');
        setStep((prevState) => prevState + 1);
    };
    const handleClose = () => {
        if (step === 0) {
            sendAmplitudeData('profile_questrionnare_modal_view_close_btn_clicked');
        }
        else {
            sendAmplitudeData('profile_questionnare_modal_view_closed');
        }
        dispatch(setUpdateUserInfo(false));
    };
    return (_jsxs(Dialog, { open: open, closeIcon: true, outside: true, css: { width: '70wv', minWidth: '600px' }, onOpenChange: handleClose, children: [_jsx(Text, { css: { marginBottom: '12px' }, type: "preTitle", children: "\u0417\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u0438\u0435 \u043F\u0440\u043E\u0444\u0438\u043B\u044F" }), _jsx(Space, { css: { marginBottom: '12px' }, size: "default", children: [0, 1, 2].map((el) => {
                    return _jsxs(Text, { css: { color: step === el ? '' : '$labelColor', fontWeight: step === el ? 700 : 600 }, children: ["\u0428\u0430\u0433 ", el + 1, " ", el !== 2 && iconDictionary.ARROWRIGHTSTEPS] });
                }) }), step === 0 &&
                _jsxs(_Fragment, { children: [_jsx(Text, { type: "labelInput", children: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0432\u0430\u0448 \u0442\u0435\u043B\u0435\u0444\u043E\u043D" }), _jsxs(Text, { type: "default", css: { marginBottom: '12px' }, children: ["\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u0443\u043A\u0430\u0436\u0438\u0442\u0435 \u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430. ", _jsx("br", {}), "\u0422\u0435\u043B\u0435\u0444\u043E\u043D \u043F\u043E\u0437\u0432\u043E\u043B\u0438\u0442 \u0432\u043E\u0441\u0441\u0442\u0430\u043D\u043E\u0432\u0438\u0442\u044C \u0434\u043E\u0441\u0442\u0443\u043F \u043A \u0430\u043A\u043A\u0430\u0443\u043D\u0442\u0443, \u0435\u0441\u043B\u0438 \u0432\u043E\u0437\u043D\u0438\u043A\u043D\u0443\u0442 \u043F\u0440\u043E\u0431\u043B\u0435\u043C\u044B."] }), _jsx(PersonalData, { setUserFields: setUserFields, userFields: userFields, incrementStep: incrementStep })] }), step === 1 &&
                _jsx(EnterCode, { incrementStep: incrementStep, phone: userFields === null || userFields === void 0 ? void 0 : userFields.phone }), step === 2 &&
                _jsx(SalesExperience, { userFields: userFields, handleCloseModal: handleClose })] }));
};
export { UpdateUserInfo };
