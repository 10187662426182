var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as S from './style';
import { forwardRef } from 'react';
const TextArea = forwardRef((_a, ref) => {
    var { value, css, autocomplete, onChange, onChangeText, typeInput, error, onFocus } = _a, props = __rest(_a, ["value", "css", "autocomplete", "onChange", "onChangeText", "typeInput", "error", "onFocus"]);
    const handleChange = (event) => {
        if (onChange) {
            if (onChangeText) {
                onChangeText(event.target.value);
            }
            onChange(event);
        }
    };
    return (_jsx(S.TextArea, Object.assign({ typeInput: typeInput, value: value, ref: ref, onBlur: onFocus, autoComplete: autocomplete ? 'on' : 'off', error: error && true, onChange: (event) => handleChange(event), css: css }, props)));
});
export { TextArea };
