import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Space, Text } from '@/shared/ui';
import { useHistory } from 'react-router';
import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
const ShopAddedSuccess = ({ shopName, handleClose }) => {
    const history = useHistory();
    const onClose = () => {
        history.push(RoutePath.newMailing);
        handleClose();
    };
    return (_jsxs(Space, { direction: "vertical", children: [_jsx(Text, { css: { marginBottom: '24px' }, type: "preTitle", children: "\u041C\u0430\u0433\u0430\u0437\u0438\u043D \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0435\u043D" }), _jsxs(Text, { css: { marginBottom: '46px' }, children: ["\u041C\u0430\u0433\u0430\u0437\u0438\u043D ", _jsx("strong", { children: shopName }), " \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0435\u043D \u043A Harvester! \u0417\u0430\u043F\u0443\u0441\u0442\u0438\u0442\u0435 \u0432\u0430\u0448\u0443 \u043F\u0435\u0440\u0432\u0443\u044E \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0443 \u0434\u043B\u044F ", _jsx("strong", { children: shopName })] }), _jsx("div", { children: _jsx(Button, { onClick: onClose, type: "primary", children: "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0443" }) })] }));
};
export { ShopAddedSuccess };
