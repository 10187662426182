import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { useMemo } from 'react';
import { UserActionEnum } from '@/features/ui/Dialogs/PaymentModal/types';
import { useQuery } from '@/shared/hooks/useQuery/useQuery';
import { useHistory } from 'react-router';
export const useParamsForSubFlow = () => {
    const currentSub = useTypedSelector((state) => { var _a; return (_a = state.paymentReducer.userSubscription) === null || _a === void 0 ? void 0 : _a.currentSub; });
    const tariffs = useTypedSelector((state) => state.paymentReducer.tariffs);
    const query = useQuery();
    const history = useHistory();
    const currentTariff = useMemo(() => {
        if (tariffs.length !== 0 && currentSub) {
            return tariffs.find((tariff) => tariff.id === currentSub.tariffId);
        }
    }, [tariffs, currentSub]);
    const handleGetQuery = (featureSubId, actionUser) => {
        const findFeatureSub = tariffs.find((tariff) => tariff.id === featureSubId);
        const origin = `${window.location.origin}/home?`;
        if (actionUser === UserActionEnum.downgrade && findFeatureSub) {
            query.set('changeType', 'downgrade');
            query.set('featureSubId', featureSubId);
            query.set('prevSubId', currentSub.tariffId);
            if (+findFeatureSub.duration > +currentTariff.duration && !findFeatureSub.name.toLowerCase().includes('free')) {
                query.set('withUpgrade', 'true');
                history.push({
                    search: query.toString(),
                });
                return `${origin}changeType=downgrade&withUpgrade=true&featureSubId=${featureSubId}&prevSubId=${currentSub === null || currentSub === void 0 ? void 0 : currentSub.tariffId}&`;
            }
            history.push({
                search: query.toString(),
            });
            return `${origin}changeType=downgrade&featureSubId=${featureSubId}&prevSubId=${currentSub === null || currentSub === void 0 ? void 0 : currentSub.tariffId}&`;
        }
        if (actionUser === UserActionEnum.upgrade && findFeatureSub) {
            return `${origin}changeType=upgrade&featureSubId=${featureSubId}&prevSubId=${currentSub === null || currentSub === void 0 ? void 0 : currentSub.tariffId}&`;
        }
        return '';
    };
    return handleGetQuery;
};
