var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import React, { useEffect, useRef, useState } from 'react';
import { postReqUser } from '@/shared/api/user';
import { ErrorUser } from '@/app/providers/locales/ErrorUser/ErrorUser';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { clearStateStore, clearUser, resendVerifyCode, setToken, setUserKindDefault, } from '@/entity/ProfileEntity/profile.slice';
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router';
import { ToastSuccess } from '@/widgets/ui/Toast/ToastSuccess/ToastSuccess';
import { ToastError } from '@/widgets/ui/Toast/ToastError/ToastError';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
import { Space, Text, BackgroundGray, ImgAuth } from '@/shared/ui';
import mainLogo from '@/shared/assets/icons/mainLogo.svg';
import { BlockWithShadow } from '@/shared/ui/BlockWithShadow/BlockWithShadow';
const cookies = new Cookies();
const SendCodeStep = ({ email, setStep, codeVerify, action, }) => {
    const dispatch = useTypedDispatch();
    const history = useHistory();
    const [sendAgain, setSendAgain] = useState(false);
    const [notRecived, setNotReceived] = useState(false);
    const [codes, setCodes] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    const resendCode = () => {
        dispatch(resendVerifyCode({ email: email, verifyType: codeVerify }));
        sendAmplitudeData('verify_page_send_code_btn_clicked');
        setSendAgain(true);
    };
    const clearStore = () => {
        dispatch(clearStateStore());
        dispatch(clearUser());
        cookies.remove('token', { path: '/' });
    };
    const handleClose = () => {
        clearStore();
        history.push(RoutePath.login);
        if (setStep) {
            setStep(1);
        }
    };
    const handleRegister = () => {
        if (setStep) {
            setStep(0);
        }
        else {
            clearStore();
            history.push(RoutePath.signUp);
        }
    };
    const handleNotRecivedCode = () => {
        sendAmplitudeData('verify_page_not_recived_code_btn_clicked');
        setNotReceived(true);
    };
    const onSubmit = (code) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const resp = yield postReqUser('verification/email', { code: code });
            cookies.set('token', resp.headers['grpc-metadata-authorization'], { maxAge: 72 * 60 * 60, path: '/' });
            sendAmplitudeData('verify_page_insert_code', {
                isOk: true,
            });
            if (resp.headers['grpc-metadata-authorization']) {
                dispatch(setToken());
                if (action === 'VerifyAccount') {
                    dispatch(setUserKindDefault());
                    ToastSuccess('Успешно!');
                    return;
                }
                history.push(RoutePath.main);
            }
        }
        catch (error) {
            sendAmplitudeData('verify_page_insert_code', {
                isOk: false,
            });
            const err = error;
            ToastError(ErrorUser[err.response.data.code]);
            setCodes(['', '', '', '', '', '']);
        }
    });
    const handleBackspace = (event, index) => {
        if (event.key === 'Backspace' && codes[index] === '' && index > 0) {
            event.preventDefault();
            const newStrings = [...codes];
            newStrings[index - 1] = '';
            inputRefs.current[index - 1].focus();
            setCodes(newStrings);
        }
    };
    const handlePaste = (event, index) => {
        event.preventDefault();
        const clipboardText = event.clipboardData.getData('text/plain');
        const maxLength = 6;
        if (clipboardText !== '') {
            const updatedStrings = [...codes];
            const newText = clipboardText.slice(0, maxLength); // Ограничение длины вставляемого текста до 6 символов
            for (let i = 0; i < newText.length; i++) {
                if (index + i < updatedStrings.length) {
                    updatedStrings[index + i] = newText[i];
                }
            }
            // Определяем индекс первого незаполненного инпута
            let nextEmptyIndex = updatedStrings.findIndex((string) => string === '');
            // Если все инпуты заполнены, устанавливаем индекс на последний инпут
            if (nextEmptyIndex === -1) {
                nextEmptyIndex = updatedStrings.length - 1;
            }
            setCodes(updatedStrings);
            setTimeout(() => {
                const allFieldsFilled = updatedStrings.every((string) => string !== '');
                if (allFieldsFilled) {
                    onSubmit(updatedStrings.join(''));
                }
            }, 0);
            // Устанавливаем фокус на следующий незаполненный инпут
            if (inputRefs.current[nextEmptyIndex]) {
                inputRefs.current[nextEmptyIndex].focus();
            }
        }
    };
    const handleChangeInput = (event, index) => __awaiter(void 0, void 0, void 0, function* () {
        const newStrings = [...codes];
        newStrings[index] = event.target.value;
        setCodes(newStrings);
        const allFieldsFilled = newStrings.every((string) => string !== '');
        if (allFieldsFilled) {
            yield onSubmit(newStrings.join(''));
        }
    });
    useEffect(() => {
        if (inputRefs.current.length > 0) {
            for (let i = 0; i < codes.length; i++) {
                if (codes[i] === '') {
                    inputRefs.current[i].focus();
                    break;
                }
            }
        }
    }, [codes]);
    return (_jsxs(Space, { css: { height: '100vh' }, children: [_jsx(S.FormContainer, { children: _jsxs(Space, { css: { maxWidth: '544px' }, direction: "vertical", children: [_jsxs(Space, { css: { marginBottom: '14px' }, direction: "vertical", children: [_jsx(Space, { css: { marginBottom: '23px' }, children: _jsx("img", { src: mainLogo, alt: "logo", style: { height: '36px' } }) }), _jsx(Text, { css: { marginBottom: '14px' }, type: "title", children: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043A\u043E\u0434" }), _jsxs(BlockWithShadow, { children: [_jsxs(Text, { css: { marginBottom: '24px' }, type: "default", children: ["\u0414\u043B\u044F \u0437\u0430\u0432\u0435\u0440\u0448\u0435\u043D\u0438\u044F \u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u0438 \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C \u0443\u0447\u0435\u0442\u043D\u0443\u044E \u0437\u0430\u043F\u0438\u0441\u044C. \u041C\u044B \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u043B\u0438 \u0432\u0430\u043C \u043A\u043E\u0434 \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u044F \u0443\u0447\u0435\u0442\u043D\u043E\u0439 \u0437\u0430\u043F\u0438\u0441\u0438 \u043D\u0430 \u043F\u043E\u0447\u0442\u0443 ", _jsxs("strong", { children: [" ", email] })] }), _jsx(Space, { content: "center", children: codes.map((el, index) => _jsx(S.CodeInput, { onKeyDown: (event) => handleBackspace(event, index), onPaste: (event) => handlePaste(event, index), placeholder: "X", onChange: (event) => handleChangeInput(event, index), value: el, ref: (inputRef) => {
                                                    if (inputRef) {
                                                        inputRefs.current[index] = inputRef;
                                                    }
                                                } }, index)) }), _jsx(Space, { css: { width: '100%' }, children: _jsxs(S.RegisterHoverButton, { children: [_jsx(Text, { onClick: handleClose, type: "select", children: "\u041D\u0435\u043F\u0440\u0430\u0432\u0438\u043B\u044C\u043D\u043E \u0432\u0432\u0435\u043B email" }), _jsx(Text, { onClick: resendCode, type: "select", children: "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E" }), _jsx(Text, { onClick: handleNotRecivedCode, type: "select", children: "\u041A\u043E\u0434 \u043D\u0435 \u043F\u0440\u0438\u0448\u0435\u043B" })] }) })] })] }), sendAgain &&
                            _jsx(BackgroundGray, { css: { marginBottom: '24px' }, children: _jsxs(Text, { type: "default", children: ["\u041C\u044B \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u043B\u0438 \u043A\u043E\u0434 \u043D\u0430 ", _jsx("strong", { children: email }), "\u00A0\u0415\u0441\u043B\u0438 \u0432\u044B \u043D\u0435 \u043C\u043E\u0436\u0435\u0442\u0435 \u043D\u0430\u0439\u0442\u0438 \u043A\u043E\u0434, \u043F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u043F\u0440\u043E\u0432\u0435\u0440\u044C\u0442\u0435 \u0432\u0435\u0440\u043D\u043E \u043B\u0438 \u0443\u043A\u0430\u0437\u0430\u043D\u0430 \u043F\u043E\u0447\u0442\u0430 \u0438 \u043D\u0435 \u043F\u043E\u043F\u0430\u043B\u043E \u043B\u0438 \u043F\u0438\u0441\u044C\u043C\u043E \u0432 \u0421\u043F\u0430\u043C. "] }) }), notRecived &&
                            _jsx(BackgroundGray, { children: _jsxs(Text, { type: "default", children: ["\u0415\u0441\u043B\u0438 \u0432\u044B \u043D\u0435 \u043C\u043E\u0436\u0435\u0442\u0435 \u043D\u0430\u0439\u0442\u0438 \u043A\u043E\u0434, \u043F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u043F\u0440\u043E\u0432\u0435\u0440\u044C\u0442\u0435 \u0432\u0435\u0440\u043D\u043E \u043B\u0438 \u0443\u043A\u0430\u0437\u0430\u043D\u0430 ", _jsx("strong", { children: email }), " \u0438 \u043D\u0435 \u043F\u043E\u043F\u0430\u043B\u043E \u043B\u0438 \u043F\u0438\u0441\u044C\u043C\u043E \u0432 \u0421\u043F\u0430\u043C.", _jsx("br", {}), "\u0415\u0441\u043B\u0438 \u043F\u0438\u0441\u044C\u043C\u0430 \u043D\u0435\u0442, \u0441\u0432\u044F\u0436\u0438\u0442\u0435\u0441\u044C \u0441 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u043E\u0439 ", _jsx("strong", { children: "support@harvester.so" })] }) })] }) }), _jsx(S.ImgContainer, { children: _jsx(ImgAuth, {}) })] }));
};
export { SendCodeStep };
