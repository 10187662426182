import { styled } from '@/app/styles/mainStyles';
import { Root, Viewport, Scrollbar, Thumb, Corner } from '@radix-ui/react-scroll-area';
export const ScrollAreaRoot = styled(Root, {
    borderRadius: 8,
    overflow: 'hidden',
    height: '100%',
});
export const ScrollAreaViewport = styled(Viewport, {
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
});
export const ScrollAreaScrollbar = styled(Scrollbar, {
    display: 'flex',
    userSelect: 'none',
    color: '$scrollColor',
    touchAction: 'none',
    '&[data-orientation="vertical"]': { width: 6 },
    '&[data-orientation="horizontal"]': {
        flexDirection: 'column',
        height: 10,
    },
});
export const ScrollAreaThumb = styled(Thumb, {
    flex: 1,
    background: '$scrollColor',
    opacity: '0.2',
    borderRadius: 10,
    position: 'relative',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        minWidth: 12,
        minHeight: 44,
    },
});
export const ScrollAreaCorner = styled(Corner, {
    background: '$scrollColor',
});
