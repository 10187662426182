import { jsx as _jsx } from "react/jsx-runtime";
import * as S from './style';
const Dropdown = ({ items, setValue, placeholder, value, error, disabled, css }) => {
    const getBorder = () => {
        if (disabled) {
            return '1px solid rgb(237, 240, 244)';
        }
        if (!!error) {
            return '1px solid #D14343';
        }
        return '1px solid #545FC9';
    };
    const customStyles = {
        dropdownIndicator: (base, state) => (Object.assign(Object.assign({}, base), { transition: 'all .2s ease', transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null })),
        control: (base, state) => {
            return (Object.assign(Object.assign({}, base), { backgroundColor: '#FFFFFF', border: getBorder(), borderRadius: state.isOpen ? '8px 8px 0 0' : '8px', borderBottom: state.isOpen && 'none', fontWeight: 400, paddingLeft: '6px', boxSizing: 'border-box', color: 'rgba(0, 0, 0, 0.65)', fontSize: '14px', lineHeight: 1.5715, height: '40px', width: '100%', '&:hover': {
                    outline: 'none',
                    boxShadow: !!error ? '0px 0px 0px 1px #F1AFAFA5' : '0px 0px 0px 1px #B3B8E7',
                }, boxShadow: state.isFocused ? !error ? '0px 0px 0px 1px #B3B8E7' : '0px 0px 0px 1px #F1AFAFA5' : 'none', outline: 'none', zIndex: 9999 }));
        },
        input: (base) => (Object.assign(Object.assign({}, base), { color: '#545FC9' })),
        singleValue: (base) => (Object.assign(Object.assign({}, base), { color: !disabled ? '#545FC9' : 'rgb(164, 169, 181)', backgroundColor: 'none' })),
        menu: (base) => (Object.assign(Object.assign({}, base), { maxHeight: '250px', overflow: 'auto', position: 'absolute', top: '26px', display: 'flex', flexDirection: 'column', padding: '6px 6px', borderRadius: '0 0 8px 8px', boxShadow: !error ? '-1px 1px 1px #B3B8E7, 1px 1px 1px #B3B8E7' : '-1px 1px 1px #F1AFAFA5, 1px 1px 1px #F1AFAFA5', 
            // boxShadow: '0px 0px 0px 1px #B3B8E7',
            borderRight: !error ? '1px solid #545FC9' : '1px solid #D14343', borderBottom: !error ? '1px solid #545FC9' : '1px solid #D14343', borderLeft: !error ? '1px solid #545FC9' : '1px solid #D14343', borderTop: 'none', zIndex: 9999 })),
        menuList: (base) => (Object.assign(Object.assign({}, base), { zIndex: 9999, color: 'white' })),
        option: (base) => (Object.assign(Object.assign({}, base), { padding: '10px', borderRadius: '10px', color: '#545FC9', background: 'none', '&:hover': {
                cursor: 'pointer',
                background: 'rgba(84, 95, 201, 0.2)',
            } })),
    };
    return (_jsx(S.Container, { css: css, children: _jsx(S.StyledSelect, { value: (value === null || value === void 0 ? void 0 : value.label) ? value : '', isDisabled: disabled, placeholder: placeholder, styles: customStyles, components: { IndicatorSeparator: () => null }, options: items, noOptionsMessage: () => _jsx(S.StyledNotFoundMessage, { children: "\u041D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E" }), onChange: (v) => {
                setValue(v);
            } }) }));
};
export { Dropdown };
