import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    onboarding: false,
    onboardingTarget: 'sidebar',
    homePage: true,
    productPage: true,
    newCalculateTitle: true,
    newCalculateFilling: true,
    newCalculateProductParameters: true,
    newCalculatePricePerfomance: true,
    logisticLastStep: true,
    logisticItems: true,
    calculatePage: true,
};
const onboardingSlice = createSlice({
    name: 'productSlice',
    initialState,
    reducers: {
        setOnboarding: (state, { payload }) => {
            state.onboarding = payload;
        },
        setOnboardingTarget: (state, { payload }) => {
            state.onboardingTarget = payload;
        },
        setOnboardingField: (state, action) => {
            state[action.payload.field] = action.payload.value;
        },
        restartOnboarding: () => { return Object.assign(Object.assign({}, initialState), { onboarding: true }); },
    },
});
export default onboardingSlice.reducer;
export const { setOnboarding, setOnboardingField, restartOnboarding, setOnboardingTarget } = onboardingSlice.actions;
