import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const iconDictionary = {
    'UPDATEDRAFTMAILINGSTATE': (_jsxs("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("g", { clipPath: "url(#clip0_6106_19807)", children: _jsx("path", { d: "M9 4.5C11.4853 4.5 13.5 6.51472 13.5 9C13.5 11.4853 11.4853 13.5 9 13.5C6.51472 13.5 4.5 11.4853 4.5 9C4.5 8.28158 4.66835 7.60248 4.96776 7M8.7 16.5H9.3C11.8202 16.5 13.0804 16.5 14.043 16.0095C14.8897 15.5781 15.5781 14.8897 16.0095 14.043C16.5 13.0804 16.5 11.8202 16.5 9.3V8.7C16.5 6.17976 16.5 4.91965 16.0095 3.95704C15.5781 3.11031 14.8897 2.4219 14.043 1.99047C13.0804 1.5 11.8202 1.5 9.3 1.5H8.7C6.17976 1.5 4.91965 1.5 3.95704 1.99047C3.11031 2.4219 2.4219 3.11031 1.99047 3.95704C1.5 4.91965 1.5 6.17976 1.5 8.7V9.3C1.5 11.8202 1.5 13.0804 1.99047 14.043C2.4219 14.8897 3.11031 15.5781 3.95704 16.0095C4.91965 16.5 6.17976 16.5 8.7 16.5Z", stroke: "#B3B8E7", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_6106_19807", children: _jsx("rect", { width: "18", height: "18", fill: "white" }) }) })] })),
    'WHITE-INFO': (_jsxs("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("g", { clipPath: "url(#clip0_7367_14329)", children: _jsx("path", { d: "M9 12V9M9 6H9.0075M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z", stroke: "#F7F7F7", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_7367_14329", children: _jsx("rect", { width: "18", height: "18", fill: "white" }) }) })] })),
    'FILTER-ITEM-TOOLTIP': (_jsxs("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0_6682_10400)", children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9Z", fill: "#3C435A" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9 8.25C9.41421 8.25 9.75 8.58579 9.75 9V12C9.75 12.4142 9.41421 12.75 9 12.75C8.58579 12.75 8.25 12.4142 8.25 12V9C8.25 8.58579 8.58579 8.25 9 8.25Z", fill: "#3C435A" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.25 6C8.25 5.58579 8.58579 5.25 9 5.25H9.00833C9.42255 5.25 9.75833 5.58579 9.75833 6C9.75833 6.41421 9.42255 6.75 9.00833 6.75H9C8.58579 6.75 8.25 6.41421 8.25 6Z", fill: "#3C435A" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_6682_10400", children: _jsx("rect", { width: "18", height: "18", fill: "white" }) }) })] })),
    'TRASH-FILTER': (_jsx("svg", { width: "20", height: "21", viewBox: "0 0 20 21", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M2.5 5.49984H4.16667M4.16667 5.49984H17.5M4.16667 5.49984V17.1665C4.16667 17.6085 4.34226 18.0325 4.65482 18.345C4.96738 18.6576 5.39131 18.8332 5.83333 18.8332H14.1667C14.6087 18.8332 15.0326 18.6576 15.3452 18.345C15.6577 18.0325 15.8333 17.6085 15.8333 17.1665V5.49984M6.66667 5.49984V3.83317C6.66667 3.39114 6.84226 2.96722 7.15482 2.65466C7.46738 2.3421 7.89131 2.1665 8.33333 2.1665H11.6667C12.1087 2.1665 12.5326 2.3421 12.8452 2.65466C13.1577 2.96722 13.3333 3.39114 13.3333 3.83317V5.49984M8.33333 9.6665V14.6665M11.6667 9.6665V14.6665", stroke: "#414BB2", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) })),
    'MAILINGS-BONUS': (_jsx("svg", { width: "22", height: "23", viewBox: "0 0 22 23", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M1.83203 6V13.3333C1.98481 15.1667 3.2987 18.8333 7.33203 18.8333H12.2446C14.4662 18.8333 17.5234 19.3691 18.1753 17.2453C18.2747 16.9213 18.332 16.5372 18.332 16.0833M8.2487 6.91667L12.832 9.66667L17.4154 6.91667M4.58203 6.91667V12.4167C4.58203 15.1667 5.4987 16.0833 8.2487 16.0833H17.4154C20.1654 16.0833 21.082 15.1667 21.082 12.4167V6.91667C21.082 4.16667 20.1654 3.25 17.4154 3.25H8.2487C5.4987 3.25 4.58203 4.16667 4.58203 6.91667Z", stroke: "#3C435A", strokeWidth: "1.8", strokeLinecap: "round", strokeLinejoin: "round" }) })),
    'CALCULATOR-BONUS': (_jsx("svg", { width: "22", height: "23", viewBox: "0 0 22 23", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M6.41667 7.83333H15.5833M10.5417 11.5H11.4583M14.2083 11.5H15.125M6.875 11.5H7.79167M6.875 15.1667H7.79167M10.5417 15.1667H11.4583M14.2083 15.1667H15.125M2.75 6.91667V16.0833C2.75 17.9167 4.58333 19.75 6.41667 19.75H15.5833C17.4167 19.75 19.25 17.9167 19.25 16.0833V6.91667C19.25 5.08333 17.4167 3.25 15.5833 3.25H6.41667C4.58333 3.25 2.75 5.08333 2.75 6.91667Z", stroke: "#3C435A", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) })),
    'PLUS-CIRCLE': (_jsxs("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("g", { clipPath: "url(#clip0_7349_7021)", children: _jsx("path", { d: "M9 6V12M6 9H12M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z", stroke: "#414BB2", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_7349_7021", children: _jsx("rect", { width: "18", height: "18", fill: "white" }) }) })] })),
    'ARROW-TOP': (_jsx("svg", { width: "19", height: "19", viewBox: "0 0 19 19", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M13.25 8.91211L9.5 5.16211L5.75 8.91211M13.25 14.1621L9.5 10.4121L5.75 14.1621", stroke: "#414BB2", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) })),
    'COPY': (_jsxs("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("g", { clipPath: "url(#clip0_6904_27557)", children: _jsx("path", { d: "M3.75 11.25H3C2.60218 11.25 2.22064 11.092 1.93934 10.8107C1.65804 10.5294 1.5 10.1478 1.5 9.75V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H9.75C10.1478 1.5 10.5294 1.65804 10.8107 1.93934C11.092 2.22064 11.25 2.60218 11.25 3V3.75M8.25 6.75H15C15.8284 6.75 16.5 7.42157 16.5 8.25V15C16.5 15.8284 15.8284 16.5 15 16.5H8.25C7.42157 16.5 6.75 15.8284 6.75 15V8.25C6.75 7.42157 7.42157 6.75 8.25 6.75Z", stroke: "#414BB2", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_6904_27557", children: _jsx("rect", { width: "18", height: "18", fill: "white" }) }) })] })),
    'SUCCESSUPDATEDRAFTMAILING': (_jsxs("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("g", { clipPath: "url(#clip0_6106_16676)", children: _jsx("path", { d: "M6 9L8 11L13 7M8.7 16.5H9.3C11.8202 16.5 13.0804 16.5 14.043 16.0095C14.8897 15.5781 15.5781 14.8897 16.0095 14.043C16.5 13.0804 16.5 11.8202 16.5 9.3V8.7C16.5 6.17976 16.5 4.91965 16.0095 3.95704C15.5781 3.11031 14.8897 2.4219 14.043 1.99047C13.0804 1.5 11.8202 1.5 9.3 1.5H8.7C6.17976 1.5 4.91965 1.5 3.95704 1.99047C3.11031 2.4219 2.4219 3.11031 1.99047 3.95704C1.5 4.91965 1.5 6.17976 1.5 8.7V9.3C1.5 11.8202 1.5 13.0804 1.99047 14.043C2.4219 14.8897 3.11031 15.5781 3.95704 16.0095C4.91965 16.5 6.17976 16.5 8.7 16.5Z", stroke: "#B3E7C7", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_6106_16676", children: _jsx("rect", { width: "18", height: "18", fill: "white" }) }) })] })),
    'MAILING-NOTIFICATION': (_jsx("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M5.71709 13.5C7.96709 13.5 9.09209 13.5 11.7171 13.5M5.71709 13.5C3.46708 13.5 2.71708 13.5 3.09208 10.5C3.46709 7.5 3.84208 4.875 4.21708 4.125C4.59209 3.375 6.01708 1.5 8.71708 1.5C11.4171 1.5 12.8421 3.375 13.2171 4.125C13.5921 4.875 13.9671 7.5 14.3421 10.5C14.7171 13.5 14.3421 13.5 11.7171 13.5M5.71709 13.5C5.59208 14.25 6.46708 15.75 8.71708 15.75C10.9671 15.75 11.8421 14.25 11.7171 13.5", stroke: "#F3F7FB", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) })),
    'CHATPREVIEWLOGO': (_jsxs("svg", { width: "22", height: "22", viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { width: "22", height: "22", rx: "4", fill: "#414BB2" }), _jsx("path", { d: "M16.1391 5.12356L14.3289 4.18227C13.2693 3.62857 12 4.40375 12 5.59975V15.4003C12 16.5963 13.2693 17.3714 14.3289 16.8177L16.1391 15.8764C16.6689 15.5996 17 15.057 17 14.459V6.54104C17 5.95411 16.6689 5.40041 16.1391 5.12356Z", fill: "white" }), _jsx("path", { d: "M5.51613 7.19099L4.80645 7.02001C4.39785 6.91943 4 7.2111 4 7.60336V13.3966C4 13.7889 4.39785 14.0806 4.80645 13.98L5.51613 13.809C5.80645 13.7386 6 13.4972 6 13.2257V7.77434C6 7.50278 5.7957 7.26139 5.51613 7.19099Z", fill: "white" }), _jsx("path", { d: "M9.46018 12.1218L8.73451 12.0086C8.34513 11.9426 8 12.2633 8 12.6782V15.3188C8 15.8281 8.50442 16.1582 8.9292 15.9224L9.65487 15.5169C9.86726 15.4037 10 15.1679 10 14.9133V12.7914C10 12.4613 9.76991 12.1784 9.46018 12.1218Z", fill: "white" }), _jsx("path", { d: "M8.73451 8.99334L9.46018 8.88012C9.76991 8.83294 10 8.54044 10 8.2102V6.08724C10 5.83248 9.86726 5.5966 9.65487 5.48337L8.9292 5.07765C8.50442 4.84176 8 5.172 8 5.68151V8.32343C8 8.73859 8.35398 9.04995 8.73451 8.99334Z", fill: "white" })] })),
    'ANDROIDCHEVRONE': (_jsxs("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0_6285_25890)", children: [_jsx("circle", { cx: "9.125", cy: "9.375", r: "8.625", fill: "#F3F0F4" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.2806 7.23564L9.125 10.3844L5.96938 7.23564L5 8.20502L9.125 12.33L13.25 8.20502L12.2806 7.23564Z", fill: "#1B1B1F" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_6285_25890", children: _jsx("rect", { width: "17.25", height: "17.25", fill: "white", transform: "translate(0.5 0.75)" }) }) })] })),
    'DATELEFT': (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M13.5 7.5L9 12L13.5 16.5", stroke: "#3C435A", strokeWidth: "1.6", strokeLinecap: "round", strokeLinejoin: "round" }) })),
    'DATERIGHT': (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M10.5 7.5L15 12L10.5 16.5", stroke: "#3C435A", strokeWidth: "1.6", strokeLinecap: "round", strokeLinejoin: "round" }) })),
    'INFO': (_jsxs("svg", { width: "12", height: "12", viewBox: "0 0 12 12", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("g", { clipPath: "url(#clip0_6285_25877)", children: _jsx("path", { d: "M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z", stroke: "#3C435A", strokeLinecap: "round", strokeLinejoin: "round" }) }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_6285_25877", children: _jsx("rect", { width: "12", height: "12", fill: "white" }) }) })] })),
    'SAVEASDRAFT': (_jsxs("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("g", { clipPath: "url(#clip0_6781_7002)", children: _jsx("path", { d: "M8.25 3.09099H3C2.60218 3.09099 2.22064 3.24903 1.93934 3.53033C1.65804 3.81164 1.5 4.19317 1.5 4.59099V15.091C1.5 15.4888 1.65804 15.8703 1.93934 16.1517C2.22064 16.433 2.60218 16.591 3 16.591H13.5C13.8978 16.591 14.2794 16.433 14.5607 16.1517C14.842 15.8703 15 15.4888 15 15.091V9.84099M13.875 1.96599C14.1734 1.66762 14.578 1.5 15 1.5C15.422 1.5 15.8266 1.66762 16.125 1.96599C16.4234 2.26436 16.591 2.66903 16.591 3.09099C16.591 3.51295 16.4234 3.91762 16.125 4.21599L9 11.341L6 12.091L6.75 9.09099L13.875 1.96599Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_6781_7002", children: _jsx("rect", { width: "18", height: "18", fill: "white" }) }) })] })),
    'ARROWRIGHT': (_jsx("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M3.75 9H14.25M14.25 9L9 3.75M14.25 9L9 14.25", stroke: "white", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) })),
    'ARROWRIGHTSTEPS': (_jsx("svg", { width: "17", height: "8", viewBox: "0 0 17 8", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z", fill: "#A4A9B5" }) })),
};
