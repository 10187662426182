import { styled } from '@/app/styles/mainStyles';
export const CodeInput = styled('input', {
    border: '1px solid $border',
    outline: 'none',
    borderRadius: '6px',
    '@desktop': {
        fontSize: '26px',
        width: '24px',
        height: '24px',
        padding: '10px',
        textAlign: 'center',
        margin: '0 0 20px 15px',
    },
    '@mobile': {
        fontSize: '17px',
        width: '17px',
        height: '17px',
        padding: '10px',
        textAlign: 'center',
        margin: '0 0 20px 15px',
    },
    '&:first-child': {
        margin: '0 0 20px 0px',
    },
});
export const ResendTimeout = styled('div', {
    backgroundColor: '$white',
    borderRadius: '8px',
    padding: '16px',
    marginBottom: '12px',
});
