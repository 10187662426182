import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, Space, Text } from '@/shared/ui';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { setEndTrialModal, setPaymentModal } from '@/entity/ModalsEntity/modals.slice';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
const EndTrialModal = ({ open }) => {
    const dispatch = useTypedDispatch();
    const handleClose = () => {
        dispatch(setEndTrialModal(false));
    };
    const handleOpenSelectTariff = () => {
        sendAmplitudeData('trial_finished_modal_view_choose_tarif_btn_clicked');
        dispatch(setPaymentModal(true));
        handleClose();
    };
    return (_jsxs(Dialog, { open: open, outside: false, children: [_jsx(Text, { css: { marginBottom: '12px' }, type: "preTitle", children: "\u041F\u0440\u043E\u0431\u043D\u044B\u0439 \u043F\u0435\u0440\u0438\u043E\u0434 \u0437\u0430\u0432\u0435\u0440\u0448\u0435\u043D" }), _jsx(Text, { css: { marginBottom: '32px' }, type: "default", children: "\u0412\u0430\u0448 \u043F\u0440\u043E\u0431\u043D\u044B\u0439 \u043F\u0435\u0440\u0438\u043E\u0434 \u043D\u0430 \u0442\u0430\u0440\u0438\u0444\u0435 Harvester Pro \u0437\u0430\u0432\u0435\u0440\u0448\u0435\u043D. \u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u0432\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043D\u043E\u0432\u044B\u0439 \u0442\u0430\u0440\u0438\u0444\u043D\u044B\u0439 \u043F\u043B\u0430\u043D." }), _jsx(Space, { content: "flexEnd", children: _jsx(Button, { onClick: handleOpenSelectTariff, type: "primary", children: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0442\u0430\u0440\u0438\u0444" }) })] }));
};
export { EndTrialModal };
