var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
import { getMailing, postMailing, putMailing } from '@/shared/api/mailing';
import { displayError } from '@/shared/utils/displayError/displayError';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
import { convertTime, dateForChange } from '@/shared/utils/checkSelectedTime';
import { MailingStatusEnum } from '@/app/interfaces/Mailings/types';
import { initialStateSecondaryFilters, initialStateFilter, } from './types';
import { OzonOrderDisblayName } from '@/features/ui/NewMailing/ui/ParamsOrders/types';
const initialState = {
    newMailingStatus: BASE_FETCH_STATUS,
    updateStatus: BASE_FETCH_STATUS,
    file: null,
    fileError: false,
    cabinetId: '',
    text: '',
    id: null,
    modalSuccess: false,
    isNeedBalance: false,
    status: '',
    needBalanceCount: 0,
    itPlannedMailing: false,
    previewCount: 0,
    filter: initialStateFilter,
    secondaryFilters: initialStateSecondaryFilters,
    count: '',
    allCount: '',
    uniqueAllCount: '',
    initSecondaryFilters: initialStateSecondaryFilters,
    secondaryFiltersFirstData: initialStateSecondaryFilters,
    plannedDate: {
        date: null,
        time: null,
    },
    updatedAt: '',
    name: '',
    parsedIds: null,
    parsedAllIds: null,
    oldVersionMailing: {
        filter: initialStateFilter,
        name: '',
        cabinetId: '',
        plannedDate: {
            date: null,
            time: null,
        },
        text: '',
        itPlannedMailing: false,
    },
};
const setParsedIdsFile = createAsyncThunk('mailingSlice/setParsedIdsFile', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const balance = thunkAPI.getState().mailingsReducer.balance;
    let isNeedBalance = false;
    let countNeedBalance = 0;
    if (payload && payload.length > balance) {
        isNeedBalance = true;
        countNeedBalance = payload.length - balance;
    }
    return {
        parsedIds: payload,
        isNeedBalance,
        countNeedBalance,
    };
}));
const getMailingById = createAsyncThunk('mailings/getMailingByIdAndSetData', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield getMailing(`request?id=${payload}`);
        return response.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getUniquePostingFormFile = createAsyncThunk('mailings/getUniquePostingFormFile', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield postMailing('postings', { postingIds: payload });
        const balance = thunkAPI.getState().mailingsReducer.balance;
        let isNeedBalance = false;
        let countNeedBalance = 0;
        if (+response.data.total > balance) {
            isNeedBalance = true;
            countNeedBalance = +response.data.total - balance;
        }
        return {
            parsedIds: response.data.total,
            isNeedBalance,
            countNeedBalance,
        };
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getSecondaryFilters = createAsyncThunk('mailings/getSecondaryFilters', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield getMailing(`uploader/filter?orderUploaderId=${payload}`);
        return response.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const setMainFields = createAsyncThunk('mailings/setMainFields', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const balance = thunkAPI.getState().mailingsReducer.balance;
    return {
        data: payload,
        balance,
    };
}));
const setFileFields = createAsyncThunk('mailings/setFileFields', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    thunkAPI.dispatch(setMainFields(payload));
    return payload;
}));
const setFieldApi = createAsyncThunk('mailings/setFieldApi', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    thunkAPI.dispatch(setMainFields(payload));
    return payload;
}));
const createMailingWithUploader = createAsyncThunk('mailings/createMailingWithUploader', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield postMailing('uploader', payload);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const updateMailingWithUploader = createAsyncThunk('mailings/updateMailingWithUploader', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield putMailing('uploader', payload);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getUploaderMailingCount = createAsyncThunk('mailings/getUploaderMailingCount', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const balance = thunkAPI.getState().mailingsReducer.balance;
        return {
            balance,
            count: payload,
        };
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const setSecondaryFiltersData = createAsyncThunk('mailings/setSecondaryFiltersData', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield postMailing('uploader/filter', payload);
        const balance = thunkAPI.getState().mailingsReducer.balance;
        return Object.assign(Object.assign({}, resp.data), { balance });
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getSecondaryFiltersCount = createAsyncThunk('mailings/getSecondaryFiltersCount', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield postMailing('uploader/filter/count', payload);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const newMailingSlice = createSlice({
    name: 'mailingSlice',
    initialState,
    reducers: {
        setFile: (state, { payload }) => {
            state.file = payload;
        },
        setFileError: (state, { payload }) => {
            state.fileError = payload;
        },
        setFields: (state, { payload }) => {
            state[payload.field] = payload.value;
        },
        setText: (state, { payload }) => {
            state.text = payload;
        },
        setProgress: (state, { payload }) => {
            state.filter.progress = payload;
        },
        setPlannedDateFields: (state, { payload }) => {
            if (payload.fullValue && payload.newValues) {
                state.plannedDate = payload.newValues;
            }
            else {
                state.plannedDate[payload.field] = payload.value;
            }
        },
        setWithLimit: (state, { payload }) => {
            state.filter[payload.field] = payload.value;
        },
        setSecondaryFilterItem: (state, { payload }) => {
            const currentState = current(state.secondaryFilters[payload.field]);
            const findIndex = currentState.find((filter) => {
                if (payload.field === 'products') {
                    return (filter === null || filter === void 0 ? void 0 : filter.name) === payload.value.name;
                }
                return filter === payload.value;
            });
            if (!findIndex) {
                state.secondaryFilters[payload.field] = [...state.secondaryFilters[payload.field], payload.value];
            }
            else {
                state.secondaryFilters[payload.field] = currentState.filter((filter) => {
                    if (payload.field === 'products') {
                        return (filter === null || filter === void 0 ? void 0 : filter.name) !== payload.value.name;
                    }
                    return filter !== payload.value;
                });
            }
        },
        setAllSecondaryFilterItems: (state, { payload }) => {
            state.secondaryFilters[payload.field] = payload.value;
        },
        setSecondaryFilterDate: (state, { payload }) => {
            state.secondaryFilters.dates = payload;
        },
        setOrderSum: (state, { payload }) => {
            state.secondaryFilters.orderSum = payload;
        },
        setInitFilter: (state, { payload }) => {
            var _a;
            if ((_a = state.initSecondaryFilters) === null || _a === void 0 ? void 0 : _a[payload]) {
                state.initSecondaryFilters[payload] = state.secondaryFilters[payload];
            }
        },
        resetFilterField: (state, { payload }) => {
            state.secondaryFilters[payload] = initialStateSecondaryFilters[payload];
        },
        setFilterLimit: (state, { payload }) => {
            state.filter[payload.field] = payload.value;
        },
        setOzonOrdersDeliveryType: (state, { payload }) => {
            state.filter.ozonOrdersDeliveryType = payload;
        },
        setOzonOrderOrderBy: (state, { payload }) => {
            state.filter.ozonOrderOrderBy = payload;
        },
        setSecondaryFiltersOzonOrderBy: (state, { payload }) => {
            state.secondaryFilters.ozonOrderOrderBy = payload;
        },
        setMailing: (state, { payload }) => {
            return Object.assign(Object.assign(Object.assign({}, state), payload), { postingIds: payload.posting_ids, status: payload.mailingStatus });
        },
        setLimit: (state, { payload }) => {
            state.secondaryFilters.limit = payload;
        },
        setPlannedRequest: (state, { payload }) => {
            state.itPlannedMailing = payload;
        },
        setDateRangeValue: (state, { payload }) => {
            state.filter.dateRange = payload;
        },
        setFiltersAfterClose: (state) => {
            state.secondaryFilters = state.secondaryFiltersFirstData;
        },
        setModalSuccess: (state, { payload }) => {
            state.modalSuccess = payload;
        },
        clearNewMailing: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setParsedIdsFile.fulfilled, (state, { payload }) => {
            state.parsedAllIds = payload.parsedIds;
        });
        builder.addCase(setMainFields.fulfilled, (state, { payload }) => {
            var _a, _b;
            const convertPlannedDate = dateForChange(convertTime(payload.data.mailingOzon.plannedStart));
            state.newMailingStatus = BASE_FETCH_STATUS;
            state.name = payload.data.mailingOzon.name;
            state.itPlannedMailing = payload.data.mailingOzon.mailingStatus === MailingStatusEnum.Planned || !!(convertPlannedDate.date && convertPlannedDate.time);
            state.plannedDate = convertPlannedDate;
            if (((_a = state.text) === null || _a === void 0 ? void 0 : _a.trim().length) === 0) {
                state.text = payload.data.mailingOzon.text;
            }
            state.id = payload.data.mailingOzon.id;
            state.cabinetId = (_b = payload.data.mailingOzon.cabinet) === null || _b === void 0 ? void 0 : _b.id;
            state.status = payload.data.mailingOzon.mailingStatus;
            state.updatedAt = payload.data.mailingOzon.updatedAt;
            if (payload.data.mailingOzon.uniqueTotal > payload.balance) {
                state.isNeedBalance = true;
                state.needBalanceCount = payload.data.mailingOzon.uniqueTotal - payload.balance;
            }
        });
        builder.addCase(setFileFields.fulfilled, (state, { payload }) => {
            state.parsedIds = Array(payload.mailingOzon.uniqueTotal).fill(true);
            state.parsedAllIds = Array(payload.mailingOzon.total).fill(true);
            state.file = { name: payload.mailingOzon.fileName };
            state.oldVersionMailing.name = state.name;
            state.oldVersionMailing.cabinetId = state.cabinetId;
            state.oldVersionMailing.itPlannedMailing = state.itPlannedMailing;
            state.oldVersionMailing.text = state.text;
            state.oldVersionMailing.plannedDate = state.plannedDate;
        });
        builder.addCase(setFieldApi.fulfilled, (state, { payload }) => {
            state.filter.dateRange = [
                payload.mailingOzon.orderUploader.ozonMailingOrdersFilters.ozonMailingOrdersMainFilter.startDate,
                payload.mailingOzon.orderUploader.ozonMailingOrdersFilters.ozonMailingOrdersMainFilter.endDate,
            ];
            if (payload.mailingOzon.orderUploader.ozonMailingOrdersFilters.ozonMailingOrdersMainFilter.limitFbo
                || payload.mailingOzon.orderUploader.ozonMailingOrdersFilters.ozonMailingOrdersMainFilter.limitFbs) {
                const value = payload.mailingOzon.orderUploader.ozonMailingOrdersFilters.ozonMailingOrdersMainFilter.ozonOrderOrderBy;
                const objOzonOrderOrderBy = { value, name: OzonOrderDisblayName[value] };
                const deliveryType = payload.mailingOzon.orderUploader.ozonMailingOrdersFilters.ozonMailingOrdersMainFilter.ozonOrdersDeliveryType;
                const findLimitFBO = deliveryType.includes('OZON_ORDERS_DELIVERY_TYPE_FBO') ? payload.mailingOzon.orderUploader.ozonMailingOrdersFilters.ozonMailingOrdersMainFilter.limitFbo : '';
                const findLimitFBS = deliveryType.includes('OZON_ORDERS_DELIVERY_TYPE_FBS') ? payload.mailingOzon.orderUploader.ozonMailingOrdersFilters.ozonMailingOrdersMainFilter.limitFbs : '';
                state.filter.limitFBO = findLimitFBO;
                state.filter.limitFBS = findLimitFBS;
                state.filter.ozonOrderOrderBy = objOzonOrderOrderBy;
                state.filter.withLimitFBO = !!findLimitFBO;
                state.filter.withLimitFBS = !!findLimitFBS;
            }
            if (payload.mailingOzon.orderUploader.fboProgress && payload.mailingOzon.orderUploader.fbsProgress) {
                state.filter.progress = {
                    uploadedPercent: ((+payload.mailingOzon.orderUploader.fboProgress.uploadedPercent + +payload.mailingOzon.orderUploader.fbsProgress.uploadedPercent) / 2).toString(),
                    estimateTime: ((+payload.mailingOzon.orderUploader.fboProgress.estimateTime + +payload.mailingOzon.orderUploader.fbsProgress.estimateTime) / 2).toString(),
                };
            }
            else if (payload.mailingOzon.orderUploader.fbsProgress) {
                state.filter.progress = payload.mailingOzon.orderUploader.fbsProgress;
            }
            else if (payload.mailingOzon.orderUploader.fboProgress) {
                state.filter.progress = payload.mailingOzon.orderUploader.fboProgress;
            }
            state.filter.ozonOrdersDeliveryType = payload.mailingOzon.orderUploader.ozonMailingOrdersFilters.ozonMailingOrdersMainFilter.ozonOrdersDeliveryType;
            state.filter.id = payload.mailingOzon.orderUploader.id;
            state.filter.uploaderId = payload.mailingOzon.orderUploader.id;
            state.filter.ozonOrderUploaderStatus = payload.mailingOzon.orderUploader.ozonOrderUploaderStatus;
            if (payload.mailingOzon.primaryUniqueTotal === 0) {
                state.uniqueAllCount = payload.mailingOzon.total;
            }
            else {
                state.uniqueAllCount = payload.mailingOzon.primaryUniqueTotal;
            }
            if (payload.mailingOzon.primaryTotal === 0) {
                state.allCount = payload.mailingOzon.total;
            }
            else {
                state.allCount = payload.mailingOzon.primaryTotal;
            }
            if (payload.mailingOzon.uniqueTotal === 0) {
                state.count = payload.mailingOzon.total;
            }
            else {
                state.count = payload.mailingOzon.uniqueTotal;
            }
            state.oldVersionMailing.filter = state.filter;
            state.oldVersionMailing.name = state.name;
            state.oldVersionMailing.cabinetId = state.cabinetId;
            state.oldVersionMailing.itPlannedMailing = state.itPlannedMailing;
            state.oldVersionMailing.text = state.text;
            state.oldVersionMailing.plannedDate = state.plannedDate;
            if (payload.mailingOzon.orderUploader.ozonMailingOrdersFilters.ozonMailingOrdersAdditionalFilter) {
                state.secondaryFilters = payload.mailingOzon.orderUploader.ozonMailingOrdersFilters.ozonMailingOrdersAdditionalFilter.filters;
                state.initSecondaryFilters = payload.mailingOzon.orderUploader.ozonMailingOrdersFilters.ozonMailingOrdersAdditionalFilter.filters;
                state.secondaryFiltersFirstData = payload.mailingOzon.orderUploader.ozonMailingOrdersFilters.ozonMailingOrdersAdditionalFilter.filters;
            }
        });
        builder.addCase(getMailingById.fulfilled, (state) => {
            state.newMailingStatus = BASE_FETCH_STATUS;
        });
        builder.addCase(getMailingById.pending, (state) => {
            state.newMailingStatus = START_FETCH_STATUS;
        });
        builder.addCase(getMailingById.rejected, (state, { payload }) => {
            state.newMailingStatus.isLoading = false;
            state.newMailingStatus.error = payload;
        });
        builder.addCase(createMailingWithUploader.fulfilled, (state) => {
            state.newMailingStatus = BASE_FETCH_STATUS;
        });
        builder.addCase(createMailingWithUploader.pending, (state) => {
            state.newMailingStatus = START_FETCH_STATUS;
        });
        builder.addCase(createMailingWithUploader.rejected, (state, { payload }) => {
            state.newMailingStatus.isLoading = false;
            state.newMailingStatus.error = payload;
        });
        builder.addCase(updateMailingWithUploader.rejected, (state, { payload }) => {
            state.newMailingStatus.isLoading = false;
            state.filter.progress = {
                estimateTime: '0',
                uploadedPercent: '0',
            };
            state.newMailingStatus.error = payload;
        });
        builder.addCase(getUploaderMailingCount.fulfilled, (state, { payload }) => {
            state.count = payload.count;
            state.isNeedBalance = +payload.count > +payload.balance;
            if (+payload.count > +payload.balance) {
                state.needBalanceCount = payload.count - payload.balance;
            }
            else {
                state.needBalanceCount = 0;
            }
        });
        builder.addCase(getUploaderMailingCount.rejected, (state, { payload }) => {
            state.newMailingStatus.isLoading = false;
            state.newMailingStatus.error = payload;
        });
        builder.addCase(getSecondaryFilters.fulfilled, (state) => {
            state.newMailingStatus = BASE_FETCH_STATUS;
        });
        builder.addCase(getSecondaryFilters.pending, (state) => {
            state.newMailingStatus = START_FETCH_STATUS;
        });
        builder.addCase(getSecondaryFilters.rejected, (state, { payload }) => {
            state.newMailingStatus.isLoading = false;
            state.newMailingStatus.error = payload;
        });
        builder.addCase(setSecondaryFiltersData.fulfilled, (state, { payload }) => {
            state.updateStatus = BASE_FETCH_STATUS;
            if (payload.balance < payload.count) {
                state.isNeedBalance = true;
                state.needBalanceCount = payload.count - payload.balance;
            }
            else {
                state.isNeedBalance = false;
                state.needBalanceCount = 0;
            }
            state.secondaryFiltersFirstData = state.secondaryFilters;
            state.count = payload.count;
        });
        builder.addCase(setSecondaryFiltersData.pending, (state) => {
            state.updateStatus = START_FETCH_STATUS;
        });
        builder.addCase(setSecondaryFiltersData.rejected, (state, { payload }) => {
            state.updateStatus.isLoading = false;
            state.updateStatus.error = payload;
        });
        builder.addCase(getSecondaryFiltersCount.fulfilled, (state, { payload }) => {
            state.updateStatus = BASE_FETCH_STATUS;
            state.previewCount = payload.count;
        });
        builder.addCase(getSecondaryFiltersCount.pending, (state) => {
            state.updateStatus = START_FETCH_STATUS;
        });
        builder.addCase(getSecondaryFiltersCount.rejected, (state, { payload }) => {
            state.updateStatus.isLoading = false;
            state.updateStatus.error = payload;
        });
        builder.addCase(getUniquePostingFormFile.fulfilled, (state, { payload }) => {
            state.updateStatus = BASE_FETCH_STATUS;
            state.isNeedBalance = payload.isNeedBalance;
            state.needBalanceCount = payload.countNeedBalance;
            if (payload.parsedIds !== '0') {
                state.parsedIds = state.parsedIds = Array(+payload.parsedIds).fill(true);
            }
        });
        builder.addCase(getUniquePostingFormFile.pending, (state) => {
            state.updateStatus = START_FETCH_STATUS;
        });
        builder.addCase(getUniquePostingFormFile.rejected, (state, { payload }) => {
            state.updateStatus.isLoading = false;
            state.updateStatus.error = payload;
        });
    },
});
export default newMailingSlice.reducer;
export const { setDateRangeValue, setFilterLimit, setOzonOrdersDeliveryType, setWithLimit, setOzonOrderOrderBy, setFile, setText, setFields, clearNewMailing, setPlannedDateFields, setPlannedRequest, setModalSuccess, setProgress, setMailing, setFileError, setSecondaryFilterItem, setSecondaryFilterDate, setOrderSum, setAllSecondaryFilterItems, setInitFilter, resetFilterField, setLimit, setSecondaryFiltersOzonOrderBy, setFiltersAfterClose, } = newMailingSlice.actions;
export { setParsedIdsFile, getSecondaryFilters, getMailingById, setFieldApi, setSecondaryFiltersData, getSecondaryFiltersCount, getUploaderMailingCount, updateMailingWithUploader, setFileFields, getUniquePostingFormFile, createMailingWithUploader, };
