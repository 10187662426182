import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { logoutUser } from '@/entity/ProfileEntity/profile.slice';
import { openAddSaveProduct, setPayMailingModal, setPaymentModal } from '@/entity/ModalsEntity/modals.slice';
import { useHistory } from 'react-router';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
import { TariffsNameEnum } from '@/entity/PaymentEntity/types';
import { setCalculatorSideBarModule, setMailingsSideBarModule, setMainSideBarModule, } from '@/entity/SidebarEntity/sidebar.slice';
import { ProductTypeEnum } from '@/app/interfaces/Mailings/types';
import { useEffect, useState } from 'react';
import { getListPayment } from '@/entity/PaymentEntity/payment.slice';
import { updateCalculateName } from '@/entity/CalculatorEntity/calculator.slice';
import { matchPath } from 'react-router-dom';
import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
export const useSideBarFlow = (setIsOpened, isDesktop) => {
    const { mailingsSideBarModule, mainSideBarModule, calculatorSideBarModule, } = useTypedSelector((state) => state.sidebarReducer);
    const calculateName = useTypedSelector((state) => state.calculatorReducer.calculateName);
    const paymentList = useTypedSelector((state) => state.paymentReducer.paymentList);
    const profile = useTypedSelector((state) => { var _a; return (_a = state === null || state === void 0 ? void 0 : state.profileReducer) === null || _a === void 0 ? void 0 : _a.profile; });
    const notification = useTypedSelector((state) => state.profileReducer.notification);
    const tariffName = useTypedSelector((state) => state.paymentReducer.userSubscriptionName);
    const currentSub = useTypedSelector((state) => state.paymentReducer.userSubscription);
    const [exitCreate, setExitCreate] = useState(false);
    const [routePath, setRoutePath] = useState('');
    const history = useHistory();
    const dispatch = useTypedDispatch();
    const handleRoute = () => {
        history.push(routePath);
        dispatch(updateCalculateName(null));
        setExitCreate(false);
        setRoutePath('');
    };
    const handleCloseExitCreate = () => {
        setExitCreate(false);
        setRoutePath('');
    };
    const handleButtonClick = (route) => {
        sendAmplitudeData('side_bar_btn_clicked', {
            sideBarBtn: route,
        });
        if (calculateName) {
            const matchParamsCalulate = matchPath(route, {
                path: RoutePath.calculateNewItem,
            });
            if (matchParamsCalulate) {
                return;
            }
            if (calculateName.trim().length !== 0) {
                setExitCreate(true);
                setRoutePath(route);
                return;
            }
        }
        if (!isDesktop) {
            setIsOpened(false);
        }
    };
    const logout = () => {
        sendAmplitudeData('log_out_btn_clicked');
        dispatch(logoutUser());
    };
    const handleBuyMailing = () => {
        sendAmplitudeData('buy_messages_modal_view_show');
        dispatch(setPayMailingModal(true));
    };
    function openAddProductModal() {
        sendAmplitudeData('analyze_good_modal_view_show');
        if (tariffName === TariffsNameEnum.Free) {
            dispatch(setPaymentModal(true));
        }
        else {
            dispatch(openAddSaveProduct('sideBar'));
        }
    }
    const toggleNav = () => {
        setIsOpened((prevState) => {
            const newState = !prevState;
            localStorage.setItem('sidebarOpened', `${newState}`);
            return newState;
        });
    };
    function setSideBarMailingModule() {
        var _a;
        sendAmplitudeData('side_bar_btn_clicked', {
            sideBarBtn: 'mailingsSideBarModule',
        });
        if ((_a = profile === null || profile === void 0 ? void 0 : profile.products) === null || _a === void 0 ? void 0 : _a.find((el) => el === ProductTypeEnum.USER_ACTIVE_PRODUCT_MAILING)) {
            dispatch(setMailingsSideBarModule(''));
        }
    }
    function setSideBarCalculatorModule() {
        sendAmplitudeData('side_bar_btn_clicked', {
            sideBarBtn: 'calculatorSideBarModule',
        });
        if (currentSub !== null) {
            dispatch(setCalculatorSideBarModule(''));
        }
    }
    function setSideBarMainModule() {
        sendAmplitudeData('side_bar_btn_clicked', {
            sideBarBtn: 'mainSideBarModule',
        });
        dispatch(setMainSideBarModule(''));
    }
    useEffect(() => {
        if (paymentList.payments.length === 0 && profile) {
            dispatch(getListPayment({ limit: '100', offset: '0' }));
        }
    }, [profile]);
    return {
        logout,
        openAddProductModal,
        toggleNav,
        setSideBarMailingModule,
        setSideBarCalculatorModule,
        setSideBarMainModule,
        handleButtonClick,
        mailingsSideBarModule,
        exitCreate,
        handleRoute,
        handleCloseExitCreate,
        mainSideBarModule,
        calculatorSideBarModule,
        profile,
        notification,
        currentSub,
        handleBuyMailing,
    };
};
