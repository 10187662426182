import { styled } from '@/app/styles/mainStyles';
export const DatePickerInputContainer = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#FFFFFF',
    border: '1px solid #B3B8E7',
    borderRadius: '8px',
    fontWeight: 400,
    paddingLeft: '6px',
    padding: '4px 11px',
    boxSizing: 'border-box',
    fontSize: '14px',
    height: '30px',
    cursor: 'pointer',
    width: '137px',
    color: '#11263C',
    lineHeight: '22px',
    '&:focus': {
        outline: 'none',
        boxShadow: '0px 0px 0px 1px #B3B8E7',
    },
    '@desktop': {
        width: '137px',
    },
});
