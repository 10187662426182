var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
import { getProduct } from '@/shared/api/calculator';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
import { differenceInDays } from 'date-fns';
import { displayError } from '@/shared/utils/displayError/displayError';
const initialState = {
    product: [],
    productInfo: null,
    productStatus: BASE_FETCH_STATUS,
    fetchProductInfo: BASE_FETCH_STATUS,
};
const getProductInfo = createAsyncThunk('product/getInfo', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const start = performance.now();
    const diff = differenceInDays(new Date(payload.endDate), new Date(payload.startDate));
    try {
        const resp = yield getProduct(payload.id ?
            `info?id=${payload.id}&startDate=${payload.startDate}&endDate=${payload.endDate}`
            :
                `info?url=${payload.url}&startDate=${payload.startDate}&endDate=${payload.endDate}`);
        const end = performance.now();
        sendAmplitudeData('SLA_good_info', {
            goodId: payload.id ? payload.id : payload.url,
            duration: Number(end - start),
            periodInDay: diff,
        });
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getLimits = createAsyncThunk('product/getLimits', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getProduct('limits');
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getProductData = createAsyncThunk('product/getData', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getProduct(`stat?id=${payload.id}&startDate=${payload.startDate}&endDate=${payload.endDate}`);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const productSlice = createSlice({
    name: 'productSlice',
    initialState,
    reducers: {
        clearProductInfo: (state) => {
            state.product = [];
            state.productInfo = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProductData.pending, (state) => {
            state.productStatus = START_FETCH_STATUS;
        });
        builder.addCase(getProductData.rejected, (state, { payload }) => {
            state.productStatus.isLoading = false;
            state.productStatus.error = payload;
        });
        builder.addCase(getProductData.fulfilled, (state, { payload }) => {
            state.productStatus.isLoading = false;
            state.product = payload;
        });
        builder.addCase(getProductInfo.pending, (state) => {
            state.fetchProductInfo = START_FETCH_STATUS;
        });
        builder.addCase(getProductInfo.rejected, (state, { payload }) => {
            state.fetchProductInfo.isLoading = false;
            state.fetchProductInfo.error = payload;
            state.productInfo = null;
            state.product = [];
        });
        builder.addCase(getProductInfo.fulfilled, (state, { payload }) => {
            state.fetchProductInfo.isLoading = false;
            state.productInfo = payload.goodInfo;
            state.product = payload.marketInfo;
        });
    },
});
export default productSlice.reducer;
export const { clearProductInfo } = productSlice.actions;
export { getProductData, getProductInfo, getLimits };
