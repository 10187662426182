import { styled } from '@/app/styles/mainStyles';
import { Content, Item, ItemText, Root, Trigger, Portal, Viewport, Value, ScrollDownButton, ScrollUpButton, Icon as PrimitiveIcon, } from '@radix-ui/react-select';
export const Select = styled(Root);
export const StyledTrigger = styled(Trigger, {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    whiteSpace: 'nowrap',
    padding: '12px 16px',
    backgroundColor: 'transparent',
    color: '$active',
    border: '1px solid $border',
    outline: 'none',
    height: '30px',
    cursor: 'pointer',
    fontFamily: '$nunito',
    justifyContent: 'space-between',
    textAlign: 'center',
    borderRadius: '8px',
    fontWeight: 500,
    fontSize: '14px',
    marginBottom: '20px',
    '&[data-state="open"]': {
        borderRadius: '8px',
    },
    '&[data-placeholder]': { color: '$scrollColor' },
    variants: {
        type: {
            default: {
                '&:hover': {
                    boxShadow: '0px 0px 0px 1px #B3B8E7',
                    transition: '0.3s',
                },
            },
            noBorder: {
                true: {
                    padding: '4px 8px',
                    border: 'none',
                },
            },
            filter: {
                border: '1px solid $active',
                color: '$active',
                fontWeight: 600,
                fontSize: '14px',
            },
            newMailingSelect: {
                backgroundColor: '$white',
                borderRadius: '6px',
                height: '30px',
                marginBottom: '0',
                '&[data-state="open"]': {
                    borderRadius: '6px',
                },
            },
        },
        error: {
            true: {
                border: '1px solid red',
            },
        },
        logistic: {
            true: {
                '&[data-state="open"]': {
                    borderRadius: '8px',
                },
            },
        },
        disabled: {
            true: {
                opacity: 0.7,
                '&:hover': {
                    boxShadow: 'none',
                },
                cursor: 'default',
            },
        },
    },
    '& > div': {
        position: 'relative',
        top: 1,
        marginLeft: 4,
        transition: '0.2s ease-in-out',
    },
    '&[aria-expanded="true"]': {
        '& > div': {
            transform: 'rotate(180deg)',
        },
    },
});
export const StyledUpButton = styled(ScrollUpButton, {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px',
});
export const StyledDownButton = styled(ScrollDownButton, {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px',
});
export const StyledContent = styled(Content, {
    width: 'var(--radix-select-trigger-width)',
    backgroundColor: 'white',
    border: '1px solid $border',
    borderRadius: '8px',
    '@desktop': {
        height: 'fit-content',
    },
    '@mobile': {
        height: '100%',
    },
});
export const StyledViewport = styled(Viewport, {});
export const StyledPortal = styled(Portal, {
    zIndex: 9999,
});
export const StyledValue = styled(Value, {
    fontFamily: '$nunito',
});
export const StyledSpaceValue = styled('span', {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    minWidth: '0px',
    textAlign: 'start',
    color: '$scrollColor',
    display: 'block',
    width: '100%',
    fontFamily: '$nunito',
});
export const StyledItem = styled(Item, {
    padding: '4px 8px',
    margin: '6px',
    cursor: 'pointer',
    fontFamily: '$nunito',
    borderRadius: '8px 8px 8px 8px',
    outline: 'none',
    transition: '0.2s ease-in-out',
    color: '$scrollColor',
    '&:not([data-state="active"]):focus': {
        backgroundColor: '$paginationButton',
    },
    '&:hover': {
        backgroundColor: '$paginationButton',
    },
    // '&:last-child': {
    //   marginBottom: '4px',
    // },
});
export const StyledItemText = styled(ItemText, {
    fontWeight: 600,
    fontFamily: '$nunito',
    color: '$scrollColor',
});
export { PrimitiveIcon };
