import { styled } from '@/app/styles/mainStyles';
export const ButtonActionsContainer = styled('div', {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '40px',
    '@mobile': {
        marginBottom: '22px',
    },
});
