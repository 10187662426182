import { styled } from '@/app/styles/mainStyles';
export const GetBonusBackground = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: '$white',
    flexDirection: 'column',
    padding: '24px 0',
    marginBottom: '12px',
});
