import { styled } from '@/app/styles/mainStyles';
import { Text } from '@/shared/ui/Text/style';
export const PaymentPlansContainer = styled('div', {
    display: 'flex',
    padding: '0 20px',
    '@desktop': {
        justifyContent: 'center',
        marginBottom: '12px',
    },
    '@mobile': {
        padding: '0',
        marginBottom: '12px',
        overflowX: 'scroll',
        flexDirection: 'column',
    },
});
export const PaymentsPlansWrapper = styled('div', {
    display: 'flex',
    width: 'fit-content',
});
export const CardPlanContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '16px',
    padding: '16px',
    border: '1px solid $border',
    background: '$white',
    marginRight: '24px',
    '&:last-child': {
        marginRight: '0px',
    },
    '@desktop': {
        minWidth: '250px',
        height: '100%',
        width: '260px',
    },
    '@mobile': {
        height: '609px',
        minWidth: '',
        width: '',
    },
    variants: {
        mostPopular: {
            true: {
                background: '#F3F7FB',
            },
        },
    },
});
export const MostPopularCardPlanContainer = styled('div', {
    background: '$activeBackground',
    width: '260px',
    position: 'relative',
    marginRight: '24px',
    top: '-22px',
    borderRadius: '16px',
    '@mobile': {
        width: '100%',
        height: '100%',
    },
});
export const DescriptionTitleText = styled(Text, {
    color: '$activeBackground',
    '@mobile': {
        fontSize: '18px !important',
    },
});
export const DescriptionText = styled(Text, {
    fontSize: '12px',
    fontWeight: '400',
});
export const CardPlanTitle = styled(Text, {
    color: '$activeBackground',
    textAlign: 'center',
    '@mobile': {},
    variants: {
        withSale: {
            true: {
                color: '$acceptButton',
            },
        },
    },
});
export const CardPlanPriceContainer = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '24px',
    background: '$white',
    width: '100%',
    position: 'relative',
    variants: {
        mostPopular: {
            true: {
                background: '#F3F7FB',
            },
        },
    },
});
export const CardPlanSale = styled('div', {
    position: 'absolute',
    top: '65px',
    right: '12px',
    padding: '8px 16px',
    width: '34px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '32px',
    border: '1px solid $revenueGood',
    background: '$revenueGoodBg',
    color: '$acceptButton',
    boxSizing: 'content-box',
    variants: {
        mostPopular: {
            false: {
                right: '0',
                top: '-24px',
            },
        },
    },
});
export const CardPrice = styled('div', {
    borderRadius: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    border: '1px solid $border',
    width: '80%',
    height: '50px',
    background: '$white',
    variants: {
        mostPopular: {
            true: {
                background: '#F3F7FB',
            },
        },
    },
});
export const PeriodText = styled(Text, {
    fontSize: '18px',
    color: '$activeBackground',
    fontFamily: '$inter',
    fontWeight: '400',
    variants: {
        withSale: {
            true: {
                color: '$acceptButton',
            },
        },
    },
});
export const CardPlanTextWithIcon = styled('div', {
    display: 'flex',
    alignItems: 'center',
    '@mobile': {
        marginBottom: '16px',
    },
    '@desktop': {
        marginBottom: '24px',
    },
    '&:last-child': {
        marginBottom: '0px',
    },
});
export const CardPlanDescriptionContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
});
export const CardPlanCustomDescription = styled('div', {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
