export var NewMailingApiStatus;
(function (NewMailingApiStatus) {
    NewMailingApiStatus["New"] = "OZON_ORDERS_UPLOADER_STATUS_NEW";
    NewMailingApiStatus["InProgress"] = "OZON_ORDERS_UPLOADER_STATUS_IN_PROGRESS";
    NewMailingApiStatus["Cancelled"] = "OZON_ORDERS_UPLOADER_STATUS_CANCELED";
    NewMailingApiStatus["Done"] = "OZON_ORDERS_UPLOADER_STATUS_DONE";
    NewMailingApiStatus["Error"] = "OZON_ORDERS_UPLOADER_STATUS_ERROR";
})(NewMailingApiStatus || (NewMailingApiStatus = {}));
export const initialStateFilter = {
    id: '',
    uploaderId: '',
    ozonOrdersDeliveryType: [],
    ozonOrderOrderBy: {
        name: '',
        value: '',
    },
    progress: {
        estimateTime: '0',
        uploadedPercent: '0',
    },
    withLimitFBS: false,
    withLimitFBO: false,
    limitFBS: '',
    limitFBO: '',
    ozonOrderUploaderStatus: '',
    dateRange: [],
};
export const initialStateSecondaryFilters = {
    actions: [],
    dates: {
        endDate: null,
        startDate: null,
    },
    orderSum: null,
    ozonOrdersDeliveryType: [],
    products: [],
    productsQuantity: [],
    region: [],
    limit: '',
    status: [],
    ozonOrderOrderBy: '',
    userOrdersQuantity: [],
};
export const onLoadStatus = [NewMailingApiStatus.New, NewMailingApiStatus.InProgress];
export var DisplayFilterTypesEnum;
(function (DisplayFilterTypesEnum) {
    DisplayFilterTypesEnum["actions"] = "\u0410\u043A\u0446\u0438\u0438";
    DisplayFilterTypesEnum["dates"] = "\u0414\u0430\u0442\u044B \u0437\u0430\u043A\u0430\u0437\u043E\u0432";
    DisplayFilterTypesEnum["orderSum"] = "\u0421\u0443\u043C\u043C\u0430 \u0437\u0430\u043A\u0430\u0437\u0430";
    DisplayFilterTypesEnum["ozonOrdersDeliveryType"] = "\u0422\u0438\u043F \u043E\u0442\u0433\u0440\u0443\u0437\u043A\u0438";
    DisplayFilterTypesEnum["products"] = "\u0422\u043E\u0432\u0430\u0440\u044B";
    DisplayFilterTypesEnum["productsQuantity"] = "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0442\u043E\u0432\u0430\u0440\u043E\u0432";
    DisplayFilterTypesEnum["region"] = "\u0420\u0435\u0433\u0438\u043E\u043D \u043F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u044F";
    DisplayFilterTypesEnum["status"] = "\u0421\u0442\u0430\u0442\u0443\u0441 \u043E\u0442\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u044F";
    DisplayFilterTypesEnum["userOrdersQuantity"] = "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0437\u0430\u043A\u0430\u0437\u043E\u0432";
})(DisplayFilterTypesEnum || (DisplayFilterTypesEnum = {}));
export var DisplayPreviewFilter;
(function (DisplayPreviewFilter) {
    DisplayPreviewFilter["actions"] = "\u0410\u043A\u0446\u0438\u0438";
    DisplayPreviewFilter["dates"] = "\u0414\u0430\u0442\u044B \u0437\u0430\u043A\u0430\u0437\u043E\u0432";
    DisplayPreviewFilter["orderSum"] = "\u0421\u0443\u043C\u043C\u0430 \u0437\u0430\u043A\u0430\u0437\u0430";
    DisplayPreviewFilter["ozonOrdersDeliveryType"] = "\u0422\u0438\u043F \u043E\u0442\u0433\u0440\u0443\u0437\u043A\u0438";
    DisplayPreviewFilter["products"] = "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0442\u043E\u0432\u0430\u0440\u043E\u0432";
    DisplayPreviewFilter["productsQuantity"] = "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0442\u043E\u0432\u0430\u0440\u043E\u0432";
    DisplayPreviewFilter["region"] = "\u0420\u0435\u0433\u0438\u043E\u043D \u043F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u044F";
    DisplayPreviewFilter["status"] = "\u0421\u0442\u0430\u0442\u0443\u0441 \u043E\u0442\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u044F";
    DisplayPreviewFilter["userOrdersQuantity"] = "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0437\u0430\u043A\u0430\u0437\u043E\u0432";
})(DisplayPreviewFilter || (DisplayPreviewFilter = {}));
