import { styled } from '@/app/styles/mainStyles';
import { Text } from '@/shared/ui/Text/style';
export const DeliveryTypeText = styled(Text, {});
export const DeliveryTypeContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '$deliveryBackground',
    cursor: 'pointer',
    borderRadius: '8px',
    width: '100%',
    '@mobile': {
        marginBottom: '16px',
    },
    '&:hover': {
        '&[data-disable-attr="false"]': {
            boxShadow: '0px 4px 10px 0 rgba(0, 0, 0, 0.2)',
            cursor: 'pointer',
        },
    },
    '&:active': {
        '&[data-disable-attr="false"]': {
            background: '$active',
            color: '$white',
            [`${DeliveryTypeText}`]: {
                color: '$white',
            },
        },
    },
    '&[data-disable-attr="true"]': {
        opacity: '0.7',
        cursor: 'not-allowed',
    },
});
