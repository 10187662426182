import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Button, Dialog, Space, Text } from '@/shared/ui';
import { useQuery } from '@/shared/hooks/useQuery/useQuery';
import { useMemo } from 'react';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { setUpdateSubModal } from '@/entity/ModalsEntity/modals.slice';
import { formatTime } from '@/shared/utils/formatTime';
import { useHistory } from 'react-router';
import { localeTime } from '@/shared/utils/subscriptionTimeLocale';
const UpdateSubModal = () => {
    const history = useHistory();
    const query = useQuery();
    const dispatch = useTypedDispatch();
    const tariffId = query.get('featureSubId');
    const prevTariffId = query.get('prevSubId');
    const changeType = query.get('changeType');
    const withUpgrade = query.get('withUpgrade');
    const allTariffs = useTypedSelector((state) => state.paymentReducer.tariffs);
    const currentSub = useTypedSelector((state) => { var _a; return (_a = state.paymentReducer.userSubscription) === null || _a === void 0 ? void 0 : _a.currentSub; });
    const findTariffById = useMemo(() => {
        if (tariffId && allTariffs.length !== 0) {
            return allTariffs.find((tariff) => tariff.id === tariffId);
        }
        return null;
    }, [tariffId, allTariffs]);
    const findPrevTariffById = useMemo(() => {
        if (prevTariffId && allTariffs.length !== 0) {
            return allTariffs.find((tariff) => tariff.id === prevTariffId);
        }
        return null;
    }, [prevTariffId, allTariffs]);
    const textFromTariff = useMemo(() => {
        if (findTariffById) {
            if (findTariffById.name.toLowerCase().includes('lite')) {
                return {
                    saveItems: 30,
                    analyze: 100,
                };
            }
            return {
                saveItems: 100,
                analyze: 250,
            };
        }
        return {
            saveItems: 30,
            analyze: 100,
        };
    }, [findTariffById]);
    const getDescriptionText = () => {
        if (changeType === 'downgrade') {
            if ((findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.duration) && (findPrevTariffById === null || findPrevTariffById === void 0 ? void 0 : findPrevTariffById.duration)) {
                if ((findPrevTariffById === null || findPrevTariffById === void 0 ? void 0 : findPrevTariffById.name) === (findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.name) && +(findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.duration) > +(findPrevTariffById === null || findPrevTariffById === void 0 ? void 0 : findPrevTariffById.duration)) {
                    return _jsx(Space, { direction: "vertical", size: "middle", children: _jsxs(Text, { type: "default", children: ["\u0421 ", formatTime(currentSub === null || currentSub === void 0 ? void 0 : currentSub.finishedAt), " \u043E\u043A\u043E\u043D\u0447\u0438\u0442\u0441\u044F \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435 \u0442\u0435\u043A\u0443\u0449\u0435\u0433\u043E \u0442\u0430\u0440\u0438\u0444\u0430 \u0438 \u0430\u043A\u0442\u0438\u0432\u0438\u0440\u0443\u0435\u0442\u0441\u044F \u0442\u0430\u0440\u0438\u0444 ", findTariffById.name, " (", localeTime(findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.duration), "). \u0421\u043B\u0435\u0434\u0443\u044E\u0449\u0435\u0435 \u0441\u043F\u0438\u0441\u0430\u043D\u0438\u0435 \u043F\u043E \u043D\u043E\u0432\u043E\u043C\u0443 \u0442\u0430\u0440\u0438\u0444\u0443 \u0431\u0443\u0434\u0435\u0442 ", formatTime(currentSub === null || currentSub === void 0 ? void 0 : currentSub.finishedAt), " \u0438 \u0441\u043E\u0441\u0442\u0430\u0432\u0438\u0442 ", findTariffById.price, "\u20BD."] }) });
                }
            }
            if ((findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.name.toLowerCase().includes('lite')) && withUpgrade) {
                return _jsxs(Space, { direction: "vertical", size: "middle", children: [_jsxs(Text, { type: "default", children: ["\u0421 \u0441\u0435\u0433\u043E\u0434\u043D\u044F\u0448\u043D\u0435\u0433\u043E \u0434\u043D\u044F \u0432\u044B \u043F\u0435\u0440\u0435\u0448\u043B\u0438 \u043D\u0430 \u0442\u0430\u0440\u0438\u0444 ", findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.name, " (", localeTime(findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.duration), ")"] }), _jsxs(Text, { type: "default", children: ["\u0412\u0430\u043C \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u043E ", textFromTariff.analyze, " \u0430\u043D\u0430\u043B\u0438\u0437\u043E\u0432 \u0442\u043E\u0432\u0430\u0440\u0430 \u0438 ", textFromTariff.saveItems, " \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u043D\u044B\u0445 \u0442\u043E\u0432\u0430\u0440\u043E\u0432."] })] });
            }
            if ((findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.name.toLowerCase().includes('lite')) && !withUpgrade) {
                return _jsxs(Space, { direction: "vertical", size: "middle", children: [_jsxs(Text, { type: "default", children: ["\u0421 ", formatTime(currentSub === null || currentSub === void 0 ? void 0 : currentSub.finishedAt), " \u043E\u043A\u043E\u043D\u0447\u0438\u0442\u0441\u044F \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435 \u0442\u0435\u043A\u0443\u0449\u0435\u0433\u043E \u0442\u0430\u0440\u0438\u0444\u0430 \u0438 \u0430\u043A\u0442\u0438\u0432\u0438\u0440\u0443\u0435\u0442\u0441\u044F \u0442\u0430\u0440\u0438\u0444 ", findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.name, " (", localeTime(findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.duration), "). \u0421\u043B\u0435\u0434\u0443\u044E\u0449\u0435\u0435 \u0441\u043F\u0438\u0441\u0430\u043D\u0438\u0435 \u043F\u043E \u043D\u043E\u0432\u043E\u043C\u0443 \u0442\u0430\u0440\u0438\u0444\u0443 \u0431\u0443\u0434\u0435\u0442 ", formatTime(currentSub === null || currentSub === void 0 ? void 0 : currentSub.finishedAt), " \u0438 \u0441\u043E\u0441\u0442\u0430\u0432\u0438\u0442 ", findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.price, "\u20BD."] }), _jsxs(Text, { type: "default", children: ["C ", formatTime(currentSub === null || currentSub === void 0 ? void 0 : currentSub.finishedAt), " \u0432\u0430\u043C \u0431\u0443\u0434\u0435\u0442 \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u043E ", textFromTariff.analyze, " \u0430\u043D\u0430\u043B\u0438\u0437\u043E\u0432 \u0442\u043E\u0432\u0430\u0440\u0430 \u0438 ", textFromTariff.saveItems, " \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u043D\u044B\u0445 \u0442\u043E\u0432\u0430\u0440\u043E\u0432."] })] });
            }
            if ((findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.name) === (findPrevTariffById === null || findPrevTariffById === void 0 ? void 0 : findPrevTariffById.name)) {
                return _jsx(Space, { direction: "vertical", size: "middle", children: _jsxs(Text, { type: "default", children: ["\u0421 ", formatTime(currentSub === null || currentSub === void 0 ? void 0 : currentSub.finishedAt), " \u043E\u043A\u043E\u043D\u0447\u0438\u0442\u0441\u044F \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435 \u0442\u0435\u043A\u0443\u0449\u0435\u0433\u043E \u0442\u0430\u0440\u0438\u0444\u0430 \u0438 \u0430\u043A\u0442\u0438\u0432\u0438\u0440\u0443\u0435\u0442\u0441\u044F \u0442\u0430\u0440\u0438\u0444 ", findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.name, " (", localeTime(findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.duration), "). \u0421\u043B\u0435\u0434\u0443\u044E\u0449\u0435\u0435 \u0441\u043F\u0438\u0441\u0430\u043D\u0438\u0435 \u043F\u043E \u043D\u043E\u0432\u043E\u043C\u0443 \u0442\u0430\u0440\u0438\u0444\u0443 \u0431\u0443\u0434\u0435\u0442 ", formatTime(currentSub === null || currentSub === void 0 ? void 0 : currentSub.finishedAt), " \u0438 \u0441\u043E\u0441\u0442\u0430\u0432\u0438\u0442 ", findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.price, "\u20BD."] }) });
            }
            return `С сегодняшнего дня вы перешли на тариф ${findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.name} (${localeTime(findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.duration)})`;
        }
        if (changeType === 'upgrade') {
            if ((findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.duration) && (findPrevTariffById === null || findPrevTariffById === void 0 ? void 0 : findPrevTariffById.duration)) {
                if ((findPrevTariffById === null || findPrevTariffById === void 0 ? void 0 : findPrevTariffById.name) === (findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.name) && +(findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.duration) > +(findPrevTariffById === null || findPrevTariffById === void 0 ? void 0 : findPrevTariffById.duration)) {
                    return _jsx(Space, { direction: "vertical", size: "middle", children: _jsxs(Text, { type: "default", children: ["\u0421 \u0441\u0435\u0433\u043E\u0434\u043D\u044F\u0448\u043D\u0435\u0433\u043E \u0434\u043D\u044F \u0432\u044B \u043F\u0435\u0440\u0435\u0448\u043B\u0438 \u043D\u0430 \u0442\u0430\u0440\u0438\u0444 ", findTariffById.name, " (", localeTime(findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.duration), ")."] }) });
                }
                if ((findPrevTariffById === null || findPrevTariffById === void 0 ? void 0 : findPrevTariffById.name.toLowerCase().includes('lite')) && findTariffById.name.toLowerCase().includes('pro')) {
                    return _jsxs(Space, { direction: "vertical", size: "middle", children: [_jsxs(Text, { type: "default", children: ["\u0421 \u0441\u0435\u0433\u043E\u0434\u043D\u044F\u0448\u043D\u0435\u0433\u043E \u0434\u043D\u044F \u0432\u044B \u043F\u0435\u0440\u0435\u0448\u043B\u0438 \u043D\u0430 \u0442\u0430\u0440\u0438\u0444 ", findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.name, " (", localeTime(findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.duration), ")"] }), _jsxs(Text, { type: "default", children: ["\u0412\u0430\u043C \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u043E ", textFromTariff.analyze, " \u0430\u043D\u0430\u043B\u0438\u0437\u043E\u0432 \u0442\u043E\u0432\u0430\u0440\u0430 \u0438 ", textFromTariff.saveItems, " \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u043D\u044B\u0445 \u0442\u043E\u0432\u0430\u0440\u043E\u0432."] })] });
                }
            }
        }
    };
    const handleClose = () => {
        query.delete('featureSubId');
        query.delete('prevSubId');
        query.delete('changeType');
        query.delete('withUpgrade');
        history.push({
            search: query.toString(),
        });
        dispatch(setUpdateSubModal(false));
    };
    return (_jsx(Dialog, { open: true, closeIcon: true, css: {
            minWidth: '600px',
            height: 'fit-content',
        }, onOpenChange: handleClose, children: _jsxs(Space, { css: { height: '100%' }, content: "between", direction: "vertical", children: [_jsxs(Space, { direction: "vertical", children: [_jsx(Text, { type: "preTitle", css: { marginBottom: '24px' }, children: "\u0422\u0430\u0440\u0438\u0444 \u043E\u0431\u043D\u043E\u0432\u043B\u0435\u043D" }), _jsx(Text, { type: "default", children: getDescriptionText() })] }), _jsxs(Space, { css: { marginTop: '24px' }, size: "default", content: "flexEnd", children: [_jsx(Button, { onClick: handleClose, type: "default", children: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E" }), (!(findTariffById === null || findTariffById === void 0 ? void 0 : findTariffById.name.toLowerCase().includes('pro')) && (findPrevTariffById === null || findPrevTariffById === void 0 ? void 0 : findPrevTariffById.name.toLowerCase().includes('pro'))) &&
                            _jsx(Button, { type: "primary", children: "\u0412\u0435\u0440\u043D\u0443\u0442\u044C\u0441\u044F \u043D\u0430 Pro" })] })] }) }));
};
export { UpdateSubModal };
