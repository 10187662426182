var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as S from './style';
import { ReactComponent as Checked } from '@/shared/assets/icons/checked.svg';
import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
export const CheckboxControl = forwardRef((_a, forwardedRef) => {
    var { disable, onChange, error, checked, setChecked, eventOnClick } = _a, props = __rest(_a, ["disable", "onChange", "error", "checked", "setChecked", "eventOnClick"]);
    return (_jsx(Controller, Object.assign({}, props, { render: ({ field }) => (_jsx(S.StyledRoot, Object.assign({}, field, { error: error && true, checked: field.value === props.value, type: field.value && 'active', onCheckedChange: (value) => {
                field.onChange(value ? props.value : '');
                if (onChange) {
                    onChange();
                }
            }, onClick: () => eventOnClick(field), children: _jsx(S.StyledIndicator, { children: field.value && _jsx(Checked, { style: { width: '14px', height: '11px' } }) }) }))) })));
});
