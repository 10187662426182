export const preparePriceAfterPromo = (price, newPrice) => {
    const updatedPrice = [];
    for (let i = 0; i < price.length; i++) {
        if (price[i].price === newPrice[i].price) {
            updatedPrice.push(price[i]);
        }
        else {
            updatedPrice.push({
                min: price[i].min,
                max: price[i].max,
                price: newPrice[i].price,
                oldPrice: price[i].price,
            });
        }
    }
    return updatedPrice;
};
