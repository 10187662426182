import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Content, Root, Trigger, Arrow, Provider, Portal } from './style';
import { forwardRef } from 'react';
import { Popover } from '@/shared/ui/Popover/Popover';
export const Tooltip = forwardRef((props, ref) => {
    const { children, css, content, side = 'top', open, align = 'center', type, onOpenChange, delayDuration, } = props;
    const isDesktop = window.innerWidth >= 1025;
    return (_jsx(_Fragment, { children: isDesktop ?
            _jsx(Provider, Object.assign({ ref: ref }, props, { children: _jsxs(Root, { open: open, onOpenChange: onOpenChange, delayDuration: delayDuration, children: [_jsx(Trigger, { asChild: true, children: children }), content &&
                            _jsx(Portal, { children: _jsxs(Content, { type: type, side: side, css: css, align: align, children: [content, _jsx(Arrow, { type: type, offset: 10, height: 6, width: 10 })] }) })] }) })) :
            _jsx(Popover, { type: type, side: "top", css: { maxWidth: '300px', textAlign: 'center', borderRadius: '8px' }, trigger: children, children: content }) }));
});
