import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import toast from 'react-hot-toast';
import { ReactComponent as Circle } from '@/shared/assets/icons/x-circle.svg';
import Error from '@/shared/assets/icons/statusError.svg';
import * as S from './style';
const ToastErrorContent = (props) => {
    return (_jsxs(S.ToastContainerWrapper, { children: [_jsx(S.ToastMessage, { children: props.message }), _jsx(S.ToastIcon, { children: _jsx(Circle, { onClick: () => toast.dismiss(props.toast.id) }) })] }));
};
const ToastError = (message) => {
    toast((t) => (_jsx(ToastErrorContent, { toast: t, message: message })), {
        icon: _jsx("img", { style: { width: '24px', height: '24px' }, src: Error, alt: "Error" }),
    });
};
export { ToastError };
