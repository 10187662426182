import { styled } from '@/app/styles/mainStyles';
export const SignUpContainer = styled('div', {
    display: 'flex',
    height: '100vh',
});
export const CodeInput = styled('input', {
    border: '1px solid $border',
    outline: 'none',
    borderRadius: '6px',
    '@desktop': {
        fontSize: '26px',
        width: '25px',
        padding: '10px 10px 10px 18px',
        margin: '0 0 20px 15px',
    },
    '@mobile': {
        fontSize: '17px',
        width: '17px',
        padding: '10px 10px 10px 16px',
        margin: '0 0 20px 15px',
    },
});
export const Img = styled('img', {
    height: '36px',
});
export const ImgContainer = styled('div', {
    display: 'flex',
    '@desktop': {
        width: '40%',
        height: '100%',
    },
    '@mobile': {
        display: 'none',
    },
});
export const FormContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    '@desktop': {
        width: '60%',
    },
    '@mobile': {
        width: '100%',
    },
});
