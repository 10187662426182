import { styled } from '@/app/styles/mainStyles';
export const GraphContainer = styled('div', {
    display: 'flex',
    marginBottom: '24px',
    '@desktop': {
        overflow: 'auto hidden',
    },
    '@mobile': {
        overflow: 'scroll hidden',
    },
});
export const GraphInfoContainer = styled('div', {
    display: 'flex',
    width: '100%',
    textAlign: 'center',
    '@desktop': {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    '@mobile': {
        flexWrap: 'wrap',
        gap: '14px',
        justifyContent: 'space-around',
    },
});
