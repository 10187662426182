import { styled } from '@/app/styles/mainStyles';
import { keyframes } from '@stitches/react';
const animation = keyframes({
    '0%': {
        transform: 'rotate(0deg)',
    },
    '100%': {
        transform: 'rotate(360deg)',
    },
});
export const SpinLoaderContainer = styled('div', {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    minHeight: '40px',
    '> div': {
        position: 'absolute',
        display: 'block',
        boxSizing: 'border-box',
        width: '32px',
        height: '32px',
        margin: '8px',
        animation: 'loading 1s linear infinite',
        border: '2px solid',
        borderColor: '$activeBackground transparent transparent',
        borderRadius: '50%',
    },
    '> div:nth-child(1)': {
        animationDelay: '-0.4s',
    },
    '> div:nth-child(2)': {
        animationDelay: '-0.2s',
    },
    variants: {
        buttons: {
            true: {
                width: '30px',
                minHeight: '24px',
                '> div': {
                    position: 'absolute',
                    display: 'block',
                    boxSizing: 'border-box',
                    width: '20px',
                    height: '20px',
                    animation: 'loading 1s linear infinite',
                    border: '2px solid',
                    borderColor: '$disableColor transparent transparent',
                    borderRadius: '50%',
                },
            },
        },
    },
    animation: `${animation} 1s linear infinite`,
});
