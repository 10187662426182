export function formatCount(count, currency) {
    if (!count || count <= 0) {
        return '0.0 ₽';
    }
    switch (currency) {
        case 'RUB': {
            const toFormat = new Intl.NumberFormat('ru-RU', {
                style: 'currency',
                currency: 'RUB',
                minimumFractionDigits: 1,
            });
            if (count > 100000) {
                return count.toLocaleString('ru-RU', {
                    style: 'currency',
                    currency: 'RUB',
                    notation: 'compact',
                });
            }
            return toFormat.format(count);
        }
        case 'EURO': {
            const toFormat = new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 1,
            });
            if (count > 100000) {
                return count.toLocaleString('nl-NL', {
                    style: 'currency',
                    currency: 'EUR',
                    notation: 'compact',
                });
            }
            return toFormat.format(count);
        }
        case 'USD': {
            const toFormat = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
            });
            if (count > 100000) {
                return count.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    notation: 'compact',
                });
            }
            return toFormat.format(count);
        }
    }
}
