export const sidebarStepsDesktop = {
    steps: [
        {
            target: '#sideBarContainer',
            content: 'В боковом меню доступны основные разделы Harvester',
            title: 'Главная страница',
            disableBeacon: true,
            placement: 'right',
            event: 'next',
        },
    ],
};
