import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import * as S from './style';
import { Icon } from '@/shared/ui';
import { LoaderIcon } from 'react-hot-toast';
export const Button = (props) => {
    const { type = 'primary', icon, fill, withoutBorder, isSVG, loading, stroke, className, children, stretch, side = 'left', activeFiltered, onClick, activePagination, disabled, id, css, name, } = props;
    const handleClick = (e) => {
        if (!disabled && onClick && !loading) {
            onClick(e);
        }
    };
    const getIcon = () => {
        if (isSVG) {
            return _jsx("span", { style: {
                    marginRight: side === 'left' ? '4px' : 0,
                    marginLeft: side === 'right' ? '4px' : 0,
                    height: '18px',
                }, children: icon });
        }
        return _jsx(Icon, { css: {
                width: 18, marginRight: side === 'left' ? '4px' : 0,
                marginLeft: side === 'right' ? '4px' : 0,
            }, stroke: stroke, fill: fill, IconItem: icon });
    };
    return (_jsxs(S.Button, { withoutBorder: withoutBorder, type: type, name: name, id: id, activeFiltered: activeFiltered, className: className ? `${className} button` : 'button', css: css, activePagination: activePagination, stretch: stretch, onClick: handleClick, disabled: disabled, children: [loading ?
                _jsx("div", { style: { width: '30px', height: '20px', display: 'flex', alignItems: 'center' }, children: _jsx(LoaderIcon, { style: { width: '18px', height: '18px', borderRightColor: 'transparent' } }) })
                :
                    (icon && side === 'left') && getIcon(), loading ? 'Загрузка...' : children, (icon && !loading && side === 'right') && getIcon()] }));
};
