import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import { Text } from '@/shared/ui';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { formatTime } from '@/shared/utils/formatTime';
import { ActivePeriodEnum } from '@/features/ui/Dialogs/PaymentModal/types';
import { useDiffTariff } from '@/shared/hooks/useDiffTariff/useDiffTariff';
const NewPriceBlock = ({ activePeriod, subType }) => {
    const currentSub = useTypedSelector((state) => { var _a; return (_a = state.paymentReducer.userSubscription) === null || _a === void 0 ? void 0 : _a.currentSub; });
    const { getNextSubPrice, getDiffPrice } = useDiffTariff(subType, activePeriod);
    const getTextForTime = () => {
        switch (activePeriod) {
            case ActivePeriodEnum.OneMonth:
                return 'Далее помесячно';
            case ActivePeriodEnum.OneYear:
                return 'Далее ежегодно';
            case ActivePeriodEnum.ThreeMonth:
                return 'Далее каждые 3 месяца';
        }
    };
    return (_jsxs(S.NewPriceContainer, { children: [_jsxs(S.DescWithPriceContainer, { children: [_jsx(Text, { type: "default", children: "\u0421\u0435\u0439\u0447\u0430\u0441 \u043A \u043E\u043F\u043B\u0430\u0442\u0435" }), _jsxs(Text, { colorText: "price", type: "pageTitle", children: [getDiffPrice(), "\u20BD"] })] }), _jsxs(S.DescWithPriceContainer, { children: [_jsx(Text, { type: "default", children: getTextForTime() }), _jsxs(Text, { colorText: "darkBlue", type: "pageTitle", children: [getNextSubPrice(), "\u20BD"] })] }), _jsxs(S.DescWithPriceContainer, { children: [_jsx(Text, { type: "default", children: "\u0421\u043B\u0435\u0434\u0443\u044E\u0449\u0430\u044F \u0434\u0430\u0442\u0430 \u0441\u043F\u0438\u0441\u0430\u043D\u0438\u044F" }), _jsx(Text, { colorText: "darkBlue", type: "pageTitle", children: formatTime(currentSub === null || currentSub === void 0 ? void 0 : currentSub.finishedAt) })] })] }));
};
export { NewPriceBlock };
