import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from '@/shared/ui/Text/Text';
import * as S from './style';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { formatCount } from '@/shared/utils/formatCount';
import { Space } from '@/shared/ui/Space/Space';
const getColor = (data, label) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    if (label === 'revenue') {
        const element = data.find((el) => el.dataKey === 'revenue');
        if (element) {
            return {
                color: { color: element.color },
                background: { background: element.color },
                content: 'Выручка',
                value: isNaN((_a = element.payload) === null || _a === void 0 ? void 0 : _a.revenue) ? 'Нет данных' : formatCount((_b = element.payload) === null || _b === void 0 ? void 0 : _b.revenue, 'RUB'),
            };
        }
    }
    if (label === 'sales') {
        const element = data.find((el) => el.dataKey === 'sales');
        if (element) {
            return {
                color: { color: element.color },
                background: { background: element.color },
                content: 'Продажи',
                value: isNaN((_c = element.payload) === null || _c === void 0 ? void 0 : _c.sales) ? 'Нет данных' : `${(_d = element.payload) === null || _d === void 0 ? void 0 : _d.sales} шт.`,
            };
        }
    }
    if (label === 'remainder') {
        const element = data.find((el) => el.dataKey === 'balance');
        if (element) {
            return {
                color: { color: element.color },
                background: { background: element.color },
                content: 'Остаток',
                value: isNaN((_e = element.payload) === null || _e === void 0 ? void 0 : _e.balance) ? 'Нет данных' : `${(_f = element.payload) === null || _f === void 0 ? void 0 : _f.balance} шт.`,
            };
        }
    }
    if (label === 'price') {
        const element = data.find((el) => el.dataKey === 'price');
        if (element) {
            return {
                color: { color: element.color },
                background: { background: element.color },
                content: 'Цена',
                value: isNaN((_g = element.payload) === null || _g === void 0 ? void 0 : _g.price) ? 'Нет данных' : formatCount((_h = element.payload) === null || _h === void 0 ? void 0 : _h.price, 'RUB'),
            };
        }
    }
    if (label === 'finalPrice') {
        const element = data.find((el) => el.dataKey === 'finalPrice');
        if (element) {
            return {
                color: { color: element.color },
                background: { background: element.color },
                content: 'Цена со скидкой',
                value: isNaN((_j = element.payload) === null || _j === void 0 ? void 0 : _j.finalPrice) ? 'Нет данных' : formatCount((_k = element.payload) === null || _k === void 0 ? void 0 : _k.finalPrice, 'RUB'),
            };
        }
    }
    if (label === 'basicPrice') {
        const element = data.find((el) => el.dataKey === 'basicPrice');
        if (element) {
            return {
                color: { color: element.color },
                background: { background: element.color },
                content: 'Цена со скидкой',
                value: isNaN((_l = element.payload) === null || _l === void 0 ? void 0 : _l.basicPrice) ? 'Нет данных' : formatCount((_m = element.payload) === null || _m === void 0 ? void 0 : _m.basicPrice, 'RUB'),
            };
        }
    }
    if (label === 'rating') {
        const element = data.find((el) => el.dataKey === 'rating');
        if (element) {
            return {
                color: { color: element.color },
                background: { background: element.color },
                content: 'Рейтинг',
                value: isNaN((_o = element.payload) === null || _o === void 0 ? void 0 : _o.rating) ? 'Нет данных' : (_p = element.payload) === null || _p === void 0 ? void 0 : _p.rating,
            };
        }
    }
    return { color: { color: 'black' }, content: '', background: { display: 'none' }, value: '0', visible: { display: 'none' } };
};
const CustomTooltip = ({ active, payload, label }) => {
    const mapArray = ['revenue', 'sales', 'remainder', 'price', 'finalPrice', 'basicPrice', 'rating'];
    if (active && payload && payload.length) {
        return (_jsxs(S.TooltipContainer, { children: [_jsx(Text, { css: { color: '$disableColor', marginBottom: '14px' }, type: "default", children: `${format(new Date(label), 'dd MMMM yyyy', { locale: ru })}` }), mapArray.map((el) => _jsxs(Space, { css: getColor(payload, el).visible, content: "between", children: [_jsxs(Space, { css: { marginRight: '24px' }, children: [_jsx(S.TooltipDivColor, { css: getColor(payload, el).background }), _jsx(Text, { type: "default", children: getColor(payload, el).content })] }), _jsx(Text, { css: { marginBottom: '8px' }, type: "default", children: getColor(payload, el).value })] }))] }));
    }
    return null;
};
export { CustomTooltip };
