import { styled } from '@/app/styles/mainStyles';
export const Divider = styled('div', {
    background: '#F4F4F4',
    height: '1px',
    width: '100%',
    variants: {
        type: {
            vertical: {
                background: '#F4F4F4',
                height: '100%',
                width: '1px',
            },
            horizontal: {
                background: '#F4F4F4',
                height: '1px',
                width: '100%',
            },
        },
    },
});
