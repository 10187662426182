import { styled } from '@/app/styles/mainStyles';
import { Root, Track, Thumb, Range } from '@radix-ui/react-slider';
export const SliderRoot = styled(Root, {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    userSelect: 'none',
    touchAction: 'none',
    width: '100%',
    height: 20,
});
export const SliderTrack = styled(Track, {
    position: 'relative',
    flexGrow: 1,
    height: '10px',
    backgroundColor: 'transparent',
    borderBottomColor: '$previewMessage',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderLeftStyle: 'solid',
    borderLeftWidth: '1px',
    borderLeftColor: '$previewMessage',
    borderRightStyle: 'solid',
    borderRightWidth: '1px',
    borderRightColor: '$previewMessage',
});
export const SliderRange = styled(Range, {
    position: 'absolute',
    backgroundColor: 'white',
    borderRadius: '9999px',
    height: 'none',
});
export const SliderThumb = styled(Thumb, {
    display: 'block',
    width: 12,
    height: 20,
    // backgroundColor: 'white',
    // boxShadow: '0 2px 10px $gray',
    // borderRadius: '10px 18px 18px 6px',
    // '&:hover': { backgroundColor: '$border' },
    '&:focus': { outline: 'none' },
});
export const ValueView = styled('span', {
    position: 'absolute',
    top: '-25px',
    color: '$scrollColor',
    fontSize: '17px',
    fontWeight: 'bold',
    fontFamily: '$inter',
});
