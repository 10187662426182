import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SideBarMobileContainer } from '@/app/styles/mainStyles';
import React from 'react';
import Cookies from 'universal-cookie';
import { Navigation } from '@/widgets/ui/Navigation/Navigation';
import { TooltipForOnboarding } from '@/features/ui/TooltipForOnboarding/TooltipForOnboarding';
import ReactJoyride, { EVENTS } from 'react-joyride';
import { setOnboardingTarget } from '@/entity/OnboardingEntity/onboarding.slice';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { sidebarStepsMobile } from '@/entity/OnboardingEntity/Steps/SidebarStep/SidebarMobile';
const cookies = new Cookies();
const MobileSideBar = ({ isTransition, isOpened, setIsOpened, isDesktop, userKind }) => {
    const dispatch = useTypedDispatch();
    const target = useTypedSelector((state) => state.onboardingReducer.onboardingTarget);
    const run = useTypedSelector((state) => state.onboardingReducer.onboarding);
    let runOnboarding = target === 'sidebar' && run;
    const handleJoyrideCallback = (data) => {
        const { type } = data;
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            setTimeout(() => {
                dispatch(setOnboardingTarget('main'));
            }, 500);
        }
    };
    if (!cookies.get('token')) {
        return null;
    }
    if (userKind === 'USER_KIND_UNVERIFIED') {
        return null;
    }
    return (_jsxs(SideBarMobileContainer, { id: "sideBarMobileContainer", isOpen: isOpened, isTransition: isTransition, children: [_jsx(Navigation, { isDesktop: isDesktop, isOpened: isOpened, setIsOpened: setIsOpened }), _jsx(ReactJoyride, { spotlightPadding: 0, continuous: true, tooltipComponent: TooltipForOnboarding, callback: handleJoyrideCallback, run: runOnboarding, steps: sidebarStepsMobile.steps })] }));
};
export { MobileSideBar };
