import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { setToken } from '@/entity/ProfileEntity/profile.slice';
import { clearStore } from '@/entity/MailingShopsEntity/shops.slice';
const cookies = new Cookies();
const WithAuth = ({ children }) => {
    const dispatch = useTypedDispatch();
    if (!cookies.get('token')) {
        dispatch(setToken());
        dispatch(clearStore());
        return _jsx(Redirect, { to: "/login" });
    }
    return (_jsx(_Fragment, { children: children }));
};
export { WithAuth };
