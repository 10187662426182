import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import { Space } from '@/shared/ui/Space/Space';
import { Button } from '@/shared/ui/Button/Button';
import { Select } from '@/shared/ui/Select/Select';
import { Input } from '@/shared/ui/Input/Input';
import { Text } from '@/shared/ui/Text/Text';
import React, { useEffect, useState } from 'react';
import { CalculateParamsEnum, FilterParamsEnum, MarketPlaceEnum } from '@/shared/hooks/useFilter/types';
import { useQuery } from '@/shared/hooks/useQuery/useQuery';
import { MarketplaceEnum, } from '@/widgets/ui/NewCalculate/ui/CustomItem/types';
import { IconFilter } from '@/features/iconsJsx/IconFilter';
import { useMedia } from 'react-use';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
const defaultItem = [
    { value: 'Есть расчет', id: CalculateParamsEnum.Available, displayName: 'Есть расчет' },
    { value: 'Нет расчета', id: CalculateParamsEnum.notAvailable, displayName: 'Нет расчета' },
    { value: 'Все', id: CalculateParamsEnum.All, displayName: 'Наличие расчета' },
];
const marketPlaceSelect = [
    { value: 'Ozon', id: MarketPlaceEnum.Ozon, displayName: 'Ozon' },
    { value: 'WildBerries', id: MarketPlaceEnum.Wildberries, displayName: 'WildBerries' },
    { value: 'Все', id: MarketPlaceEnum.All, displayName: 'Маркетплейс' },
];
const initialState = {
    search: '',
    field: null,
    DESC: false,
    marketPlace: MarketPlaceEnum.All,
    calculate: null,
};
const FilterParamsComponent = ({ setCalculationParams, filterParams, setFilterParams, saveItemComponent = false, }) => {
    const isDesktop = useMedia('(min-width: 1024px)', false);
    const query = useQuery();
    const marketPlaceQuery = query.get('marketplace');
    const [selectValue, setSelectValue] = useState(null);
    const [selectMarketplace, setSelectMarketplace] = useState(null);
    function setSearchFilterParams(e) {
        setFilterParams(Object.assign(Object.assign({}, filterParams), { search: e.target.value }));
    }
    function checkStateForIcon(field) {
        sendAmplitudeData('page_good_list_sort_btn_clicked', {
            goodListSort: field,
            sortDirection: field === (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) ? 'descending' : 'ascending',
        });
        if (field !== (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field)) {
            setFilterParams(Object.assign(Object.assign({}, filterParams), { field: field, DESC: false }));
        }
        else {
            setFilterParams(Object.assign(Object.assign({}, filterParams), { DESC: !filterParams.DESC }));
        }
    }
    function clearFilters() {
        sendAmplitudeData('page_good_list_sort_clean_btn_clicked');
        setSelectValue(null);
        if (marketPlaceQuery) {
            setFilterParams(Object.assign(Object.assign({}, initialState), { marketPlace: +marketPlaceQuery === MarketplaceEnum.Ozon ? MarketPlaceEnum.Ozon : MarketPlaceEnum.Wildberries }));
        }
        else {
            if (saveItemComponent) {
                setFilterParams(Object.assign(Object.assign({}, initialState), { marketPlace: filterParams.marketPlace }));
            }
            else {
                setFilterParams(initialState);
            }
        }
    }
    useEffect(() => {
        var _a;
        const marketplace = (_a = marketPlaceSelect.find((el) => el.id === filterParams.marketPlace)) === null || _a === void 0 ? void 0 : _a.displayName;
        setSelectMarketplace(marketplace);
    }, [filterParams]);
    return (_jsxs(S.FilterParamsContainer, { children: [(saveItemComponent && !isDesktop) &&
                _jsx(Space, { direction: "horizontal", css: { width: '100%', marginBottom: '16px' }, children: _jsx(Input, { onChange: setSearchFilterParams, placeholder: "\u041F\u043E\u0438\u0441\u043A", value: filterParams.search, css: { height: '34px' }, typeInput: "default", iconInside: true }) }), _jsxs(S.FilterContainer, { children: [_jsxs(Space, { direction: "horizontal", children: [!saveItemComponent && _jsx(S.FilterButtonContainer, { children: _jsx(Select, { defaultValue: "\u041C\u0430\u0440\u043A\u0435\u0442\u043F\u043B\u0435\u0439\u0441", type: "filter", css: { height: '37px', width: '137px' }, onValueChange: (value) => {
                                        sendAmplitudeData('page_good_list_filter_chosen', {
                                            goodListFilter: value.id,
                                        });
                                        if (!marketPlaceQuery) {
                                            setSelectMarketplace(value.displayName);
                                            setFilterParams(Object.assign(Object.assign({}, filterParams), { marketPlace: value.id }));
                                        }
                                    }, value: selectMarketplace, children: marketPlaceSelect.map((item, index) => (_jsx(Select.Item, { value: item, name: item.name, children: item.value }, index))) }) }), _jsx("div", { children: _jsx(Select, { defaultValue: "\u041D\u0430\u043B\u0438\u0447\u0438\u0435 \u0440\u0430\u0441\u0447\u0435\u0442\u0430", type: "filter", css: { height: '37px', width: '170px' }, onValueChange: (value) => {
                                        setSelectValue(value.displayName);
                                        if (setCalculationParams) {
                                            setCalculationParams(value.id);
                                        }
                                    }, value: selectValue, children: defaultItem.map((item, index) => (_jsx(Select.Item, { value: item, name: item.name, children: item.value }, index))) }) })] }), (saveItemComponent && isDesktop) &&
                        _jsx(Space, { direction: "horizontal", css: { width: '30%' }, children: _jsx(Input, { onChange: setSearchFilterParams, placeholder: "\u041F\u043E\u0438\u0441\u043A", value: filterParams.search, css: { height: '34px' }, typeInput: "default", iconInside: true }) })] }), !isDesktop && _jsx(Text, { css: { marginRight: '12px', display: 'block' }, type: "labelText", children: "\u0421\u043E\u0440\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u043F\u043E:" }), _jsxs(S.SortContainer, { children: [isDesktop &&
                        _jsx(Text, { css: { marginRight: '12px', display: 'block' }, type: "labelText", children: "\u0421\u043E\u0440\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u043F\u043E:" }), _jsxs(S.SortButtonContainer, { onClick: () => checkStateForIcon(FilterParamsEnum.createdAt), active: (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.createdAt, children: [_jsx(S.TextForFilter, { type: "labelText", decoration: "dashed", "data-active": (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.createdAt, active: (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.createdAt, children: "\u0414\u0430\u0442\u0435 \u0434\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0438\u044F" }), (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.createdAt &&
                                _jsx(IconFilter, { open: filterParams === null || filterParams === void 0 ? void 0 : filterParams.DESC })] }), _jsxs(S.SortButtonContainer, { onClick: () => checkStateForIcon(FilterParamsEnum.updatedAt), active: (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.updatedAt, children: [_jsx(S.TextForFilter, { type: "labelText", decoration: "dashed", "data-active": (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.updatedAt, active: (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.updatedAt, children: "\u0414\u0430\u0442\u0435 \u043E\u0431\u043D\u043E\u0432\u043B\u0435\u043D\u0438\u044F" }), (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.updatedAt &&
                                _jsx(IconFilter, { open: filterParams === null || filterParams === void 0 ? void 0 : filterParams.DESC })] }), _jsxs(S.SortButtonContainer, { onClick: () => checkStateForIcon(FilterParamsEnum.price), active: (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.price, children: [_jsx(S.TextForFilter, { type: "labelText", decoration: "dashed", "data-active": (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.price, active: (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.price, children: "\u0426\u0435\u043D\u0435" }), (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.price &&
                                _jsx(IconFilter, { open: filterParams === null || filterParams === void 0 ? void 0 : filterParams.DESC })] }), _jsxs(S.SortButtonContainer, { onClick: () => checkStateForIcon(FilterParamsEnum.revenue), active: (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.revenue, children: [_jsx(S.TextForFilter, { type: "labelText", decoration: "dashed", "data-active": (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.revenue, active: (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.revenue, children: "\u0412\u044B\u0440\u0443\u0447\u043A\u0435" }), (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.revenue &&
                                _jsx(IconFilter, { open: filterParams === null || filterParams === void 0 ? void 0 : filterParams.DESC })] }), _jsxs(S.SortButtonContainer, { onClick: () => checkStateForIcon(FilterParamsEnum.orders), active: (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.orders, children: [_jsx(S.TextForFilter, { type: "labelText", decoration: "dashed", "data-active": (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.orders, active: (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.orders, children: "\u0417\u0430\u043A\u0430\u0437\u0430\u043C" }), (filterParams === null || filterParams === void 0 ? void 0 : filterParams.field) === FilterParamsEnum.orders &&
                                _jsx(IconFilter, { open: filterParams === null || filterParams === void 0 ? void 0 : filterParams.DESC })] }), _jsx(Space, { children: _jsx(Button, { onClick: clearFilters, type: "filtered", children: "\u0421\u0431\u0440\u043E\u0441\u0438\u0442\u044C \u0444\u0438\u043B\u044C\u0442\u0440\u044B" }) })] })] }));
};
export { FilterParamsComponent };
