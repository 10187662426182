var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import * as S from './style';
import { Button, PhoneMaskedInput, Space, Text } from '@/shared/ui';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { sendCodeToVerifyNumber, verifyPhoneNumber } from '@/entity/ProfileEntity/profile.slice';
import { ToastError } from '@/widgets/ui/Toast/ToastError/ToastError';
import { IMask } from 'react-imask';
const maskedPhone = IMask.createMask({
    mask: '+{7}(000)000-00-00',
});
const EnterCode = ({ phone, incrementStep }) => {
    const [codes, setCodes] = useState(['', '', '', '']);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [incorrectNumber, setIncorrectNumber] = useState(false);
    const [resendCodeValue, setResendCodeValue] = useState(false);
    const [resendCodeTimeout, setResendCodeTimeout] = useState(120);
    const inputRefs = useRef([]);
    const dispatch = useTypedDispatch();
    const handleBackspace = (event, index) => {
        if (event.key === 'Backspace' && codes[index] === '' && index > 0) {
            event.preventDefault();
            const newStrings = [...codes];
            newStrings[index - 1] = '';
            inputRefs.current[index - 1].focus();
            setCodes(newStrings);
        }
    };
    if (phone || phoneNumber) {
        maskedPhone.resolve(phoneNumber ? phoneNumber : phone);
    }
    const resendCode = () => __awaiter(void 0, void 0, void 0, function* () {
        const resp = yield dispatch(sendCodeToVerifyNumber({
            phoneNumber: phoneNumber ? phoneNumber : phone,
        }));
        if (!resp.payload.code) {
            setResendCodeTimeout(120);
            setResendCodeValue(true);
        }
    });
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (incorrectNumber) {
            const resp = yield dispatch(sendCodeToVerifyNumber({
                phoneNumber: phoneNumber,
            }));
            if (!resp.payload.code) {
                setIncorrectNumber(false);
                setResendCodeTimeout(120);
            }
            return;
        }
        const allFieldsFilled = codes.every((string) => string !== '');
        if (!allFieldsFilled) {
            return ToastError('Введите код');
        }
        const data = yield dispatch(verifyPhoneNumber({ code: codes.join('') }));
        if (data.payload.code) {
            return setCodes(['', '', '', '']);
        }
        incrementStep();
    });
    const handlePaste = (event, index) => {
        event.preventDefault();
        const clipboardText = event.clipboardData.getData('text/plain');
        const maxLength = 4;
        if (clipboardText !== '') {
            const updatedStrings = [...codes];
            const newText = clipboardText.slice(0, maxLength);
            for (let i = 0; i < newText.length; i++) {
                if (index + i < updatedStrings.length) {
                    updatedStrings[index + i] = newText[i];
                }
            }
            let nextEmptyIndex = updatedStrings.findIndex((string) => string === '');
            if (nextEmptyIndex === -1) {
                nextEmptyIndex = updatedStrings.length - 1;
            }
            setCodes(updatedStrings);
            if (inputRefs.current[nextEmptyIndex]) {
                inputRefs.current[nextEmptyIndex].focus();
            }
        }
    };
    const handleChangeInput = (event, index) => __awaiter(void 0, void 0, void 0, function* () {
        const newStrings = [...codes];
        newStrings[index] = event.target.value;
        setCodes(newStrings);
    });
    const handleChangePhoneNumber = (value) => {
        setPhoneNumber(value);
    };
    useEffect(() => {
        if (inputRefs.current.length > 0) {
            for (let i = 0; i < codes.length; i++) {
                if (codes[i] === '') {
                    inputRefs.current[i].focus();
                    break;
                }
            }
        }
    }, [codes]);
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
    const handleBack = () => {
        setIncorrectNumber(false);
        setPhoneNumber('');
    };
    useEffect(() => {
        if (resendCodeTimeout <= 0)
            return;
        const intervalId = setInterval(() => {
            setResendCodeTimeout((prev) => prev - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [resendCodeTimeout]);
    return (_jsxs(Space, { direction: "vertical", size: "default", children: [_jsx(Text, { type: "labelInput", children: incorrectNumber ? 'Введите телефон повторно' : 'Подтверждение телефона' }), !incorrectNumber && _jsxs(Text, { type: "default", children: ["\u0421\u0435\u0439\u0447\u0430\u0441 \u043F\u043E \u043D\u043E\u043C\u0435\u0440\u0443 ", _jsx("strong", { children: maskedPhone.value }), " \u0432\u0430\u043C \u043F\u043E\u0437\u0432\u043E\u043D\u0438\u0442 \u0431\u043E\u0442 \u0438\u0437 Harvester \u0438 ", _jsx("br", {}), " \u043D\u0430\u0437\u043E\u0432\u0435\u0442 \u043A\u043E\u0434 \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u044F, \u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u0435\u0433\u043E \u043D\u0438\u0436\u0435:"] }), incorrectNumber &&
                _jsx(PhoneMaskedInput, { labelFontFamily: "nunito", lazy: false, typeInput: "auth", label: "\u0422\u0435\u043B\u0435\u0444\u043E\u043D", autocomplete: "new-password", placeholder: "+7", mask: '+{7}(000)000-00-00', cssContainer: { width: '100%', marginBottom: '12px' }, css: { width: '100%' }, value: phoneNumber, onAccept: (event, mask) => {
                        handleChangePhoneNumber(mask.unmaskedValue);
                    } }), !incorrectNumber &&
                _jsxs(Space, { direction: "vertical", size: "default", children: [_jsx(Text, { type: "default", css: { fontWeight: 700 }, children: "\u041A\u043E\u0434 \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u044F" }), _jsx(Space, { content: "flexStart", children: codes.map((el, index) => _jsx(S.CodeInput, { onKeyDown: (event) => handleBackspace(event, index), onPaste: (event) => handlePaste(event, index), onChange: (event) => handleChangeInput(event, index), value: el, ref: (inputRef) => {
                                    if (inputRef) {
                                        inputRefs.current[index] = inputRef;
                                    }
                                } }, index)) })] }), resendCodeValue &&
                _jsxs(S.ResendTimeout, { children: ["\u0417\u0432\u043E\u043D\u0438\u043C \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E \u043D\u0430 ", _jsx("strong", { children: maskedPhone.value })] }), !incorrectNumber &&
                _jsxs(Space, { css: { marginBottom: '12px' }, align: "center", size: "middle", children: [resendCodeTimeout !== 0 ?
                            _jsxs(Text, { type: "labelText", children: ["\u0417\u0430\u043F\u0440\u043E\u0441\u0438\u0442\u044C \u043F\u043E\u0432\u0442\u0440\u043D\u043E \u0447\u0435\u0440\u0435\u0437 ", formatTime(resendCodeTimeout)] })
                            :
                                _jsx(Text, { disable: resendCodeTimeout !== 0, onClick: resendCode, type: "select", children: "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E" }), _jsx(Text, { onClick: () => setIncorrectNumber(true), type: "select", children: "\u041D\u0435\u043F\u0440\u0430\u0432\u0438\u043B\u044C\u043D\u043E \u0432\u0432\u0435\u043B \u043D\u043E\u043C\u0435\u0440" })] }), _jsxs(Space, { size: "middle", align: "center", children: [_jsx(Button, { onClick: onSubmit, children: "\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C" }), incorrectNumber && _jsx(Text, { type: "select", onClick: handleBack, children: "\u041D\u0430\u0437\u0430\u0434" })] })] }));
};
export { EnterCode };
