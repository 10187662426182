import { jsx as _jsx } from "react/jsx-runtime";
import * as S from './style';
import React from 'react';
const CollapseButton = ({ open, setOpen, sideBarHead }) => {
    return (_jsx(S.IconItem, { "data-state-open": open, onClick: (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (setOpen) {
                setOpen(event);
            }
        }, children: _jsx(S.IconCollapse, { children: _jsx("path", { d: "M8.75 5.25L5 1.5L1.25 5.25M8.75 10.5L5 6.75L1.25 10.5", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) }) }));
};
export { CollapseButton };
