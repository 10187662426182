import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import * as S from './style';
import { Icon } from '@/shared/ui/Icon/Icon';
import { routes } from '@/shared/utils/routes';
import { Space } from '@/shared/ui';
const SideBarButton = ({ text, IconItem, handleButtonClick, open, active, route, notification, }) => {
    return (_jsx(S.SideBarBodySectionButtonWrapper, { id: route === routes.calculateNewItem ? 'caluclateSidebarButton' : '', display: open, active: active, onClick: handleButtonClick, children: _jsxs(S.SideBarBodySectionButtonRow, { children: [_jsxs(Space, { align: "center", children: [IconItem &&
                            _jsx(Icon, { IconItem: IconItem, active: active, fill: "sideBar", sideBarHover: "sideBar" }), _jsx(S.SideBarBodySectionButtonText, { active: active, children: text })] }), notification && _jsx(S.NotificationPoint, {})] }) }));
};
export default SideBarButton;
