import { styled } from '@/app/styles/mainStyles';
export const BreadCrumbsWrapper = styled('div', {
    width: '100%',
    display: 'flex',
    padding: '16px 0',
    cursor: 'pointer',
    alignItems: 'center',
});
export const BreadCrumbsContainer = styled('div', {
    display: 'flex',
    alignItems: 'center',
});
export const BreadCrumbsTextBack = styled('div', {
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '16px',
    color: '$textBlackColor',
    textDecorationLine: 'underline',
    '&:hover': {
        color: '$active',
    },
    '&:active': {
        color: '$activeBackground',
    },
});
