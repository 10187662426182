import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import { Pro } from './Pro/Pro';
import { Free } from './Free/Free';
import { Lite } from './Lite/Lite';
import { Custom } from './Custom/Custom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useState } from 'react';
import { NavDots, NavDotsContainer } from '@/features/ui/Dialogs/PaymentModal/style';
const PaymentPlans = ({ activeValuesForPlans, getIsFeatureTariff, handleClickSub, tariffName, isDesktop, getIsCurrentTariff, }) => {
    const [swiper, setSwiper] = useState(null);
    const [activeIndex, setActiveIndex] = useState(1);
    const onSwiperReady = (swipe) => {
        setSwiper(swipe);
    };
    const handleSlideChange = () => {
        if (swiper) {
            setActiveIndex(swiper.activeIndex);
        }
    };
    return (_jsxs(S.PaymentPlansContainer, { children: [isDesktop ?
                _jsxs(S.PaymentsPlansWrapper, { children: [_jsx(Free, { getIsCurrentTariff: getIsCurrentTariff, tariffName: tariffName, handleClickSub: handleClickSub, getIsFeatureTariff: getIsFeatureTariff }), _jsx(Lite, { tariffName: tariffName, getIsCurrentTariff: getIsCurrentTariff, handleClickSub: handleClickSub, price: activeValuesForPlans.lite, sale: activeValuesForPlans.liteSale, getIsFeatureTariff: getIsFeatureTariff }), _jsx(Pro, { tariffName: tariffName, getIsCurrentTariff: getIsCurrentTariff, sale: activeValuesForPlans.proSale, handleClickSub: handleClickSub, price: activeValuesForPlans.pro, getIsFeatureTariff: getIsFeatureTariff }), _jsx(Custom, { handleClickSub: handleClickSub })] })
                :
                    _jsxs(Swiper, { style: { paddingTop: '22px', marginBottom: '4px', width: '100%' }, 
                        // @ts-ignore
                        centeredSlides: true, initialSlide: 1, spaceBetween: 20, slidesPerView: 1.2, onSwiper: onSwiperReady, onSlideChange: handleSlideChange, children: [_jsx(SwiperSlide, { style: { width: '290px !important' }, children: _jsx(Free, { getIsCurrentTariff: getIsCurrentTariff, tariffName: tariffName, getIsFeatureTariff: getIsFeatureTariff, handleClickSub: handleClickSub }) }), _jsx(SwiperSlide, { style: { width: '290px !important' }, children: _jsx(Lite, { getIsCurrentTariff: getIsCurrentTariff, sale: activeValuesForPlans.liteSale, tariffName: tariffName, handleClickSub: handleClickSub, price: activeValuesForPlans.lite, getIsFeatureTariff: getIsFeatureTariff }) }), _jsx(SwiperSlide, { style: { width: '290px !important' }, children: _jsx(Pro, { getIsCurrentTariff: getIsCurrentTariff, sale: activeValuesForPlans.proSale, tariffName: tariffName, handleClickSub: handleClickSub, price: activeValuesForPlans.pro, getIsFeatureTariff: getIsFeatureTariff }) }), _jsx(SwiperSlide, { style: { width: '290px !important' }, children: _jsx(Custom, { handleClickSub: handleClickSub }) })] }), !isDesktop &&
                _jsx(NavDotsContainer, { children: Array.from({ length: 4 }, (_, index) => (_jsx(NavDots, { active: index === activeIndex, onClick: () => {
                            swiper.slideTo(index);
                        } }, index))) })] }));
};
export { PaymentPlans };
