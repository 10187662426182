import { ReactComponent as WbIcon } from '@/shared/assets/icons/wbIcon.svg';
import { ReactComponent as OzonIcon } from '@/shared/assets/icons/ozonIcon.svg';
export function getItemIcon(market) {
    if (market === 'MARKET_WILD_BERRIES') {
        return WbIcon;
    }
    else {
        return OzonIcon;
    }
}
