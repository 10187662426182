import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ReactComponent as Info } from '@/shared/assets/icons/info.svg';
import { BackgroundGray, Tooltip, Icon, Space } from '@/shared/ui';
import * as S from './style';
const BackgroundHint = ({ children, text = false, tooltipContent, css, align, error, }) => {
    return (_jsx(BackgroundGray, { error: error, css: css, children: _jsxs(Space, { align: align, children: [tooltipContent ?
                    _jsx(Tooltip, { delayDuration: 0, content: tooltipContent, children: _jsx("div", { style: { padding: '2px 0 0 2px' }, children: _jsx(Icon, { css: { width: '18px' }, IconItem: Info }) }) }) :
                    _jsx("div", { style: { padding: '2px 0 0 2px' }, children: _jsx(Icon, { css: { width: '18px' }, IconItem: Info }) }), !text ? _jsx(S.BackgroundHintText, { hintError: error, css: { marginLeft: '6px', width: '100%' }, type: "default", children: children }) :
                    { children }] }) }));
};
export { BackgroundHint };
