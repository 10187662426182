import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { useEffect } from 'react';
import { setCompleteOnboarding } from '@/entity/ModalsEntity/modals.slice';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
export const useOnboarding = () => {
    const dispatch = useTypedDispatch();
    const onboardingState = useTypedSelector((state) => state.onboardingReducer);
    useEffect(() => {
        if (onboardingState.onboarding && onboardingState.onboardingTarget === 'main') {
            const object = {
                newCalculateProductParameters: onboardingState.newCalculateProductParameters,
                newCalculatePricePerfomance: onboardingState.newCalculatePricePerfomance,
                homePage: onboardingState.homePage,
                productPage: onboardingState.productPage,
                logisticItems: onboardingState.logisticItems,
                logisticLastStep: onboardingState.logisticLastStep,
                calculatePage: onboardingState.calculatePage,
                newCalculateFilling: onboardingState.newCalculateFilling,
                newCalculateTitle: onboardingState.newCalculateTitle,
            };
            const checkOnboarding = Object.entries(object).every(([, value]) => value === false);
            if (checkOnboarding) {
                dispatch(setCompleteOnboarding(true));
            }
        }
    }, [onboardingState]);
};
