import { styled } from '@/app/styles/mainStyles';
export const ToastContainerWrapper = styled('div', {
    fontFace: '$montserrat',
    display: 'flex',
    minWidth: '350px',
    padding: '16px 24px 16px 14px',
    wordWarp: 'break-word',
    overflow: 'hidden',
    '@mobile': {
        maxWidth: '351px',
    },
});
export const ToastMessage = styled('div', {
    fontFace: 'Montserrat',
    display: 'flex',
    alignItems: 'center',
    wordWarp: 'break-word',
    overflow: 'hidden',
    width: '75%',
});
export const ToastIcon = styled('div', {
    display: 'flex',
    alignItems: 'center',
    width: '25%',
    '&:hover': {
        cursor: 'pointer',
        opacity: '.7',
    },
});
