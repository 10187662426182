var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import React, { useState } from 'react';
import { ReactComponent as XCircle } from '@/shared/assets/icons/x-circle.svg';
import { useForm } from 'react-hook-form';
import { ToastError } from '@/widgets/ui/Toast/ToastError/ToastError';
import { yupResolver } from '@hookform/resolvers/yup';
import { restorePasswordSchema } from './schema';
import { ErrorUser } from '@/app/providers/locales/ErrorUser/ErrorUser';
import { putReqUser } from '@/shared/api/user';
import { Dialog, Icon, Space, Text, Button, Input } from '@/shared/ui';
const RestorePassword = ({ closeModal, defaultEmail }) => {
    var _a;
    const { register, getValues, formState: { errors, isValid } } = useForm({
        mode: 'onChange',
        resolver: yupResolver(restorePasswordSchema),
        defaultValues: {
            emailRestore: defaultEmail,
        },
    });
    const [step, setStep] = useState(0);
    function incrementStep() {
        setStep((prevState) => prevState + 1);
    }
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        const email = getValues().emailRestore;
        try {
            yield putReqUser('/password/reset', { email: email });
            incrementStep();
        }
        catch (e) {
            const err = e;
            ToastError(ErrorUser[err.response.data.code]);
        }
    });
    function getEmailSate() {
        var _a;
        return (_a = getValues()) === null || _a === void 0 ? void 0 : _a.emailRestore;
    }
    return (_jsx(Dialog, { open: true, css: { width: '608px' }, children: _jsxs(S.RestorePasswordContainer, { children: [_jsx(Icon, { dialogClose: true, IconItem: XCircle, onClick: () => {
                        closeModal(false);
                    } }), step === 0 &&
                    _jsxs(Space, { direction: "vertical", children: [_jsx(Text, { css: { marginBottom: '24px' }, type: "title", children: "\u0412\u043E\u0441\u0441\u0442\u0430\u043D\u043E\u0432\u043B\u0435\u043D\u0438\u0435 \u043F\u0430\u0440\u043E\u043B\u044F" }), _jsx(Text, { css: { marginBottom: '24px' }, type: "default", children: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0430\u0434\u0440\u0435\u0441 \u044D\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u043E\u0439 \u043F\u043E\u0447\u0442\u044B, \u043A\u043E\u0442\u043E\u0440\u044B\u0439 \u0432\u044B \u0443\u043A\u0430\u0437\u044B\u0432\u0430\u043B\u0438 \u043F\u0440\u0438 \u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u0438. \u041C\u044B \u0432\u044B\u0448\u043B\u0435\u043C \u0438\u043D\u0441\u0442\u0440\u0443\u043A\u0446\u0438\u0438 \u0434\u043B\u044F \u0432\u043E\u0441\u0441\u0442\u0430\u043D\u043E\u0432\u043B\u0435\u043D\u0438\u044F \u043F\u0430\u0440\u043E\u043B\u044F." }), _jsxs(Space, { css: { marginBottom: '24px' }, direction: "vertical", children: [_jsx(Text, { type: "labelName", children: "Email" }), _jsx(Input, Object.assign({ autocomplete: "none", error: (_a = errors === null || errors === void 0 ? void 0 : errors.emailRestore) === null || _a === void 0 ? void 0 : _a.message }, register('emailRestore'), { placeholder: "Email", typeInput: "default" }))] }), _jsx("div", { children: _jsx(Button, { disabled: !isValid, onClick: onSubmit, type: "primary", children: "\u0412\u043E\u0441\u0441\u0442\u0430\u043D\u043E\u0432\u0438\u0442\u044C \u043F\u0430\u0440\u043E\u043B\u044C" }) })] }), step === 1 &&
                    _jsxs(Space, { direction: "vertical", children: [_jsx(Text, { css: { marginBottom: '24px' }, type: "title", children: "\u041F\u0440\u043E\u0432\u0435\u0440\u044C\u0442\u0435 \u043F\u043E\u0447\u0442\u0443" }), _jsxs(Text, { css: { marginBottom: '24px' }, type: "default", children: ["\u041C\u044B \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u043B\u0438 \u0432\u0430\u043C \u0438\u043D\u0441\u0442\u0440\u0443\u043A\u0446\u0438\u044E \u0434\u043B\u044F \u0432\u043E\u0441\u0441\u0442\u0430\u043D\u043E\u0432\u043B\u0435\u043D\u0438\u044F \u043F\u0430\u0440\u043E\u043B\u044F \u043D\u0430 \u0430\u0434\u0440\u0435\u0441 ", _jsx("strong", { children: getEmailSate() })] }), _jsxs(Space, { align: "center", content: "between", children: [_jsx(Space, { children: _jsx(Button, { onClick: () => closeModal(false), type: "primary", children: "\u0412\u043E\u0439\u0442\u0438" }) }), _jsx(S.NavLinkSupport, { target: "_blank", href: "mailto:support@harvester.so", children: _jsx(Text, { decoration: "underline", css: { margin: '0 6px', fontSize: '14px', fontWeight: '600', color: '#414BB2' }, children: "\u0421\u0432\u044F\u0437\u0430\u0442\u044C\u0441\u044F \u0441 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u043E\u0439" }) })] })] })] }) }));
};
export { RestorePassword };
