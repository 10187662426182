import { jsx as _jsx } from "react/jsx-runtime";
import * as S from './style';
const Icon = ({ IconItem, fill, stroke, rotate, sideBarHover, active, css, dialogClose, hoverSetting, onClick, }) => {
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };
    return (_jsx(S.Icon, { rotate: rotate, hoverSetting: hoverSetting, onClick: handleClick, dialogClose: dialogClose, css: css, active: active, sideBarHover: sideBarHover, children: _jsx(IconItem, { css: css, stroke: stroke, fill: fill ? fill : 'none' }) }));
};
export { Icon };
