import { styled } from '@/app/styles/mainStyles';
export const StyledItem = styled('div', {
    padding: '4px 8px',
    margin: '0 6px',
    fontFamily: '$nunito',
    borderRadius: '8px 8px 8px 8px',
    outline: 'none',
    transition: '0.2s ease-in-out',
    color: '$active',
});
export const StyledItemText = styled('span', {
    fontWeight: 600,
    fontFamily: '$nunito',
    color: '$active',
});
