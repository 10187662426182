import { styled } from '@/app/styles/mainStyles';
export const ContainerLoader = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
});
export const ContainerLoaderContent = styled('div', {
    padding: '10px 14px',
    display: 'flex',
    alignItems: 'center',
    background: '$suspenseLoader',
    borderRadius: '16px',
});
