import { MarketPlaceEnum } from '@/shared/hooks/useFilter/types';
import { v4 as uuidv4 } from 'uuid';
const getCalculateItemValue = (calculatingItem) => {
    if (calculatingItem === 'CALCULATION_ITEM_SAVED') {
        return 1;
    }
    if (calculatingItem === 'CALCULATION_ITEM_NEW_ITEM') {
        return 2;
    }
    if (calculatingItem === 'CALCULATION_ITEM_CUSTOM') {
        return 3;
    }
};
const getDeliveryTypes = (deliveryType) => {
    if (deliveryType === 'DELIVERY_TYPE_FBS') {
        return 4;
    }
    if (deliveryType === 'DELIVERY_TYPE_OWN_DELIVERY') {
        return 5;
    }
    if (deliveryType === 'DELIVERY_TYPE_FBO') {
        return 1;
    }
    if (deliveryType === 'DELIVERY_TYPE_FBS_CHECKOUT_POINT') {
        return 2;
    }
    if (deliveryType === 'DELIVERY_TYPE_FBS_COURIER') {
        return 3;
    }
};
const getMarketplace = (marketplace) => {
    if (marketplace === MarketPlaceEnum.Wildberries) {
        return 2;
    }
    if (marketplace === MarketPlaceEnum.Ozon) {
        return 1;
    }
};
const getFbwType = (fbwType) => {
    if (fbwType === ' FBW_TYPE_KOROB') {
        return 1;
    }
    if (fbwType === 'FBW_TYPE_MONOPALLET') {
        return 2;
    }
    return 0;
};
const getFbwOrFbsId = (marketplace, item) => {
    if (marketplace === MarketPlaceEnum.Wildberries) {
        return { fbwId: item.request.delivery.fbwId || '' };
    }
    if (marketplace === MarketPlaceEnum.Ozon) {
        return { fbsId: item.request.delivery.fbsId || '' };
    }
};
export const prepareDataForItem = (item) => {
    var _a;
    const fbsOrFbw = getFbwOrFbsId((_a = item === null || item === void 0 ? void 0 : item.request) === null || _a === void 0 ? void 0 : _a.marketplace, item);
    const obj = {
        goodId: item === null || item === void 0 ? void 0 : item.goodId,
        calculateId: uuidv4(),
        calculatingItem: getCalculateItemValue(item === null || item === void 0 ? void 0 : item.request.calculatingItem) || (item === null || item === void 0 ? void 0 : item.request.calculatingItem),
        stats: item === null || item === void 0 ? void 0 : item.request.stats,
        costPrice: item === null || item === void 0 ? void 0 : item.request.costPrice,
        expensesMarketplace: item === null || item === void 0 ? void 0 : item.request.expensesMarketplace,
        finalPrice: item === null || item === void 0 ? void 0 : item.request.finalPrice,
        salesCountPerMonth: item === null || item === void 0 ? void 0 : item.request.salesCountPerMonth,
        marketplace: getMarketplace(item === null || item === void 0 ? void 0 : item.request.marketplace),
        deliveryPrice: item === null || item === void 0 ? void 0 : item.mpExpenses,
        delivery: Object.assign(Object.assign({ name: '', deliveryType: getDeliveryTypes(item === null || item === void 0 ? void 0 : item.request.delivery.deliveryType), fbwStoragePeriod: item === null || item === void 0 ? void 0 : item.request.delivery.fbwStoragePeriod, fbwType: getFbwType(item === null || item === void 0 ? void 0 : item.request.delivery.fbwType), fbwCountOnPallet: item === null || item === void 0 ? void 0 : item.request.delivery.fbwCountOnPallet }, fbsOrFbw), { countShipments: item === null || item === void 0 ? void 0 : item.request.delivery.countShipments, deliveryPrice: item === null || item === void 0 ? void 0 : item.request.delivery.deliveryPrice }),
    };
    return obj;
};
