import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from '@/shared/ui/Dialog/Dialog';
import * as S from './style';
import { Text } from '@/shared/ui/Text/Text';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { formatTime } from '@/shared/utils/formatTime';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { setPaymentHistory } from '@/entity/ModalsEntity/modals.slice';
var TranslateStatusEnum;
(function (TranslateStatusEnum) {
    TranslateStatusEnum["PAYMENT_STATUS_PENDING"] = "\u0412 \u043E\u0436\u0438\u0434\u0430\u043D\u0438\u0438";
    TranslateStatusEnum["PAYMENT_STATUS_CANCELED"] = "\u041E\u0442\u043C\u0435\u043D\u0435\u043D";
    TranslateStatusEnum["PAYMENT_STATUS_SUCCEEDED"] = "\u0423\u0441\u043F\u0435\u0448\u043D\u043E";
})(TranslateStatusEnum || (TranslateStatusEnum = {}));
const PaymentsHistory = ({ open }) => {
    const dispatch = useTypedDispatch();
    const tariffs = useTypedSelector((state) => state.paymentReducer.tariffs);
    const payments = useTypedSelector((state) => state.paymentReducer.paymentList.payments);
    const findTariff = (tariffId) => {
        const find = tariffs === null || tariffs === void 0 ? void 0 : tariffs.find((el) => el.id === tariffId);
        if (find) {
            return find;
        }
    };
    const handleClose = () => {
        dispatch(setPaymentHistory(false));
    };
    return (_jsxs(Dialog, { css: {
            maxWidth: '90vw',
            width: '90vw',
        }, open: open, closeIcon: true, onOpenChange: handleClose, children: [_jsx(Text, { css: { marginBottom: '24px' }, type: "preTitle", children: "\u0418\u0441\u0442\u043E\u0440\u0438\u044F \u043F\u043B\u0430\u0442\u0435\u0436\u0435\u0439" }), _jsxs(S.PaymentsHistoryDescription, { children: [_jsx(Text, { css: { width: '15%' }, type: "textEllipsis", children: "\u0414\u0430\u0442\u0430" }), _jsx(Text, { css: { width: '20%' }, type: "textEllipsis", children: "\u0421\u0435\u0440\u0432\u0438\u0441" }), _jsx(Text, { css: { width: '10%' }, type: "textEllipsis", children: "\u0422\u0430\u0440\u0438\u0444" }), _jsx(Text, { css: { width: '20%' }, type: "textEllipsis", children: "\u041D\u043E\u043C\u0435\u0440" }), _jsx(Text, { css: { width: '20%' }, type: "textEllipsis", children: "\u0421\u043F\u043E\u0441\u043E\u0431 \u043E\u043F\u043B\u0430\u0442\u044B" }), _jsx(Text, { css: { width: '15%' }, type: "textEllipsis", children: "\u0421\u0442\u0430\u0442\u0443\u0441" })] }), _jsx(S.PaymentsHistoryWrapper, { children: payments === null || payments === void 0 ? void 0 : payments.map((el) => {
                    var _a, _b, _c;
                    return (_jsxs(S.PaymentsHistoryContainer, { children: [_jsx(Text, { type: "default", css: { width: '15%' }, children: formatTime(el.createdAt) }), _jsx(Text, { type: "default", css: { width: '20%' }, children: (_a = findTariff(el.tariffId)) === null || _a === void 0 ? void 0 : _a.name }), _jsx(Text, { type: "default", css: { width: '10%' }, children: (_b = findTariff(el.tariffId)) === null || _b === void 0 ? void 0 : _b.duration }), _jsx(Text, { type: "default", css: { width: '20%' }, children: el.id }), _jsxs(Text, { type: "default", css: { width: '20%' }, children: ["**** ", (_c = el.additionalData.paymentMethod) === null || _c === void 0 ? void 0 : _c.card.last4] }), _jsx(Text, { type: "default", css: { width: '15%' }, children: TranslateStatusEnum[el.status] })] }));
                }) })] }));
};
export { PaymentsHistory };
