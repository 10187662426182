import { styled } from '@/app/styles/mainStyles';
export const TooltipContainer = styled('div', {
    background: 'white',
    borderRadius: '9px',
    padding: '19px 13px',
    outline: 'none !important',
    boxShadow: '0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06)',
    '&:hover, &:focus': {
        outline: 'none !important',
    },
});
export const TooltipDivColor = styled('div', {
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    marginRight: '12px',
});
