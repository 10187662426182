var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import { getReqUser, patchReqUser, postReqUser, removeReqUser } from '@/shared/api/user';
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
import { ToastSuccess } from '@/widgets/ui/Toast/ToastSuccess/ToastSuccess';
import { clearStore } from '@/entity/MailingShopsEntity/shops.slice';
import { clearMailing } from '@/entity/MailingListEntity/mailing.slice';
import { clearSavedProducts } from '@/entity/ListSavedProductsEntity/savedProducts.slice';
import { clearProductInfo } from '@/entity/ProductEntity/product.slice';
import { clearCalculateState } from '@/entity/CalculatorEntity/calculator.slice';
import { sendAmplitudeData, setAmplitudeUserId } from '@/shared/utils/amplitude/amplitude';
import { clearPaymentState } from '@/entity/PaymentEntity/payment.slice';
import { displayError } from '@/shared/utils/displayError/displayError';
const cookies = new Cookies();
const initialState = {
    profile: null,
    token: cookies.get('token') || '',
    profileStatus: BASE_FETCH_STATUS,
    userLoginStatus: BASE_FETCH_STATUS,
    notification: false,
    isFillProfile: false,
};
const getProfile = createAsyncThunk('profile/getProfile', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getReqUser('/user');
        setAmplitudeUserId(resp.data.id);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response.status === 401) {
            cookies.remove('token', { path: '/' });
            return;
        }
        if (err.response) {
            displayError(err, 'user');
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const clearStateStore = createAsyncThunk('clearStore', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    thunkAPI.dispatch(clearStore());
    thunkAPI.dispatch(clearMailing());
    thunkAPI.dispatch(clearSavedProducts());
    thunkAPI.dispatch(clearProductInfo());
    thunkAPI.dispatch(clearCalculateState());
    thunkAPI.dispatch(clearPaymentState());
}));
const registerUser = createAsyncThunk('profile/register', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield postReqUser('/user', payload);
        sendAmplitudeData('reg_page_reg_btn_clicked', {
            isOk: true,
        });
        return response.data;
    }
    catch (error) {
        const err = error;
        sendAmplitudeData('reg_page_reg_btn_clicked', {
            isOk: false,
        });
        if (err.response) {
            displayError(err, 'user');
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const resendVerifyCode = createAsyncThunk('profile/resendVerifyCode', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield postReqUser('code/send', { email: payload.email, verifyType: payload.verifyType });
        ToastSuccess('Код успешно отправлен!');
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err, 'user');
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const sendCodeToVerifyNumber = createAsyncThunk('profile/sendCodeToVerifyNumber', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const data = yield postReqUser('phone/number', payload);
        return data.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err, 'user');
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const verifyPhoneNumber = createAsyncThunk('profile/verifyPhoneNumber', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const data = yield postReqUser('phone/code', payload);
        return data.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err, 'user');
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const updateUserInfo = createAsyncThunk('profile/updateUserInfo', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const data = yield patchReqUser('user_information', payload);
        return data.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err, 'user');
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const updateUserProduct = createAsyncThunk('profile/updateUserProduct', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield patchReqUser('product', payload);
        return payload.product;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err, 'user');
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const verifyUserEmail = createAsyncThunk('profile/verifyUserEmail', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield postReqUser('verification/email', payload);
        return response.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err, 'user');
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const logoutUser = createAsyncThunk('profile/logout', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield removeReqUser('/logout');
        cookies.remove('token', { path: '/' });
        thunkAPI.dispatch(clearStateStore());
        window.location.reload();
        return response;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            cookies.remove('token', { path: '/' });
            thunkAPI.dispatch(clearStateStore());
            if (err.response.status === 401) {
                window.location.reload();
                return;
            }
            displayError(err, 'user');
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const login = createAsyncThunk('profile/login', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield postReqUser('/login', payload, {});
        cookies.set('token', response.headers['grpc-metadata-authorization'], { maxAge: 72 * 60 * 60, path: '/' });
        sendAmplitudeData('auth_page_login_btn_clicked', {
            isOk: true,
        });
        return response.headers['grpc-metadata-authorization'];
    }
    catch (error) {
        const err = error;
        sendAmplitudeData('auth_page_login_btn_clicked', {
            isOk: false,
        });
        if (err.response) {
            displayError(err, 'user');
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const profileSlice = createSlice({
    name: 'profileSlice',
    initialState,
    reducers: {
        setToken: (state) => {
            state.token = cookies.get('token') || '';
        },
        setNotification: (state, { payload }) => {
            state.notification = payload;
        },
        setIsFillProfile: (state, { payload }) => {
            state.isFillProfile = payload;
        },
        setUserKindDefault: (state) => {
            state.profile.kind = 'USER_KIND_DEFAULT';
        },
        updateUserProducts: (state, { payload }) => {
            var _a;
            (_a = state === null || state === void 0 ? void 0 : state.profile) === null || _a === void 0 ? void 0 : _a.products.push(payload);
        },
        clearUser: (state) => {
            state.profile = null;
            state.token = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProfile.pending, (state) => {
            state.profileStatus = START_FETCH_STATUS;
        });
        builder.addCase(getProfile.rejected, (state, { payload }) => {
            state.profileStatus.isLoading = false;
            state.profileStatus.error = payload;
        });
        builder.addCase(getProfile.fulfilled, (state, { payload }) => {
            state.profileStatus.isLoading = false;
            state.isFillProfile = payload.isVerifyPhoneNumber;
            state.profile = payload;
        });
        builder.addCase(login.pending, (state) => {
            state.userLoginStatus = START_FETCH_STATUS;
        });
        builder.addCase(login.rejected, (state, { payload }) => {
            state.userLoginStatus.isLoading = false;
            state.userLoginStatus.error = payload;
        });
        builder.addCase(login.fulfilled, (state, { payload }) => {
            state.userLoginStatus.isLoading = false;
            state.token = payload;
        });
        builder.addCase(logoutUser.pending, (state) => {
            state.profileStatus = START_FETCH_STATUS;
        });
        builder.addCase(logoutUser.rejected, (state, { payload }) => {
            state.profileStatus.isLoading = false;
            state.profileStatus.error = payload;
        });
        builder.addCase(logoutUser.fulfilled, (state) => {
            state.profileStatus.isLoading = false;
            state.token = null;
            state.profile = null;
        });
        builder.addCase(registerUser.pending, (state) => {
            state.profileStatus = START_FETCH_STATUS;
        });
        builder.addCase(registerUser.rejected, (state, { payload }) => {
            state.profileStatus.isLoading = false;
            state.profileStatus.error = payload;
        });
        builder.addCase(registerUser.fulfilled, (state) => {
            state.profileStatus.isLoading = false;
        });
        builder.addCase(verifyUserEmail.pending, (state) => {
            state.profileStatus = START_FETCH_STATUS;
        });
        builder.addCase(verifyUserEmail.rejected, (state, { payload }) => {
            state.profileStatus.isLoading = false;
            state.profileStatus.error = payload;
        });
        builder.addCase(verifyUserEmail.fulfilled, (state) => {
            state.profileStatus.isLoading = false;
        });
        builder.addCase(updateUserProduct.pending, (state) => {
            state.profileStatus = START_FETCH_STATUS;
        });
        builder.addCase(updateUserProduct.rejected, (state, { payload }) => {
            state.profileStatus.isLoading = false;
            state.profileStatus.error = payload;
        });
        builder.addCase(updateUserProduct.fulfilled, (state, { payload }) => {
            var _a;
            state.profileStatus.isLoading = false;
            if ((_a = state === null || state === void 0 ? void 0 : state.profile) === null || _a === void 0 ? void 0 : _a.products) {
                state.profile.products.push(payload);
            }
        });
        builder.addCase(updateUserInfo.pending, (state) => {
            state.profileStatus = START_FETCH_STATUS;
        });
        builder.addCase(updateUserInfo.rejected, (state, { payload }) => {
            state.profileStatus.isLoading = false;
            state.profileStatus.error = payload;
        });
        builder.addCase(updateUserInfo.fulfilled, (state) => {
            state.profileStatus.isLoading = false;
        });
        builder.addCase(clearStateStore.fulfilled, (state) => {
            state.profileStatus.isLoading = false;
            state.notification = false;
        });
    },
});
export default profileSlice.reducer;
export const { setToken, setUserKindDefault, clearUser, setNotification, updateUserProducts, setIsFillProfile, } = profileSlice.actions;
export { updateUserProduct, sendCodeToVerifyNumber, verifyPhoneNumber, getProfile, login, logoutUser, registerUser, resendVerifyCode, clearStateStore, updateUserInfo, };
