import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Dialog } from '@/shared/ui/Dialog/Dialog';
import { Text } from '@/shared/ui/Text/Text';
import { Space } from '@/shared/ui/Space/Space';
import { Button } from '@/shared/ui/Button/Button';
import { useState } from 'react';
import { SaveItems } from '@/features/ui/Dialogs/LimitFlow/LimitSavedProducts/SaveItems';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { setLimitSavedProducts, setPaymentModal } from '@/entity/ModalsEntity/modals.slice';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
import { removeAllUserProducts } from '@/entity/ListSavedProductsEntity/savedProducts.slice';
const LimitSavedProducts = ({ open, isDesktop }) => {
    const products = useTypedSelector((state) => state.savedProducts.products);
    const dispatch = useTypedDispatch();
    const [step, setStep] = useState(1);
    const incrementStep = () => {
        sendAmplitudeData('downgrate_to_lite_modal_view_choose_goods_btn_clicked');
        sendAmplitudeData('delete_goods_modal_view_shown');
        setStep((prevState) => prevState + 1);
    };
    const handleClose = () => {
        dispatch(setLimitSavedProducts(true));
    };
    const handleRemoveItems = () => {
        sendAmplitudeData('downgrate_to_lite_modal_view_delete_btn_clicked');
        dispatch(removeAllUserProducts());
    };
    const handleOpenPaymentModal = () => {
        sendAmplitudeData('downgrate_to_lite_modal_view_pro_btn_clicked');
        sendAmplitudeData('delete_goods_modal_view_keep_btn_clicked');
        dispatch(setPaymentModal(true));
    };
    const decrementStep = () => {
        sendAmplitudeData('delete_goods_modal_view_closed');
        setStep((prevState) => prevState - 1);
    };
    return (_jsxs(Dialog, { open: open, outside: false, css: {
            minWidth: step === 2 ? '80vw' : '',
            maxWidth: step === 2 ? '80vw' : '',
            height: step === 2 ? '80vh' : '',
        }, children: [step === 1 &&
                _jsxs(_Fragment, { children: [_jsx(Text, { css: { marginBottom: '24px' }, type: "preTitle", children: "\u041F\u0440\u0435\u0432\u044B\u0448\u0435\u043D \u043B\u0438\u043C\u0438\u0442" }), _jsxs(Text, { css: { marginBottom: '24px' }, type: "default", children: ["\u0421 \u0441\u0435\u0433\u043E\u0434\u043D\u044F\u0448\u043D\u0435\u0433\u043E \u0434\u043D\u044F \u0432\u044B \u043F\u0435\u0440\u0435\u0448\u043B\u0438 \u043D\u0430 \u0442\u0430\u0440\u0438\u0444 Lite. \u0423 \u0432\u0430\u0441 ", products.length, " \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u043D\u044B\u0445 \u0442\u043E\u0432\u0430\u0440\u043E\u0432 \u0438\u0437 30 \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u044B\u0445. \u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u0432\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0442\u043E\u0432\u0430\u0440\u044B, \u043A\u043E\u0442\u043E\u0440\u044B\u0435 \u0432\u044B \u0445\u043E\u0442\u0438\u0442\u0435 \u043E\u0441\u0442\u0430\u0432\u0438\u0442\u044C."] }), _jsxs(Space, { content: "between", children: [_jsx(Button, { onClick: handleRemoveItems, css: { borderRadius: '28px' }, type: "delete", children: "\u0414\u0430, \u0443\u0434\u0430\u043B\u0438\u0442\u044C" }), _jsxs(Space, { children: [_jsx(Button, { onClick: incrementStep, css: { marginRight: '12px' }, type: "default", children: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0442\u043E\u0432\u0430\u0440\u044B" }), _jsx(Button, { onClick: handleOpenPaymentModal, type: "primary", children: "\u041E\u0441\u0442\u0430\u0432\u0438\u0442\u044C \u0432\u0441\u0435 \u0442\u043E\u0432\u0430\u0440\u044B" })] })] })] }), step === 2 && _jsx(SaveItems, { products: products, handleOpenPaymentModal: handleOpenPaymentModal, decrementStep: decrementStep, isDesktop: isDesktop, handleClose: handleClose })] }));
};
export { LimitSavedProducts };
