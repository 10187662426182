import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, Space, Text } from '@/shared/ui';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { TariffsNameEnum } from '@/entity/PaymentEntity/types';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { setPaymentModal, setSaveProductLimit } from '@/entity/ModalsEntity/modals.slice';
import { useEffect } from 'react';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
const LimitSaveProduct = ({ open }) => {
    const dispatch = useTypedDispatch();
    const currentSub = useTypedSelector((state) => { var _a; return (_a = state.paymentReducer.userSubscription) === null || _a === void 0 ? void 0 : _a.tariffName; });
    const handleBuySub = () => {
        sendAmplitudeData('calc_limit_modal_view_increase_limit_btn_clicked');
        dispatch(setPaymentModal(true));
    };
    const handleClose = () => {
        sendAmplitudeData('');
        sendAmplitudeData('calc_limit_modal_view_ok_btn_clicked');
        dispatch(setSaveProductLimit(false));
    };
    const getUi = () => {
        if (currentSub === TariffsNameEnum.Lite) {
            return (_jsxs(Space, { size: "default", content: "flexEnd", children: [_jsx(Text, { onClick: handleClose, type: "select", children: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E" }), _jsx(Button, { onClick: handleBuySub, type: "primary", children: "\u041F\u043E\u0432\u044B\u0441\u0438\u0442\u044C \u043B\u0438\u043C\u0438\u0442\u044B" })] }));
        }
        if (currentSub === TariffsNameEnum.Pro) {
            return (_jsx(Space, { content: "flexEnd", children: _jsx(Button, { onClick: handleClose, children: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E" }) }));
        }
    };
    useEffect(() => {
        sendAmplitudeData('calc_limit_modal_view_shown', {
            isUpsaleShown: currentSub === TariffsNameEnum.Pro,
        });
    }, []);
    return (_jsxs(Dialog, { open: open, closeIcon: true, children: [_jsx(Text, { css: { marginBottom: '24px' }, type: "preTitle", children: "\u041B\u0438\u043C\u0438\u0442 \u0438\u0441\u0447\u0435\u0440\u043F\u0430\u043D" }), _jsx(Text, { css: { marginBottom: '56px' }, type: "default", children: "\u0412\u044B \u0438\u0441\u0447\u0435\u0440\u043F\u0430\u043B\u0438 \u043B\u0438\u043C\u0438\u0442 \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u043D\u044B\u0445 \u0442\u043E\u0432\u0430\u0440\u043E\u0432 \u0432 \u044D\u0442\u043E\u043C \u043C\u0435\u0441\u044F\u0446\u0435. \u0412\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u0443\u0434\u0430\u043B\u0438\u0442\u044C \u0441\u0442\u0430\u0440\u044B\u0435 \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u043D\u044B\u0435 \u0442\u043E\u0432\u0430\u0440\u044B." }), getUi()] }));
};
export { LimitSaveProduct };
