import { styled } from '@/app/styles/mainStyles';
import { keyframes } from '@stitches/react';
import * as AlertDialogPrimitive from '@radix-ui/react-dialog';
export { Root, Trigger, } from '@radix-ui/react-dialog';
const overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
});
const StyledOverlay = styled('div', {
    zIndex: 3,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.45)',
    position: 'fixed',
    pointerEvents: 'auto',
    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
    },
});
const StyledPortal = styled(AlertDialogPrimitive.Portal, {
    zIndex: 5,
    variants: {
        view: {
            fullView: {
                overflow: 'auto',
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'none',
                '&: > div': {
                    width: '400px',
                    position: 'relative',
                    margin: '10 auto',
                    padding: '5px 20px 13px 20px',
                    borderRadius: '10px',
                    background: '#fff',
                },
            },
        },
    },
});
const StyledContent = styled(AlertDialogPrimitive.Content, {
    zIndex: 4,
    backgroundColor: '#F7F7F7',
    overflow: 'auto',
    borderRadius: 8,
    fontFamily: '$nunito',
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.2)',
    position: 'fixed',
    transform: 'translate(-50%, -50%)',
    padding: '1.5rem',
    fontSize: '.875rem',
    lineHeight: '1.375rem',
    '@desktop': {
        maxWidth: '550px',
        maxHeight: '85vh',
        top: '50%',
        left: '50%',
        minWidth: 300,
    },
    '&:focus': { outline: 'none' },
    '@mobile': {
        width: '88vw !important',
        maxHeight: '90vh !important',
        top: '40%',
        left: '50%',
    },
});
const StyledTitle = styled(AlertDialogPrimitive.Title, {
    margin: 0,
    color: 'black',
    fontSize: 24,
    fontWeight: 500,
    '@tablet': {
        fontSize: 20,
    },
});
export const Overlay = StyledOverlay;
export const Content = StyledContent;
export const Portal = StyledPortal;
export const Title = StyledTitle;
export const Close = styled('div', {
    position: 'absolute',
    top: '.75rem',
    right: '.75rem',
    cursor: 'pointer',
    'svg > g': {
        stroke: '$textDark',
    },
});
