import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect } from 'react';
import { ReactComponent as CloseIcon } from '@/shared/assets/icons/x-circle.svg';
import { Root, Trigger, Portal, Overlay, Content, Close, } from './style';
export const Dialog = forwardRef(({ open, onOpenChange, trigger, children, closeIcon, id, css, className, outside, }, ref) => {
    const close = () => {
        if (onOpenChange) {
            onOpenChange(false);
        }
    };
    const onCloseClickOutside = () => {
        if (outside) {
            return;
        }
        if (onOpenChange) {
            onOpenChange(false);
        }
    };
    const handleClickOutside = (event) => {
        if (outside) {
            event.preventDefault();
        }
    };
    useEffect(() => {
        const body = document.querySelector('body');
        if (open) {
            body === null || body === void 0 ? void 0 : body.classList.add('overflow-hidden');
            return;
        }
        return () => {
        };
    }, [open]);
    return (_jsxs(Root, { open: open, onOpenChange: onOpenChange, children: [trigger && _jsx(Trigger, { asChild: true, children: trigger }), _jsxs(Portal, { children: [_jsx(Overlay, { onClick: onCloseClickOutside }), _jsxs(Content, { id: id, onCloseAutoFocus: handleClickOutside, onPointerDownOutside: handleClickOutside, onInteractOutside: handleClickOutside, css: css, className: className, children: [children, closeIcon && (_jsx(Close, { "data-testid": "closeIcon", onClick: () => {
                                    close();
                                }, children: _jsx(CloseIcon, {}) }))] })] })] }));
});
