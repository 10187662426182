import { createSlice } from '@reduxjs/toolkit';
const getInitStateForMailing = () => {
    const mailingsModule = localStorage.getItem('mailingsSideBarModule');
    return !(mailingsModule && !JSON.parse(mailingsModule));
};
const getInitStateForCalculator = () => {
    const calculatorSideBarModule = localStorage.getItem('calculatorSideBarModule');
    return !(calculatorSideBarModule && !JSON.parse(calculatorSideBarModule));
};
const getInitStateForMain = () => {
    const mainSideBarModule = localStorage.getItem('mainSideBarModule');
    return !(mainSideBarModule && !JSON.parse(mainSideBarModule));
};
const initialState = {
    mailingsSideBarModule: getInitStateForMailing(),
    calculatorSideBarModule: getInitStateForCalculator(),
    mainSideBarModule: getInitStateForMain(),
};
const sidebarSlice = createSlice({
    name: 'sidebarSlice',
    initialState,
    reducers: {
        setMailingsSideBarModule: (state, { payload }) => {
            if (!payload) {
                localStorage.setItem('mailingsSideBarModule', `${!state.mailingsSideBarModule}`);
                state.mailingsSideBarModule = !state.mailingsSideBarModule;
            }
            else {
                localStorage.setItem('mailingsSideBarModule', `${payload.value}`);
                state.mailingsSideBarModule = payload.value;
            }
        },
        setCalculatorSideBarModule: (state, { payload }) => {
            if (!payload) {
                localStorage.setItem('calculatorSideBarModule', `${!state.calculatorSideBarModule}`);
                state.calculatorSideBarModule = !state.calculatorSideBarModule;
            }
            else {
                localStorage.setItem('calculatorSideBarModule', `${payload.value}`);
                state.calculatorSideBarModule = payload.value;
            }
        },
        setMainSideBarModule: (state, { payload }) => {
            if (!payload) {
                localStorage.setItem('mainSideBarModule', `${!state.mainSideBarModule}`);
                state.mainSideBarModule = !state.mainSideBarModule;
            }
            else {
                localStorage.setItem('mainSideBarModule', `${payload}`);
                state.mainSideBarModule = payload;
            }
        },
    },
});
export default sidebarSlice.reducer;
export const { setMainSideBarModule, setMailingsSideBarModule, setCalculatorSideBarModule, } = sidebarSlice.actions;
