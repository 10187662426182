import { styled } from '@/app/styles/mainStyles';
export const Text = styled('span', {
    fontSize: '14px',
    display: 'block',
    color: '$textBlackColor',
    fontWeight: 500,
    fontFamily: '$nunito',
    variants: {
        type: {
            error: {
                color: '$error',
                fontWeight: 400,
                fontSize: '13px',
                lineHeight: '12px',
            },
            default: {
                display: 'block',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '22px',
            },
            pageTitle: {
                display: 'block',
                fontWeight: 900,
                fontFamily: '$inter',
                fontSize: '32px',
                lineHeight: '39px',
            },
            select: {
                textDecoration: 'underline',
                color: '$activeBackground',
                textDecorationColor: '$activeBackground',
                cursor: 'pointer',
                fontSize: '14px',
                fontWeight: '600',
            },
            title: {
                display: 'block',
                fontWeight: 700,
                fontFamily: '$inter',
                fontSize: '28px',
                lineHeight: '34px',
            },
            mailingText: {
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
                '-webkit-line-clamp': 3,
                fontSize: '14px',
                fontWeight: 500,
                overflow: 'hidden',
                lineBreak: 'anywhere',
                textOverflow: 'ellipsis',
                lineHeight: '14px',
            },
            preTitle: {
                display: 'block',
                fontWeight: 700,
                fontFamily: '$inter',
                fontSize: '24px',
                lineHeight: '100%',
            },
            labelInput: {
                fontFamily: '$inter',
                fontWeight: '700',
                fontSize: '17px',
                lineHeight: '21px',
                marginBottom: '8px',
            },
            logisticCard: {
                fontFamily: '$inter',
                fontWeight: '600',
                fontSize: '17px',
                lineHeight: '25px',
            },
            modalText: {
                display: 'block',
                color: '$modalColor',
                fontSize: '18px',
            },
            labelText: {
                fontWeight: 400,
                fontSize: '13px',
                lineHeight: '12px',
                color: '$labelColor',
            },
            labelTextStrong: {
                fontWeight: 700,
                fontSize: '21px',
                lineHeight: '25px',
                fontFamily: '$nunito',
            },
            labelName: {
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '22px',
                fontFamily: '$nunito',
                marginBottom: '4px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
            textEllipsis: {
                fontWeight: 400,
                fontSize: '13px',
                lineHeight: '12px',
                color: '$labelColor',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
        algin: {
            center: {
                textAlign: 'center',
            },
        },
        decoration: {
            underline: {
                textDecoration: 'underline',
            },
            dashed: {
                borderBottom: '1px dashed $black',
                paddingBottom: '2px',
                color: '$black',
            },
        },
        hover: {
            true: {
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        ellipse: {
            true: {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
        },
        colorText: {
            error: {
                color: '$errorRed',
            },
            true: {},
            price: {
                color: '$acceptButton',
            },
            darkBlue: {
                color: '$activeBackground',
            },
            errorLogistic: {
                color: '$logisticError',
            },
            errorLight: {
                color: '$errorLight',
            },
        },
        active: {
            true: {
                color: '$white !important',
            },
        },
        error: {
            true: {
                textDecoration: 'underline',
                textDecorationColor: '$error',
            },
        },
        activeUnderline: {
            true: {
                borderBottom: '1px dashed $activeBackground',
                color: '$activeBackground',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        disable: {
            true: {
                color: '$disableColor',
            },
        },
    },
});
