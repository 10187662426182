import { styled } from '@/app/styles/mainStyles';
import { Text } from '@/shared/ui/Text/style';
export const FilterParamsContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    '@desktop': {},
    '@mobile': {
        width: '100%',
    },
});
export const FilterContainer = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
});
export const SortContainer = styled('div', {
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    alignItems: 'center',
});
export const SortButtonContainer = styled('div', {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 8px',
    cursor: 'pointer',
    '&:first-child': {
        padding: '8px 8px 8px 0',
    },
    variants: {
        active: {
            true: {
                background: '$backgroundFilteredButton',
                borderRadius: '8px',
            },
        },
    },
});
export const TextForFilter = styled(Text, {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    '&:hover': {
        '&[data-active=false]': {
            color: '$active',
            borderBottom: '1px dashed $active',
            paddingBottom: '2px',
        },
    },
    variants: {
        active: {
            true: {
                color: '$activeBackground',
                borderBottom: 'none',
                paddingBottom: '0',
            },
        },
    },
});
export const FilterButtonContainer = styled('div', {
    marginRight: '20px',
});
