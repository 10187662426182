import { styled } from '@/app/styles/mainStyles';
import { Text } from '@/shared/ui/Text/style';
export const ItemText = styled(Text, {});
export const ItemMobileWrapper = styled('div', {
    background: '#F7F7F7',
    borderRadius: '8px',
    padding: '16px',
    margin: '8px 0 8px 0',
    variants: {
        active: {
            true: {
                background: '$activeBackground',
                boxShadow: 'none',
                [`${ItemText}`]: {
                    color: '$white',
                },
            },
        },
    },
});
export const ItemMobileContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});
