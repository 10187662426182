import { jsx as _jsx } from "react/jsx-runtime";
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { getProductInfo } from '@/entity/ProductEntity/product.slice';
import { useMemo } from 'react';
import { Graph } from '@/widgets/ui/Graph/Graph';
import { formatStatsForGraph } from '@/shared/utils/formatStatsForGraph';
import { ContainerLoader } from '@/shared/ui/ContainerLoader/ContainerLoader';
const TooltipGraph = ({ id }) => {
    const dispatch = useTypedDispatch();
    const product = useTypedSelector((state) => state.productReducer.product);
    const productInfoStats = useTypedSelector((state) => state.productReducer.productInfo);
    const isLoadingProduct = useTypedSelector((state) => state.productReducer.productStatus.isLoading);
    const formatProductStats = useMemo(() => {
        if (product.length !== 0) {
            return formatStatsForGraph(product);
        }
    }, [product]);
    useMemo(() => {
        const currentDate = new Date();
        const startDate = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString();
        const endDatePeriod = new Date(new Date().setDate(currentDate.getDate() - 30)).toISOString();
        if (id) {
            dispatch(getProductInfo({ id: id, startDate: endDatePeriod, endDate: startDate }));
        }
        return null;
    }, [id]);
    return (_jsx("div", { children: isLoadingProduct ?
            _jsx("div", { style: { width: '70vw', height: '60vh' }, children: _jsx(ContainerLoader, {}) })
            :
                _jsx(Graph, { modal: true, data: formatProductStats, productInfoStats: productInfoStats }) }));
};
export { TooltipGraph };
