import { styled } from '@/app/styles/mainStyles';
export const Input = styled('input', {
    background: '#FFFFFF',
    border: '1px solid $border',
    borderRadius: '8px',
    fontWeight: 400,
    paddingLeft: '6px',
    padding: '4px 11px',
    outline: 'none',
    boxSizing: 'border-box',
    '-moz-appearance': 'textfield',
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: '14px',
    fontFamily: '$montserrat',
    lineHeight: 1.5715,
    '&:disabled': {
        opacity: 0.3,
        cursor: 'not-allowed',
    },
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
    },
    variants: {
        typeInput: {
            default: {
                height: '40px',
                width: '100%',
                '&:focus': {
                    outline: 'none',
                    boxShadow: '0px 0px 0px 1px #B3B8E7',
                },
            },
        },
        error: {
            true: {
                outline: 'none',
                border: '1px solid #F14343A5',
                '&:focus': {
                    outline: 'none',
                    boxShadow: '0px 0px 0px 1px #F1AFAFA5',
                },
                '&:hover': {
                    boxShadow: '0px 0px 0px 1px #F1AFAFA5',
                    transition: '0.3s',
                },
            },
        },
    },
});
