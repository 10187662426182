import { ToastError } from '@/widgets/ui/Toast/ToastError/ToastError';
import { ErrorCalculator } from '@/app/providers/locales/ErrorCalculator/ErrorCalculator';
import { ErrorUser } from '@/app/providers/locales/ErrorUser/ErrorUser';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
export const displayError = (metadata, entity) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    sendAmplitudeData('error_popup_shown', {
        errorMessage: metadata.response.data.message,
        errorDetails: ((_c = (_b = (_a = metadata.response.data.details) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.metadata) === null || _c === void 0 ? void 0 : _c.error_description) ? (_d = metadata.response.data.details) === null || _d === void 0 ? void 0 : _d[0].metadata.error_description : metadata.response.data.code,
    });
    if ((_f = (_e = metadata.response.data.details[0]) === null || _e === void 0 ? void 0 : _e.metadata) === null || _f === void 0 ? void 0 : _f.error_description) {
        ToastError((_g = metadata.response.data.details) === null || _g === void 0 ? void 0 : _g[0].metadata.error_description);
    }
    else if (((_j = (_h = metadata.response.data.details) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.domain) === 'user' || entity === 'user') {
        ToastError(ErrorUser[(_k = metadata === null || metadata === void 0 ? void 0 : metadata.response.data) === null || _k === void 0 ? void 0 : _k.code]);
    }
    else {
        ToastError(ErrorCalculator[(_l = metadata.response.data) === null || _l === void 0 ? void 0 : _l.code]);
    }
};
