import { routes } from '@/shared/utils/routes';
import { ReactComponent as Calendar } from '@/shared/assets/icons/calendar.svg';
import { ReactComponent as Bookmark } from '@/shared/assets/icons/bookmark.svg';
import { ReactComponent as Plus } from '@/shared/assets/icons/plus.svg';
const secondBlockButtons = [
    {
        id: 'calendar',
        icon: Calendar,
        text: 'Мои расчеты',
        route: routes.calculationsList,
    },
    {
        id: 'bookmark',
        icon: Bookmark,
        text: 'Сохраненные товары',
        route: routes.savedProducts,
    },
    {
        id: 'plus',
        icon: Plus,
        text: 'Создать расчет',
        route: routes.calculateNewItem,
    },
];
export default secondBlockButtons;
