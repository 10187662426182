var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import React, { forwardRef } from 'react';
import { Space } from '@/shared/ui/Space/Space';
import { Text } from '@/shared/ui/Text/Text';
import { HelpTooltip } from '@/shared/ui/HelpTooltip/HelpTooltip';
const Input = forwardRef((_a, ref) => {
    var { value, css, autocomplete, onChange, iconInside, cssLabel, labelFontFamily = 'inter', typeInput, type, error, change, handleChangeField, required, label, onBlur, password, cssContainer, tooltip } = _a, props = __rest(_a, ["value", "css", "autocomplete", "onChange", "iconInside", "cssLabel", "labelFontFamily", "typeInput", "type", "error", "change", "handleChangeField", "required", "label", "onBlur", "password", "cssContainer", "tooltip"]);
    const handleChange = (event) => {
        if (onChange) {
            onChange(event);
        }
    };
    const handleChangeEditField = () => {
        if (handleChangeField) {
            handleChangeField();
        }
    };
    const getError = () => {
        if (error) {
            if (error === 'errorLogistic') {
                return 'errorLogistic';
            }
            else {
                return true;
            }
        }
        return false;
    };
    const handleBlur = (event) => {
        if (onBlur) {
            onBlur(event);
        }
    };
    return (_jsxs(Space, { css: Object.assign({ width: '100%' }, cssContainer), direction: "vertical", children: [label &&
                _jsxs(S.Label, { css: { width: '100%' }, children: [_jsxs(Space, { children: [_jsx(S.TextLabel, { css: cssLabel, fontType: labelFontFamily, children: label }), required && _jsx(Text, { type: "error", children: "*" }), tooltip &&
                                    _jsx(HelpTooltip, { cssForContainer: { marginLeft: '6px', width: '18px', height: '18px' }, mode: "dark", content: tooltip })] }), change &&
                            _jsx(Text, { type: "select", onClick: handleChangeEditField, children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C" })] }), _jsx(S.Input, Object.assign({ typeInput: typeInput, value: value, iconInside: iconInside, onBlur: handleBlur, type: password ? password : 'text', ref: ref, autoFocus: false, autoComplete: autocomplete || 'on', error: getError(), onChange: (event) => handleChange(event), css: css }, props))] }));
});
export { Input };
