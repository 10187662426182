var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useState, useContext } from 'react';
import { useQuery } from '@/shared/hooks/useQuery/useQuery';
import { fakeMailingIdAfterPayment } from '@/types/fakeMailingIdAfterPayment';
import axios from 'axios';
import { getReqPayment } from '@/shared/api/payment';
import { PaymentStatus } from '@/entity/PaymentEntity/types';
import { ToastSuccess } from '@/widgets/ui/Toast/ToastSuccess/ToastSuccess';
import { ToastError } from '@/widgets/ui/Toast/ToastError/ToastError';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { getBalanceForMailings } from '@/entity/MailingListEntity/mailing.slice';
import { useHistory } from 'react-router';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
// @ts-ignore
const CheckPaymentContext = createContext(null);
export const useCheckPaymentContext = () => useContext(CheckPaymentContext);
export const useGetDataFromCheckPayment = () => useContext(CheckPaymentContext).data;
export const CheckPaymentProvider = ({ children }) => {
    const email = useTypedSelector((state) => { var _a; return (_a = state.profileReducer.profile) === null || _a === void 0 ? void 0 : _a.email; });
    const history = useHistory();
    const dispatch = useTypedDispatch();
    const [data, setData] = useState([]);
    const query = useQuery();
    const mailingIdAfterPayment = query.get('mailingIdAfterPayment');
    const mailingPaymentId = query.get('mailing_payment_id');
    const getAndParseData = () => {
        const dataArray = localStorage.getItem('paymentsArray');
        return JSON.parse(dataArray ? dataArray : '[]');
    };
    const handleAddNewItem = (item) => {
        const dataFromLocal = getAndParseData();
        if (!dataFromLocal.find((el) => el.paymentId === item.paymentId)) {
            localStorage.setItem('paymentsArray', JSON.stringify([...dataFromLocal, item]));
            setData([...dataFromLocal, item]);
        }
    };
    const handleRemoveItem = (id) => {
        const dataFromLocal = getAndParseData();
        if (dataFromLocal.find((el) => el.paymentId === id)) {
            localStorage.setItem('paymentsArray', JSON.stringify([...dataFromLocal.filter((el) => el.paymentId !== id)]));
            setData([...dataFromLocal.filter((el) => el.paymentId !== id)]);
        }
    };
    const handleCheckPayment = (ms) => __awaiter(void 0, void 0, void 0, function* () {
        const dataValues = getAndParseData();
        if (data.length === 0) {
            return;
        }
        try {
            const dataFromBack = yield axios.all(dataValues.map((el) => {
                if (el.email === email) {
                    return getReqPayment(`payment?id=${el.paymentId}`);
                }
                return null;
            }));
            if (dataFromBack.length !== 0) {
                dataFromBack.forEach((el) => {
                    if (!el) {
                        return null;
                    }
                    if (el.status === 200) {
                        if (el.data.payment.status === PaymentStatus.success) {
                            ToastSuccess('Оплата прошла успешно');
                            handleRemoveItem(el.data.payment.id);
                            dispatch(getBalanceForMailings());
                        }
                        else if (el.data.payment.status === PaymentStatus.canceled) {
                            ToastError('Ошибка с получением платежа');
                            handleRemoveItem(el.data.payment.id);
                        }
                    }
                });
            }
            setTimeout(() => {
                handleCheckPayment(ms + 10000);
            }, ms <= 60000 ? ms : 60000);
        }
        catch (err) {
            console.log('err', err);
        }
    });
    useEffect(() => {
        const dataFromLocalStorage = getAndParseData();
        if (dataFromLocalStorage) {
            setData(dataFromLocalStorage);
        }
        if (mailingIdAfterPayment && email && mailingPaymentId && mailingIdAfterPayment !== fakeMailingIdAfterPayment) {
            query.delete('mailingIdAfterPayment');
            query.delete('mailing_payment_id');
            history.push({ pathname: 'newMailing', search: query.toString() });
            handleAddNewItem({
                paymentId: mailingPaymentId,
                mailingId: mailingIdAfterPayment,
                email: email,
            });
        }
    }, [email]);
    useEffect(() => {
        handleCheckPayment(10000).then();
    }, [data]);
    const context = {
        handleAddNewItem,
        handleRemoveItem,
        data,
    };
    return (_jsx(CheckPaymentContext.Provider, { value: context, children: children }));
};
