import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from '@/shared/ui/Popover/Popover';
import { Space } from '@/shared/ui/Space/Space';
import { ReactComponent as Message } from '@/shared/assets/icons/message.svg';
import { ReactComponent as OnMarket } from '@/shared/assets/icons/onMarketIcon.svg';
import { useState } from 'react';
import * as S from './style';
import { Icon } from '@/shared/ui/Icon/Icon';
const SupportButton = ({ children }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen((prevState) => !prevState);
    };
    return (_jsx("div", { children: _jsx(Popover, { css: { borderRadius: '8px' }, open: open, onChangeOpen: handleOpen, side: "top", trigger: _jsx("div", { children: children }), children: _jsxs(Space, { direction: "vertical", children: [_jsxs(S.NavLinkSupport, { target: "_blank", href: "mailto:support@harvester.so", children: [_jsx(Icon, { fill: "#414BB2", IconItem: Message }), _jsx(S.NavLinkText, { type: "default", children: "\u042D\u043B. \u043F\u043E\u0447\u0442\u0430" })] }), _jsxs(S.NavLinkSupport, { target: "_blank", href: "https://t.me/harvester_support", children: [_jsx(Icon, { stroke: "#414BB2", IconItem: OnMarket }), _jsx(S.NavLinkText, { type: "default", children: "\u0412 Telegram" })] })] }) }) }));
};
export { SupportButton };
