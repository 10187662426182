import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
export const useDiffTariff = (subType, activePeriod) => {
    const diffTariff = useTypedSelector((state) => state.paymentReducer.diffTariffs);
    const oldTariffs = useTypedSelector((state) => state.paymentReducer.tariffs);
    const getDiffPrice = () => {
        if (subType) {
            const diffPrice = diffTariff.find((tariff) => tariff.name.toLowerCase().includes(subType) && tariff.duration === activePeriod);
            if (diffPrice === null || diffPrice === void 0 ? void 0 : diffPrice.price) {
                if (diffPrice.price < 0) {
                    return 0;
                }
                return diffPrice.price.toFixed(0);
            }
            return 0;
        }
        return 0;
    };
    const getNextSubPrice = () => {
        if (subType) {
            const oldPrice = oldTariffs.find((tariff) => tariff.name.toLowerCase().includes(subType) && tariff.duration === activePeriod);
            return oldPrice === null || oldPrice === void 0 ? void 0 : oldPrice.price.toFixed(0);
        }
    };
    return {
        getDiffPrice,
        getNextSubPrice,
    };
};
