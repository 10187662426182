import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState('');
    useEffect(() => {
        if (!isEqual(value, debouncedValue)) {
            const timer = setTimeout(() => setDebouncedValue(value), delay || 500);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [value, delay]);
    return debouncedValue;
};
export { useDebounce };
