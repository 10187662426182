import { routes } from '@/shared/utils/routes';
import { ReactComponent as Message } from '@/shared/assets/icons/message.svg';
import { ReactComponent as Cart } from '@/shared/assets/icons/cart.svg';
import { ReactComponent as Plus } from '@/shared/assets/icons/plus.svg';
const thirdBlockButtons = [
    {
        id: 'message',
        icon: Message,
        text: 'Мои рассылки',
        active: 'mailing',
        route: routes.myMailings,
    },
    {
        id: 'plus',
        icon: Plus,
        text: 'Создать рассылку',
        route: routes.newMailing,
    },
    {
        id: 'cart',
        icon: Cart,
        text: 'Мои магазины',
        route: routes.myShops,
    },
];
export default thirdBlockButtons;
