import { styled } from '@/app/styles/mainStyles';
export const TextArea = styled('textarea', {
    background: '#FFFFFF',
    border: '1px solid #B3B8E7',
    borderRadius: '8px',
    fontWeight: 400,
    paddingLeft: '6px',
    padding: '4px 11px',
    boxSizing: 'border-box',
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: '14px',
    fontFamily: '$nunuto',
    lineHeight: 1.5715,
    resize: 'none',
    '&:disabled': {
        opacity: 0.3,
        cursor: 'not-allowed',
    },
    variants: {
        typeInput: {
            default: {
                transition: '400ms',
                height: '340px',
                width: '100%',
                '&:focus': {
                    outline: 'none',
                    boxShadow: '0px 0px 0px 1px #B3B8E7',
                },
                '&:hover': {
                    outline: 'none',
                    boxShadow: '0px 0px 0px 1px #B3B8E7',
                },
            },
        },
        error: {
            true: {
                transition: '400ms',
                outline: 'none',
                border: '1px solid #F14343A5',
                '&:focus': {
                    outline: 'none',
                    boxShadow: '0px 0px 0px 1px #F1AFAFA5',
                },
                '&:hover': {
                    outline: 'none',
                    boxShadow: '0px 0px 0px 1px #F1AFAFA5',
                },
            },
        },
    },
});
