var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as S from './style';
import { ToSub } from './ToSub/ToSub';
import { ToTime } from './ToTime/ToTime';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { changeSubscription } from '@/entity/PaymentEntity/payment.slice';
import { SubActionState, UserActionEnum } from '@/features/ui/Dialogs/PaymentModal/types';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { useParamsForSubFlow } from '@/shared/hooks/useParamsForSubFlow/useParamsForSubFlow';
const UpgradeSub = ({ handleBack, isDesktop, upgradeState, subType, activeValuesForPlans, getIsCurrentTariff, activePeriod, getIsFeatureTariff, }) => {
    const handleGetQuery = useParamsForSubFlow();
    const dispatch = useTypedDispatch();
    const allTariffs = useTypedSelector((state) => state.paymentReducer.tariffs);
    const handleChangeTariff = () => __awaiter(void 0, void 0, void 0, function* () {
        if (allTariffs.length !== 0 && subType) {
            const findTariff = allTariffs.find((tariff) => tariff.name.toLowerCase().includes(subType) && tariff.duration === activePeriod);
            if (findTariff === null || findTariff === void 0 ? void 0 : findTariff.id) {
                const domain = handleGetQuery(findTariff.id, UserActionEnum.upgrade);
                const resp = yield dispatch(changeSubscription({ tariffId: findTariff.id, domain }));
                if (resp.payload.confirmationUrl) {
                    window.location.replace(resp.payload.confirmationUrl);
                }
            }
        }
    });
    return (_jsx(S.UpgradeSubContainer, { children: upgradeState === SubActionState.tariff ?
            _jsx(ToSub, { handleChangeTariff: handleChangeTariff, activePeriod: activePeriod, getIsCurrentTariff: getIsCurrentTariff, getIsFeatureTariff: getIsFeatureTariff, activeValuesForPlans: activeValuesForPlans, handleBack: handleBack, isDesktop: isDesktop, subType: subType })
            :
                _jsx(ToTime, { handleChangeTime: handleChangeTariff, activePeriod: activePeriod, getIsFeatureTariff: getIsFeatureTariff, getIsCurrentTariff: getIsCurrentTariff, activeValuesForPlans: activeValuesForPlans, handleBack: handleBack, isDesktop: isDesktop, subType: subType }) }));
};
export { UpgradeSub };
