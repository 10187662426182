import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as S from './style';
import { ReactComponent as Thumb } from '@/shared/assets/icons/SliderPicker.svg';
// import { ReactComponent as ThumbGift } from '@/shared/assets/icons/Gift.svg';
import { Icon } from '@/shared/ui/Icon/Icon';
import { PromocodeSlider } from '@/shared/ui/PromocodeSlider/PromocodeSlider';
const Slider = ({ value, max, onValueChange, defaultValue, css, min, withProomocode, promocodeValue, }) => {
    return (_jsx(_Fragment, { children: _jsxs(S.SliderRoot, { css: css, value: value, onValueChange: onValueChange, max: max, min: min, step: 1, "aria-label": "Volume", defaultValue: defaultValue, children: [_jsxs(S.SliderTrack, { children: [withProomocode && _jsx(PromocodeSlider, { max: 50000, min: promocodeValue ? promocodeValue + 450 : 0 }), _jsx(S.SliderRange, {})] }), _jsx(S.SliderThumb, { asChild: true, children: _jsxs("div", { style: { display: 'flex', justifyContent: 'center', width: '13px', height: '20px' }, children: [_jsx(S.ValueView, { children: value[0] }), _jsx(Icon, { css: { width: '13px', height: '20px', margin: 0 }, IconItem: Thumb })] }) })] }) }));
};
export { Slider };
