import { styled } from '@/app/styles/mainStyles';
export const UpgradeSubContainer = styled('div', {
    display: 'flex',
    padding: '40px 30px 0 30px',
    '@mobile': {
        padding: '0 18px 10px 18px',
    },
});
export const UpgradeSubWrapper = styled('div', {
    display: 'flex',
});
export const UpgradeDescriptionContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    padding: '28px 32px',
    borderRadius: '16px',
    border: '1px solid $border',
    minHeight: '588px',
    width: '740px',
    background: '$white',
    justifyContent: 'space-between',
    '@mobile': {
        height: '100%',
        width: '100%',
        padding: '16px 18px',
    },
});
