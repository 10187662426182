export const sidebarStepsMobile = {
    steps: [
        {
            target: '#sideBarMobileContainer',
            content: 'В меню доступны основные разделы Harvester',
            title: 'Главная страница',
            disableBeacon: true,
            placement: 'bottom',
            event: 'next',
        },
    ],
};
