import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import { ToLite } from './ToLite/ToLite';
import { ToFree } from './ToFree/ToFree';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
import { useMemo } from 'react';
import { formatTime } from '@/shared/utils/formatTime';
const DowngradeSub = ({ handleBack, subType, activeValuesForPlans, handleBuySub, isDesktop, handleCloseModal, getIsCurrentTariff, activePeriod, withUpgrade, getIsFeatureTariff, }) => {
    const savedProductsCount = useTypedSelector((state) => state.savedProducts.products.length);
    const currentSub = useTypedSelector((state) => { var _a; return (_a = state.paymentReducer.userSubscription) === null || _a === void 0 ? void 0 : _a.currentSub; });
    const getEndSubDate = useMemo(() => {
        if (currentSub === null || currentSub === void 0 ? void 0 : currentSub.finishedAt) {
            return formatTime(currentSub === null || currentSub === void 0 ? void 0 : currentSub.finishedAt);
        }
        return '';
    }, [currentSub]);
    return (_jsxs(S.DowngradeSubContainer, { children: [subType === 'lite' &&
                _jsx(ToLite, { withUpgrade: withUpgrade, activePeriod: activePeriod, getIsCurrentTariff: getIsCurrentTariff, getIsFeatureTariff: getIsFeatureTariff, isDesktop: isDesktop, handleBuySub: handleBuySub, price: activeValuesForPlans.lite, handleBack: handleBack, handleCloseModal: handleCloseModal, savedProductsCount: savedProductsCount, subEndDate: getEndSubDate }), subType === 'free' &&
                _jsx(ToFree, { getIsCurrentTariff: getIsCurrentTariff, getIsFeatureTariff: getIsFeatureTariff, isDesktop: isDesktop, handleCloseModal: handleCloseModal, handleBuySub: handleBuySub, handleBack: handleBack, savedProductsCount: savedProductsCount, subEndDate: getEndSubDate })] }));
};
export { DowngradeSub };
