import { styled } from '@/app/styles/mainStyles';
export const AddNewShopModalContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
});
export const ContainerForTooltip = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
});
