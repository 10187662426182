var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
import { getProduct, postProduct, putProduct, removeProduct } from '@/shared/api/calculator';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
import { ToastSuccess } from '@/widgets/ui/Toast/ToastSuccess/ToastSuccess';
import { prepareDataForItem } from '@/shared/utils/prepareDataForItem';
import { MarketplaceEnum, } from '@/widgets/ui/NewCalculate/ui/CustomItem/types';
import { MarketPlaceEnum } from '@/shared/hooks/useFilter/types';
import { displayError } from '@/shared/utils/displayError/displayError';
const initialState = {
    calculatorList: {
        calculatings: [],
        total: 0,
    },
    currentCalculate: null,
    updatedCalculate: null,
    calculateName: null,
    calculatorStatus: BASE_FETCH_STATUS,
    comissionStatus: BASE_FETCH_STATUS,
    comissionMarketError: {
        ozon: false,
        wb: false,
    },
};
const uploadFile = (calculateId, file) => __awaiter(void 0, void 0, void 0, function* () {
    const formData = new FormData();
    formData.append('calculating_id', calculateId);
    formData.append('file', file);
    try {
        const resp = yield postProduct('file/attachment', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            return displayError(err);
        }
    }
});
const createCalculation = createAsyncThunk('CalculatorSlice/createCalculation', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    let updatePayload = Object.assign(Object.assign({}, payload.calculating), { save: true });
    try {
        const resp = yield postProduct('/calculating', updatePayload);
        if (payload.file && resp.data.calculating.id) {
            yield uploadFile(resp.data.calculating.id, payload.file);
        }
        return { item: resp.data.calculating };
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getCommissionIdForMarketplace = createAsyncThunk('CalculatorSlice/getCommissionIdForMarketplace', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getProduct(`/commission?id=${payload.id}&market=${payload.market}`);
        return {
            data: resp.data,
            market: payload.market,
        };
    }
    catch (error) {
        const err = error;
        if (err.response) {
            return thunkAPI.rejectWithValue({
                error: err.response.data,
                market: payload.market,
            });
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const removeCalculate = createAsyncThunk('CalculatorSlice/removeCalculate', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield removeProduct(`/calculating?id=${payload.id}`);
        ToastSuccess('Успех!');
        return payload.id;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getCurrentCalculation = createAsyncThunk('CalculatorSlice/getCurrentCalculaton', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getProduct(`/calculating?id=${payload.id}`);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const checkDeliveryForOzon = createAsyncThunk('CalculatorSlice/checkDeliveryForOzon', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getProduct(`/good/possible/delivery?market=${payload.market}&width=${payload.width}&height=${payload.height}&length=${payload.length}&weight=${payload.weight}`);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const updateCalculate = createAsyncThunk('LogisticSlice/updateCalculate', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield putProduct('calculating', payload);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getAllCalculation = createAsyncThunk('CalculatorSlice/getAllCalculation', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getProduct(`/calculatings?limit=${payload.limit}&offset=${payload.offset}`);
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const calculatorSlice = createSlice({
    name: 'CalculatorSlice',
    initialState,
    reducers: {
        clearCalculateState: (state) => {
            state.calculatorList.total = 0;
            state.calculatorList.calculatings = [];
            state.currentCalculate = null;
            state.calculateName = null;
        },
        setUpdatedCalculate: (state, { payload }) => {
            var _a;
            const findCalculate = current(state.calculatorList.calculatings).find((el) => el.id === payload.id);
            const findUpdatedItem = findCalculate === null || findCalculate === void 0 ? void 0 : findCalculate.items.find((el) => {
                if (payload.marketplace === MarketplaceEnum.Ozon) {
                    return el.market === MarketPlaceEnum.Ozon;
                }
                else {
                    return el.market === MarketPlaceEnum.Wildberries;
                }
            });
            if (payload.update) {
                if (findCalculate) {
                    const updated = Object.assign(Object.assign({}, findCalculate), { items: [prepareDataForItem(findUpdatedItem)] });
                    state.updatedCalculate = updated;
                }
            }
            else {
                const updated = Object.assign(Object.assign({}, findCalculate), { items: [prepareDataForItem((_a = findCalculate === null || findCalculate === void 0 ? void 0 : findCalculate.items) === null || _a === void 0 ? void 0 : _a[0])] });
                state.updatedCalculate = updated;
            }
        },
        clearCurrentCalculate: (state) => {
            state.currentCalculate = null;
            state.calculateName = null;
        },
        updateCalculateName: (state, { payload }) => {
            state.calculateName = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllCalculation.pending, (state) => {
            state.calculatorStatus = START_FETCH_STATUS;
        });
        builder.addCase(getAllCalculation.fulfilled, (state, { payload }) => {
            state.calculatorList = payload;
            state.calculatorStatus.isLoading = false;
        });
        builder.addCase(getAllCalculation.rejected, (state, { payload }) => {
            state.calculatorStatus.isLoading = false;
            state.calculatorStatus.error = payload;
        });
        builder.addCase(createCalculation.pending, (state) => {
            state.calculatorStatus = START_FETCH_STATUS;
        });
        builder.addCase(createCalculation.fulfilled, (state, { payload }) => {
            state.calculatorList.calculatings = [payload.item, ...state.calculatorList.calculatings];
            state.calculatorList.total = state.calculatorList.total + 1;
            state.calculatorStatus.isLoading = false;
        });
        builder.addCase(createCalculation.rejected, (state, { payload }) => {
            state.calculatorStatus.isLoading = false;
            state.calculatorStatus.error = payload;
        });
        builder.addCase(getCurrentCalculation.pending, (state) => {
            state.calculatorStatus = START_FETCH_STATUS;
        });
        builder.addCase(getCurrentCalculation.fulfilled, (state, { payload }) => {
            state.currentCalculate = payload;
            state.calculatorStatus.isLoading = false;
        });
        builder.addCase(getCurrentCalculation.rejected, (state, { payload }) => {
            state.calculatorStatus.isLoading = false;
            state.calculatorStatus.error = payload;
        });
        builder.addCase(removeCalculate.pending, (state) => {
            state.calculatorStatus = START_FETCH_STATUS;
        });
        builder.addCase(removeCalculate.fulfilled, (state, { payload }) => {
            state.calculatorList.calculatings = state.calculatorList.calculatings.filter((el) => el.id !== payload);
            state.calculatorList.total = state.calculatorList.total - 1;
            state.calculatorStatus.isLoading = false;
        });
        builder.addCase(removeCalculate.rejected, (state, { payload }) => {
            state.calculatorStatus.isLoading = false;
            state.calculatorStatus.error = payload;
        });
        builder.addCase(getCommissionIdForMarketplace.pending, (state) => {
            state.comissionStatus = START_FETCH_STATUS;
        });
        builder.addCase(getCommissionIdForMarketplace.fulfilled, (state, { payload }) => {
            state.comissionStatus.isLoading = false;
            if (payload.market === MarketPlaceEnum.Ozon) {
                state.comissionMarketError.ozon = false;
            }
            else {
                state.comissionMarketError.wb = false;
            }
        });
        builder.addCase(getCommissionIdForMarketplace.rejected, (state, { payload }) => {
            state.comissionStatus.isLoading = false;
            if (payload.market === MarketPlaceEnum.Ozon) {
                state.comissionMarketError.ozon = true;
            }
            else {
                state.comissionMarketError.wb = true;
            }
        });
        builder.addCase(updateCalculate.pending, (state) => {
            state.calculatorStatus = START_FETCH_STATUS;
        });
        builder.addCase(updateCalculate.rejected, (state, { payload }) => {
            state.calculatorStatus.isLoading = false;
            state.calculatorStatus.error = payload;
        });
        builder.addCase(updateCalculate.fulfilled, (state, { payload }) => {
            state.calculatorList.calculatings = state.calculatorList.calculatings.map((el) => {
                if (el.id === payload.calculating.id) {
                    el = payload.calculating;
                    return el;
                }
                return el;
            });
            state.calculatorStatus.isLoading = false;
        });
    },
});
export default calculatorSlice.reducer;
export const { clearCalculateState, setUpdatedCalculate, clearCurrentCalculate, updateCalculateName, } = calculatorSlice.actions;
export { getAllCalculation, createCalculation, getCurrentCalculation, removeCalculate, updateCalculate, checkDeliveryForOzon, getCommissionIdForMarketplace, };
