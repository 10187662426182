var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
import { getMailing, postMailing, putMailing, removeMailing } from '@/shared/api/mailing';
import { ToastSuccess } from '@/widgets/ui/Toast/ToastSuccess/ToastSuccess';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
import { displayError } from '@/shared/utils/displayError/displayError';
const initialState = {
    shops: [],
    shopsStatus: BASE_FETCH_STATUS,
};
const getMyShops = createAsyncThunk('shops/getMyShops', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield getMailing('/cabinets');
        return response.data.ozonCabinets;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            if (err.response.status !== 403) {
                displayError(err);
            }
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const addNewShop = createAsyncThunk('shops/addNewShop', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield postMailing('/cabinet', payload);
        return Object.assign(Object.assign({}, payload), { id: response.data.id });
    }
    catch (error) {
        const err = error;
        if (err.response) {
            sendAmplitudeData('add_shop', {
                isOk: false,
                errorMessage: err.response.data.code,
            });
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const updateShop = createAsyncThunk('shops/updateShop', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield putMailing('cabinet', payload);
        ToastSuccess('Успех!');
        return payload;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const removeShop = createAsyncThunk('shops/removeShop', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield removeMailing(`cabinet?id=${payload}`);
        ToastSuccess('Успех!');
        return payload;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const shopsSlice = createSlice({
    name: 'shopsSlice',
    initialState,
    reducers: {
        clearStore: (state) => {
            state.shops = [];
            state.shopsStatus = BASE_FETCH_STATUS;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMyShops.pending, (state) => {
            state.shopsStatus = BASE_FETCH_STATUS;
        });
        builder.addCase(getMyShops.rejected, (state, { payload }) => {
            state.shopsStatus.isLoading = false;
            state.shopsStatus.error = payload;
        });
        builder.addCase(getMyShops.fulfilled, (state, { payload }) => {
            state.shopsStatus.isLoading = false;
            state.shops = payload;
        });
        builder.addCase(removeShop.pending, (state) => {
            state.shopsStatus = BASE_FETCH_STATUS;
        });
        builder.addCase(removeShop.rejected, (state, { payload }) => {
            state.shopsStatus.isLoading = false;
            state.shopsStatus.error = payload;
        });
        builder.addCase(removeShop.fulfilled, (state, { payload }) => {
            state.shopsStatus.isLoading = false;
            state.shops = state.shops.filter((shop) => shop.id !== payload);
        });
        builder.addCase(addNewShop.pending, (state) => {
            state.shopsStatus = BASE_FETCH_STATUS;
        });
        builder.addCase(addNewShop.rejected, (state, { payload }) => {
            state.shopsStatus.isLoading = false;
            state.shopsStatus.error = payload;
        });
        builder.addCase(addNewShop.fulfilled, (state, { payload }) => {
            state.shopsStatus.isLoading = false;
            state.shops.push(payload);
        });
        builder.addCase(updateShop.pending, (state) => {
            state.shopsStatus = BASE_FETCH_STATUS;
        });
        builder.addCase(updateShop.rejected, (state, { payload }) => {
            state.shopsStatus.isLoading = false;
            state.shopsStatus.error = payload;
        });
        builder.addCase(updateShop.fulfilled, (state, { payload }) => {
            state.shops = state.shops.map((shop) => {
                if (shop.id === payload.id) {
                    return Object.assign(Object.assign({}, shop), { name: payload.name });
                }
                return shop;
            });
        });
    },
});
export default shopsSlice.reducer;
export const { clearStore } = shopsSlice.actions;
export { getMyShops, removeShop, addNewShop, updateShop };
