export var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["success"] = "PAYMENT_STATUS_SUCCEEDED";
    PaymentStatus["canceled"] = "PAYMENT_STATUS_CANCELED";
    PaymentStatus["pending"] = "PAYMENT_STATUS_PENDING";
    PaymentStatus["waiting"] = "PAYMENT_STATUS_WAITING";
    PaymentStatus["none"] = "PAYMENT_STATUS_NONE";
})(PaymentStatus || (PaymentStatus = {}));
export var TariffsNameEnum;
(function (TariffsNameEnum) {
    TariffsNameEnum["Free"] = "Free";
    TariffsNameEnum["Lite"] = "\u041A\u0430\u043B\u044C\u043A\u0443\u043B\u044F\u0442\u043E\u0440 Lite";
    TariffsNameEnum["Pro"] = "\u041A\u0430\u043B\u044C\u043A\u0443\u043B\u044F\u0442\u043E\u0440 Pro";
})(TariffsNameEnum || (TariffsNameEnum = {}));
