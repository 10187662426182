export const getZeroWithNumber = (number) => {
    return number > 9 ? `${number}` : `0${number}`;
};
export function checkSelectedTime(selectedTime, selectedDate) {
    const now = new Date(); // Текущая дата и время
    // Если выбранная дата - сегодняшний день
    if (selectedDate.toDateString() === now.toDateString()) {
        const currentHour = now.getHours();
        const currentMinute = now.getMinutes();
        // Создаем объект Date для текущего времени
        const currentTime = new Date();
        currentTime.setHours(currentHour, currentMinute, 0, 0);
        const [hourStr, minuteStr] = selectedTime.split(':');
        const selectedHour = parseInt(hourStr, 10);
        const selectedMinute = parseInt(minuteStr, 10);
        // Создаем объект Date для выбранного времени
        const selectedTimeObj = new Date();
        selectedTimeObj.setHours(selectedHour, selectedMinute, 0, 0);
        // Если выбранное время меньше текущего времени, возвращаем пустую строку
        if (selectedTimeObj < currentTime) {
            return '';
        }
    }
    // Если выбранная дата не сегодняшний день или выбранное время больше или равно текущему времени,
    // возвращаем выбранное время без изменений
    return selectedTime;
}
export const dateForUi = (time) => {
    if (typeof time === 'string') {
        return '';
    }
    return `${getZeroWithNumber(time.getDate())}.${getZeroWithNumber(time.getMonth() + 1)}.${time.getFullYear()}, ${getZeroWithNumber(time.getHours())}:${getZeroWithNumber(time.getMinutes())} МСК`;
};
export const dateForSuccessModal = (date, time) => {
    if (!date || !time) {
        return '';
    }
    const [hours, minutes] = time === null || time === void 0 ? void 0 : time.split(':');
    return `${getZeroWithNumber(date.getDate())}.${getZeroWithNumber(date.getMonth() + 1)}.${date.getFullYear()}, ${hours}:${minutes} МСК`;
};
export const dateForChange = (time) => {
    if (typeof time === 'string') {
        return {
            date: new Date(),
            time: '',
        };
    }
    return {
        date: time,
        time: `${getZeroWithNumber(time.getHours())}:${getZeroWithNumber(time.getMinutes())}`,
    };
};
export function convertTime(date) {
    if (!date) {
        return '';
    }
    const [year, month, day] = date.slice(0, 10).split('-');
    const [hour, minute] = date.split('T')[1].split(':');
    return new Date(+year, +month - 1, +day, +hour + 3, +minute);
}
export function combineDateTime(date, time) {
    // Разбиваем строку времени на часы и минуты
    const [hoursUser, minutesUser] = time.split(':').map(Number);
    const combinedDateTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hoursUser - 3, minutesUser);
    const year = combinedDateTime.getFullYear();
    const month = getZeroWithNumber(combinedDateTime.getMonth() + 1);
    const day = getZeroWithNumber(combinedDateTime.getDate());
    const hours = getZeroWithNumber(combinedDateTime.getHours());
    const minute = getZeroWithNumber(combinedDateTime.getMinutes());
    return `${year}-${month}-${day}T${hours}:${minute}:00.000Z`;
}
