import { jsx as _jsx } from "react/jsx-runtime";
import * as S from '../CheckboxControl/style';
import { ReactComponent as Checked } from '@/shared/assets/icons/checked.svg';
const Checkbox = ({ onChange, value, disabled }) => {
    const handleChange = (prevIsChecked) => {
        if (!disabled) {
            onChange(prevIsChecked);
        }
    };
    return (_jsx(S.StyledRoot, { checked: !!value, type: value && 'active', disabled: disabled, onCheckedChange: handleChange, children: _jsx(S.StyledIndicator, { disabled: disabled, children: value && _jsx(Checked, { style: { width: '14px', height: '11px' } }) }) }));
};
export { Checkbox };
