import { keyframes } from '@stitches/react';
import { styled } from '@/app/styles/mainStyles';
const pulseBorder = keyframes({
    '0%': {
        transform: 'scale(0.5)',
        opacity: 1,
    },
    '100%': {
        transform: 'scale(5)',
        opacity: 0,
    },
});
export const Beacon = styled('span', {
    backgroundColor: '$acceptButton',
    position: 'absolute',
    borderRadius: '50%',
    display: 'inline-block',
    height: '10px',
    width: '10px',
    variants: {
        error: {
            true: {
                background: '$error',
            },
        },
    },
});
export const BeaconContainer = styled('div', {
    animation: `${pulseBorder} 1.5s linear infinite`,
    borderRadius: '50%',
    backgroundColor: '$white',
    padding: '4px',
    display: 'flex',
    background: 'rgba(59, 180, 74, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
    width: '10px',
    height: '10px',
});
